import React, { useState, useEffect } from "react";
import TermComponent from "./TermComponent/TermComponent";
import "./TermsOfService.css";
import { useTranslation } from "react-i18next";
import NavBar from "../homepage/NavBar";
import Footer from "../homepage/Footer";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div id="ppSec">
      <NavBar initialFont="black" />
      <div className="TermsContainer">
        <div className="TermsContainerImagesAndText">
          <img
            className="img1"
            src="/images/Aboutus/cdd20-GpPkBWuN_qc-unsplash.webp"
          />
          <img
            className="img2"
            src="/images/Aboutus/cdd20-GpPkBWuN_qc-unsplash.webp"
          />
          <div className="textContainer">
            <div className="">
              <div className="TermOfServiceTitle">{t("privacyPolicy")}</div>
            </div>
            <TermComponent
              TermNumber={t("01")}
              TermTitle={t("Guidingprinciples")}
              TermContent={t("wehaveafewfundamentalprinciples")}
            />{" "}
            <TermComponent
              TermNumber={t("02")}
              TermTitle={t("WebsiteVisitors")}
              TermContent={t(
                "LikemostwebsiteoperatorsFintoldcollectsnonpersonallyidentifying"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("03")}
              TermTitle={t("GatheringofPersonallyIdentifyingInformation")}
              TermContent={t(
                "GatheringofPersonallyIdentifyingInformationContent"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("04")}
              TermTitle={t("AggregatedStatistics")}
              TermContent={t("AggregatedStatisticsContent")}
            />{" "}
            <TermComponent
              TermNumber={t("05")}
              TermTitle={t(
                "ProtectionofCertainPersonallyIdentifyingInformation"
              )}
              TermContent={t(
                "ProtectionofCertainPersonallyIdentifyingInformationContent"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("06")}
              TermTitle={t("Cookies")}
              TermContent={t("CookiesContent")}
            />{" "}
            <TermComponent
              TermNumber={t("07")}
              TermTitle={t("BusinessTransfers")}
              TermContent={t("BusinessTransfersContent")}
            />{" "}
            <TermComponent
              TermNumber={t("08")}
              TermTitle={t("PrivacyPolicyChanges")}
              TermContent={t("PrivacyPolicyChangesContent")}
            />{" "}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
