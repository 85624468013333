import React from "react";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "@fintold/packages";
import { MainButton } from "@fintold/packages";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { customFetch } from "../../CustomFetch";
import "./ResetPassword.css";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "password must contain at least 1 letter, 1 number and 1 symbol"
    )
    .required(),
  confirmPassword: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

const ResetPassword = (props) => {
  const { t } = useTranslation();

  const handleSubmit = async (values) => {
    try {
      const res = await customFetch("/account/setpassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values["password"]),
      });
      const data = await res.json();
      console.log(data.url);
      window.location.href = data.url;
    } catch (e) {
      toast(<ToastBody icon="error" text={"Error setting password"} />, {
        className: "errorToast",
      });
    }
  };

  return (
    <div className="container resetPassword">
      <img className="logo" width="90" src="/images/logo-black.webp" />
      <h1 className="resetHead">{t("youNeedToCreatePass")}</h1>
      <p className="resetSubHead">{t("youAreUsingGenPass")}</p>
      <div className="resetContent">
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form>
              <PasswordInput
                test={true}
                placeholder="enter your password"
                label={t("password")}
                name="password"
                showicon="true"
                passRegex="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
              />
              <PasswordInput
                test={false}
                placeholder="enter your password again"
                label={t("confirmPassword")}
                name="confirmPassword"
              />
              <MainButton
                btnType={formik.isSubmitting ? "loading" : "main"}
                text={
                  formik.isSubmitting
                    ? t("pleaseWait")
                    : t("enterPassAgainLogin")
                }
                type="submit"
                onClick={null}
                disabled={!(formik.isValid && formik.dirty)}
              />
            </Form>
          )}
        </Formik>
        <div className="helper">
          {t("havingProbLoggingIn")}{" "}
          <a href={`tel:${t("CustomerServiceNumber")}`}>
            {t("contactSupport")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
