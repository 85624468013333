import React from "react";
import FundingOption from "./FundingOption";
import "./FundingOptions.css";

const FundingOptions = (props) => {
  return (
    <div className="fundingOptions">
      <div className="mainPage">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="51.649"
          height="61.394"
          viewBox="0 0 51.649 61.394"
        >
          <g id="Group" transform="translate(199.706 -288.37) rotate(50)">
            <path
              id="Vector"
              d="M39.1,9.78V21.228c0,6.846-3.912,9.78-9.78,9.78H9.78a13,13,0,0,1-2.845-.289A8.643,8.643,0,0,1,5.357,30.3C2.023,29.052,0,26.162,0,21.228V9.78C0,2.934,3.912,0,9.78,0H29.319c4.979,0,8.558,2.112,9.514,6.935A16.075,16.075,0,0,1,39.1,9.78Z"
              transform="translate(109.71 319.52)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M38.833,9.514V20.961c0,6.846-3.912,9.78-9.78,9.78H9.514A12.482,12.482,0,0,1,5.09,30.03,7.8,7.8,0,0,1,0,23.784a13,13,0,0,0,2.845.289H22.384c5.868,0,9.78-2.934,9.78-9.78V2.845A14.068,14.068,0,0,0,31.9,0C36.121.889,38.833,3.868,38.833,9.514Z"
              transform="translate(116.648 326.455)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M11.737,5.868A5.868,5.868,0,1,1,5.868,0,5.868,5.868,0,0,1,11.737,5.868Z"
              transform="translate(123.377 329.167)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0V9.78"
              transform="translate(116.534 330.145)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0V9.78"
              transform="translate(141.967 330.146)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
          </g>
        </svg>
        <h1>
          Funding your clients is not a <br /> problem anymore!
        </h1>
        <p>
          Do no limit your financial business growth due to limited cash. Use{" "}
          <br />
          our network to choose a funding channel.
        </p>
      </div>
      <FundingOption
        smallTitleColor="#A6A5B6"
        title="Banks."
        titleColor="rgba(255, 255, 255, 1)"
        background="#213243"
        img="/images/home/fbank.webp"
        description={
          <>
            We finalized the process with multiple Egyptian <br /> banks. Choose
            which bank you want to fund your <br /> customers in one click.
          </>
        }
        descriptionColor="rgba(214, 214, 214, 0.8)"
      />
      <FundingOption
        smallTitleColor="rgba(214, 214, 214, 0.8)"
        title="Other NBFSs."
        titleColor="rgba(255, 255, 255, 1)"
        background="#0C3139"
        img="/images/home/fnbfs.webp"
        description={
          <>
            We also connect you with other NBFSs willing to <br /> fund
            customers.
          </>
        }
        descriptionColor="rgba(214, 214, 214, 0.8)"
      />
      <FundingOption
        smallTitleColor="rgba(12, 49, 57, 0.8)"
        title={
          <>
            Your <br /> company.
          </>
        }
        titleColor="rgba(12, 49, 57, 1)"
        description={
          <>
            You still have the option and management system <br /> that allows
            you to manage the whole funding <br /> process on your own.
          </>
        }
        descriptionColor="rgba(42, 42, 42, 0.8)"
        background="#F8F8F8"
        img="/images/home/fcompany.webp"
      />
    </div>
  );
};

export default FundingOptions;
