import React from "react";
import "./CardsContainer.css";

const CardsContainer = (props) => {
  return (
    <div className="cardsContainer">
      <div className="column1">
        {props.children.filter((i, index) => index % 2 === 0).map((i) => i)}
      </div>
      <div className="column2">
        {props.children.filter((i, index) => index % 2 !== 0).map((i) => i)}
      </div>
      {/* <div className="column3">{props.children.map((i) => i)}</div> */}
    </div>
  );
};

export default CardsContainer;
