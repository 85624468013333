
const getCsrfFromCookie = () => {
    try {
        const csrfCookie = document.cookie
            .split("; ")
            .find(row => row.startsWith("CSRF-TOKEN="));
        if (csrfCookie) return csrfCookie.split("=")[1]
        return null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
};

export const customFetch = async (url, config = {}) => {
    const res = await fetch(url, {
        ...config,
        headers: {
            ...config.headers,
            "X-CSRF-TOKEN": getCsrfFromCookie()
        }
    });
    if (res.status === 401) {
        await fetch("/account/logout", {
            ...config,
            headers: {
                ...config.headers,
                "X-CSRF-TOKEN": getCsrfFromCookie()
            }
        });
        window.location.href = `${window.location.origin}/login`;
    }
    return res;
};