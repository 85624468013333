import React, { useState, useEffect, useRef } from "react";
import "./CompanySolutionCenter.css";
import { MainModal } from "@fintold/packages";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
const CompanySolutionCenter = ({ solutions, ...props }) => {
  const { t } = useTranslation();

  const storeSolution = (solution) => {
    props.setSolution(solution);
  };

  return (
    <div>
      <MainModal
        modal={props.modal}
        setModal={props.setModal}
        closeText={t("close")}
        hideHelper={true}
        size={"modal-lg"}
      >
        <div className="modal-header"></div>
        <div className="stylingtitlecenternbficomp">
          <div className="aligncenteallnbficomp">
            <div className="stylingOurSolutionsnbfisstylecomp">
              <h6>{t("OURSOLUTIONS")}</h6>
            </div>

            <div className="Choosewhichclientstylenbfistylecomp">
              <h1>{t("ChoosewhichsolutionModal")}</h1>
            </div>
          </div>

          <div className="col-3">
            <div className="stylingall11111nbfiicomp">
              {solutions &&
                solutions.map((item) => {
                  return (
                    <div key={item.id}>
                      <li
                        style={{ listStyle: "none" }}
                        onClick={() => {
                          props.modal.hide();
                          storeSolution(item);
                        }}
                      >
                        <div className="card addingcompboxshadoincardnbfiicomp">
                          <div className="card-body ">
                            <div className="coloringDocumentneedednbfistylecomp">
                              <div className="logoContainer">
                                <img src={item.logo} />
                              </div>
                              <a>
                                {t("DocumentsneededModal")}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.699"
                                  height="12.699"
                                  viewBox="0 0 12.699 12.699"
                                  style={{ marginbottom: "4px" }}
                                >
                                  <path
                                    id="iconmonstr-info-2"
                                    d="M6.349,1.058A5.291,5.291,0,1,1,1.058,6.349,5.3,5.3,0,0,1,6.349,1.058ZM6.349,0A6.349,6.349,0,1,0,12.7,6.349,6.35,6.35,0,0,0,6.349,0Zm.529,9.524H5.82V5.291H6.878ZM6.349,3.042a.661.661,0,1,1-.661.661A.662.662,0,0,1,6.349,3.042Z"
                                    fill="#007094"
                                  />
                                </svg>
                              </a>
                            </div>
                            <div className="stylingsupplayChainnbfi">
                              <span>{item.name}</span>
                            </div>
                            <div className="paragraphstylingcomp">
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </MainModal>
    </div>
  );
};

export default CompanySolutionCenter;
