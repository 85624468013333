import React from "react";
import "./Hero.css";

const Hero = (props) => {
  return (
    <div className="spfHero">
      <small>Eliminate the hassle through automating factoring.</small>
      <h1>
        Better cash flow & access <br /> to liquidity with factoring.
      </h1>
      <img src="/images/home/factoringlaptop.webp" />
    </div>
  );
};

export default Hero;
