import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./ChooseBanner.css";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { useLocation } from "react-router-dom";
import { MainButton } from "@fintold/packages";
import { VerificationSteps } from "@fintold/packages";
import ScrollContainer from "react-indiana-drag-scroll";
import { customFetch } from "../../../CustomFetch";

const ChooseBanner = (props) => {
  const { t } = useTranslation();
  const [view, setView] = useState(props.view);
  const { state } = useLocation();
  const [containerName, setContainerName] = useState("banners");
  const [bannersList, setBannersList] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState("");
  const setting = {
    dragSpeed: 1.25,
    itemWidth: 303,
    itemHeight: 402,
    itemSideOffsets: 15,
  };

  const itemStyle = {
    width: `${setting.itemWidth}px`,
    height: `${setting.itemHeight}px`,
    margin: `0px ${setting.itemSideOffsets}px`,
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("path", selectedBanner);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/General/uploadcompanybannerlink", true);
    xhr.onload = (e) => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          if (data.valid === false) {
            toast(
              <ToastBody icon="error" text={"file contains invalid values"} />,
              {
                className: "errorToast",
              }
            );
            return;
          }
          // when request sucess
          window.location.reload();
        } else {
          toast(
            <ToastBody
              icon="error"
              text={"An error has occurred while uploading file"}
            />,
            {
              className: "errorToast",
            }
          );
          return;
        }
      }
    };
    xhr.send(formData);
  };
  useEffect(() => {
    fetchData();
  }, [view]);

  const fetchData = async () => {
    const res = await customFetch(
      `/General/getbanners?containerName=${containerName}`,
      {
        method: "Get",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    const banners = data.map((banner, index) => ({
      checked: false,
      banner: banner,
      id: index,
    }));

    if (banners != null) {
      setBannersList(banners);
    }
  };
  return (
    <div className="chooseBannerContainer">
      <div className="modal-header" style={{ width: "65vw" }}>
        <div className="Content">
          <div style={{ font: "normal normal bold 35px/41px Raleway" }}>
            {t("CustomizeYourClient")}
          </div>
          <div className="VerificationStepsUploadLogoChooseBanner">
            <VerificationSteps totalSteps={2} currentStep={3} />
          </div>
          <div className="VerificationStepsUploadLogo"></div>
          <div className="UploadCompanyLogo">{t("ChooseYourLoginBanner")}</div>
          <div className="BrandYourDashboard">{t("ChooseBannerLoginPage")}</div>
          <ScrollContainer className="scroll-container">
            <div className="ImageGallery">
              {bannersList.map((i, index) => (
                <div
                  className="ImageCardContainer"
                  key={index}
                  onClick={(index) => {
                    setSelectedBanner("");
                    setSelectedBanner(i.banner);
                    console.log("selected banner : " + selectedBanner);
                  }}
                >
                  <div
                    className="CheckIcon"
                    style={{
                      display: selectedBanner == i.banner ? "flex" : "none",
                    }}
                  >
                    <svg
                      className="formCircleIconCompleted"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 172 172"
                    >
                      <path
                        d="M157.66667,86c0,39.57792 -32.08875,71.66667 -71.66667,71.66667c-39.57792,0 -71.66667,-32.08875 -71.66667,-71.66667c0,-39.57792 32.08875,-71.66667 71.66667,-71.66667c39.57792,0 71.66667,32.08875 71.66667,71.66667z"
                        fill="#0F1D42"
                      ></path>
                      <path
                        d="M123.93317,52.2665l-48.62583,48.68317l-20.07383,-20.0165l-10.13367,10.13367l30.22183,30.15017l58.74875,-58.81683z"
                        fill="#ffffff"
                      ></path>
                    </svg>
                  </div>
                  <div className="ImgContainer">
                    <img
                      src={i.banner}
                      style={{
                        border:
                          selectedBanner == i.banner
                            ? "5px solid #0f1d42"
                            : "none",
                      }}
                    />
                  </div>
                </div>
              ))}
              {/* {bannersList.map((i, index) => (
              <div className="ImgContainer" key={i}>
                <img src={bannersList[index]} />
              </div>
            ))} */}
              <div className="containerScroller">
                {/* <BannerScroller _data={bannersList} {...setting}>
                {bannersList.map((i, _i) => (
                  <div className="item" style={{ ...itemStyle }} key={_i}>
                    <div className="ImgContainer">
                      <img src={i} />
                    </div>
                  </div>
                ))}
              </BannerScroller> */}
              </div>
            </div>
          </ScrollContainer>
          <div className="choosebannerbutton">
            <MainButton
              btnType={"main"}
              type="button"
              onClick={handleSubmit}
              disabled={selectedBanner == ""}
              text={t("ChooseBannerToContinue")}
            />
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="Container">
          <div></div>
          <div className="Content"></div>
          <div style={{}}></div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(ChooseBanner);
