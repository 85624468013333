import React, { useState, useEffect } from "react";
import "./OpenPositions.css";
import { useTranslation } from "react-i18next";
import JobCard from "../JobCard/JobCard";

import JobPosition from "../JobPositions";

import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const OpenPositions = () => {
  const [Department, setDepartment] = useState(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setDepartment(null);
  }

  const handleShow = (department) => {
    setDepartment(department);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="jobs">
        <Modal
          isOpen={!!Department}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <JobPosition Department={Department} onCloseModal={closeModal} />
        </Modal>
        <div className="OpenPositionsP" onClick={() => handleShow("S&G")}>
          <JobCard
            PositionTitle={t("S&G")}
            PositionDescription={t("HRExecutive")}
            JobCardPic="/images/Careers/sandgbanner.png"
            SloganColor="#ffffff"
          ></JobCard>
        </div>
        <div className="OpenPositionsP" onClick={() => handleShow("Creative")}>
          <JobCard
            PositionTitle={t("Creative")}
            PositionDescription={t("Designers")}
            JobCardPic="/images/Careers/creativebanner.png"
            SloganColor="#ffffff"
          />
        </div>
        <div
          className="OpenPositionsP"
          onClick={() => handleShow("Engineering")}
        >
          <JobCard
            PositionTitle={t("Engineering")}
            PositionDescription={t("Front-end")}
            JobCardPic="/images/Careers/engineeringbanner.png"
            SloganColor="#1D1D1F"
          />
        </div>
        <div className="OpenPositionsP" onClick={() => handleShow("Finance")}>
          <JobCard
            PositionTitle={t("Finance")}
            PositionDescription={t("Accountants")}
            JobCardPic="/images/Careers/finance.png"
            SloganColor="#ffffff"
          />
        </div>
      </div>
    </>
  );
};
export default OpenPositions;
