import React, { useState, useEffect } from "react";
import "./Aboutus.css";
import Panner from "./Panner/Panner";
import Retelling from "./Retelling/Retelling";
import AmongHigh from "./AmongHigh/AmongHigh";
import Hosted from "./Hosted/Hosted";
import Solution from "./Solutions/Solutions";
import Founder from "./Founder/Founder";
import Established from "./Established/Established";
import Avatar from "./Avatar/Avatar";
import { useTranslation } from "react-i18next";
import Maximize from "./Maximize/Maximize";
import NavBar from "../homepage/NavBar";
import Footer from "../homepage/Footer";
const Aboutus = () => {
  const { t } = useTranslation();
  return (
    <div id="aboutusSec">
      <NavBar initialFont="white" />
      <Panner />
      <Established />
      <Retelling />
      <AmongHigh />
      <Hosted />
      <Founder />
      <Solution />
      <Footer />
    </div>
  );
};
export default Aboutus;
