import React, { useState, useEffect } from "react";
import TermComponent from "./TermComponent/TermComponent";
import "./TermsOfService.css";
import { useTranslation } from "react-i18next";
import NavBar from "../homepage/NavBar";
import Footer from "../homepage/Footer";

const TermsOfService = () => {
  const { t } = useTranslation();
  var link = <a href={<></>}>www.Fintold.com</a>;
  return (
    <div id="tofSec">
      <NavBar initialFont="black" />
      <div className="TermsContainer">
        <div className="TermsContainerImagesAndText">
          <img
            className="img1"
            src="/images/Aboutus/cdd20-GpPkBWuN_qc-unsplash.webp"
          />
          <img
            className="img2"
            src="/images/Aboutus/cdd20-GpPkBWuN_qc-unsplash.webp"
          />
          <div className="textContainer">
            <div className="">
              <div className="TermOfServiceTitle">{t("TermsOfService")}</div>
            </div>
            <TermComponent
              TermNumber={t("01")}
              TermTitle={t("Introduction")}
              TermContent={t(
                "ThefollowingtermsandconditionsgoveralluseofthewwwFintoldcomwebsite"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("02")}
              TermTitle={t("YourFintold.comAccountandWebsite")}
              TermContent={t(
                "IfyoucreateanaccountontheWebsiteyouareresponsible"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("03")}
              TermTitle={t("ResponsibilityofContributors")}
              TermContent={t(
                "IfyoupostmaterialtotheWebsitepostlinksontheWebsite"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("04")}
              TermTitle={t("Payment")}
              TermContent={t(
                "InexchangeofusingtheServicesoftheWebsiteyouagree"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("05")}
              TermTitle={t("RestrictedUse")}
              TermContent={t(
                "YoumaynotuseFintoldcomtosubstantiallyreplicateproducts"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("06")}
              TermTitle={t("ResponsibilityofWebsiteVisitors")}
              TermContent={t("Fintoldhasnotreviewedandcannotreview")}
            />{" "}
            <TermComponent
              TermNumber={t("07")}
              TermTitle={t("ContentPostedonOtherWebsites")}
              TermContent={t(
                "Wehavenotreviewedandcannotreviewmaterialincludingcomputersoftware"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("08")}
              TermTitle={t("CopyrightInfringementandDMCAPolicy")}
              TermContent={t(
                "AsFintoldasksotherstorespectitsintellectualpropertyrights"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("09")}
              TermTitle={t("IntellectualProperty")}
              TermContent={t("ThisAgreementdoesnottoyouanyFintoldthirparty")}
            />{" "}
            <TermComponent
              TermNumber={t("10")}
              TermTitle={t("Advertisements")}
              TermContent={t("Fintoldreservestherighdisplayadvertisements")}
            />{" "}
            <TermComponent
              TermNumber={t("11")}
              TermTitle={t("Attribution")}
              TermContent={t(
                "FintoldreservestherighttodisplayattributionlinkssuchFundedthrough"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("12")}
              TermTitle={t("Changes")}
              TermContent={t(
                "Fintoldreservestherightatitsolediscretionmodifyreplace"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("13")}
              TermTitle={t("Termination")}
              TermContent={t(
                "FintoldmayterminateyouraccesstoalloranypartoftheWebsiteatanytime"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("14")}
              TermTitle={t("DisclaimerofWarranties")}
              TermContent={t(
                "TheWebsiteprovidedFintoldanditssuppliersandlicensors"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("15")}
              TermTitle={t("LimitationofLiability")}
              TermContent={t(
                "InnoeventwillFintoldsupplierslicensorsbeliablewithrespecttosubject"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("16")}
              TermTitle={t("GeneralRepresentationandWarranty")}
              TermContent={t(
                "YourepresentandwarranttheWebsitewillbeaccordance"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("17")}
              TermTitle={t("Indemnification")}
              TermContent={t(
                "YouagreetoindemnifyandholdharmlessFintoldcontractorsandlicensors"
              )}
            />{" "}
            <TermComponent
              TermNumber={t("18")}
              TermTitle={t("GoverningLawandDisputeResolution")}
              TermContent={t(
                "ThisAgreementconstitutestheentireagreementbetweenFintoldandyouconcerning"
              )}
            />{" "}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default TermsOfService;
