import React from "react";
import "./InnovativeSPF.css";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
const InnovativeSPF = (props) => {
  const { t } = useTranslation();
  return (
    <div className="spfInnovativeBackGround">
      <div className="spfInnovative col-11">
        <img src={"/images/home/InnovativeSPF.webp"} />
        <div className="innovativeOverlay"></div>
        <h1 className="SPFSubTitleParagraph">
          {t("InnovativeSupply")}
          <br />
          {t("FinanceWith")}
          <br />
          {t("Technology")}
        </h1>
        <p className="SPFParagraph">
          {t("OnlineSupply")}
          <br /> {t("OnboardsCompanies")}
          <br /> {t("helpAccess")}
          <br />
          {t("PayableData")}
          <br />
          <br /> {t("PartnerBanks")}
          <br /> {t("financeSolutions")}
          <br /> {t("SMEAffordable")}
          <br />
          <br />{" "}
          <HashLink className="arrowdownSPF" to="/supplychainfinance/#spfSec">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g
                id="vuesax_linear_maximize-2"
                data-name="vuesax/linear/maximize-2"
                transform="translate(-684 -316)"
              >
                <g id="maximize-2">
                  <path
                    id="Vector"
                    d="M0,7V4.5A4.494,4.494,0,0,1,4.5,0H7"
                    transform="translate(686 318)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M0,0H2.5A4.494,4.494,0,0,1,7,4.5V7"
                    transform="translate(699 318)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M6,0V1.5A4.494,4.494,0,0,1,1.5,6H0"
                    transform="translate(700 332)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M7,7H4.5A4.494,4.494,0,0,1,0,2.5V0"
                    transform="translate(686 331)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Vector-5"
                    data-name="Vector"
                    d="M0,0H24V24H0Z"
                    transform="translate(684 316)"
                    fill="none"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>{" "}
            {t("MoreDetails")}
          </HashLink>
        </p>
      </div>
    </div>
  );
};

export default InnovativeSPF;
