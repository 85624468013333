import React from "react";
import { HashLink } from "react-router-hash-link";
import "./Dashboard.css";
import { useTranslation } from "react-i18next";
const Dashboard = (props) => {
    const { t } = useTranslation();
  return (
    <div className="bankDashboardSec">
      <img className="img1-p1" src="/images/home/p1.webp" />
      <img className="img3-p4" src="/images/home/p4.webp" />
      <img className="img2-p2" src="/images/home/p2.webp" />
      <img className="img4-p3" src="/images/home/p3.webp" />
      <img className="img5-p4" src="/images/home/p4.webp" />
      <h2 className="outline">{t("DASHBOARD")}</h2>
      <h1>
      {t("CompleteControl")} 
        <br /> {t("FinancialProcess")}
      </h1>
      <p>
      {t("ReviewRequests")} 
        <br /> {t("CashoutMonitor")}
        <br /> {t("OnlinePortal")}
      </p>
      <HashLink to="#EasyBankWorkFlowSection">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="49.453"
          height="49.453"
          viewBox="0 0 49.453 49.453"
        >
          <g id="arrow-down" transform="translate(-236 -252)" opacity="0.6">
            <path
              id="Vector"
              d="M32.639,0,19.2,13.435a4.092,4.092,0,0,1-5.77,0L0,0"
              transform="translate(244.407 270.442)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H49.453V49.453H0Z"
              transform="translate(285.453 301.453) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </svg>
      </HashLink>
    </div>
  );
};

export default Dashboard;
