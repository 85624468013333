import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { VerificationSteps } from "@fintold/packages";
import AddCompanyDetails from "./AddCompanyDetails";
import ActivateSolutionCenter from "./ActivateSolutionCenter";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import UploadCompanyLogo from "./UploadCompanyLogo/UploadCompanyLogo";
import { SignoutList } from "@fintold/packages";
import { userLogin } from "../../store/user";
import "./GettingStarted.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { customFetch } from "../../CustomFetch";
import { Data } from "@microsoft/applicationinsights-common";
import CompanySolutionCenter from "./CompanySolutionCenter";

const GettingStarted = ({ parentCallback, ...props }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const { state } = useLocation();
  const [businessState, setBusinessState] = useState(state);
  const [view, setView] = useState(1);
  const [addCompanyDetailsModal, setAddCompanyDetailsModal] = useState(null);
  const [ActivateSolutionModal, setActivateSolutionModal] = useState(null);
  const [UploadCompanyLogoModal, setUploadCompanyLogoModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);

  const addCompanyDetailssucceed = (business) => {
    setView(3);
    setBusinessState({ ...business });
    setTimeout(() => {
      getDeployment(business.crnumber);
    }, 10000);
  };
  const showActivateSolModal = () => {
    ActivateSolutionModal.show();
  };
  const showUploadCompanyLogoModal = () => {
    UploadCompanyLogoModal.show();
  };

  const getSolutions = async () => {
    const res = await customFetch(`/account/getAllSolutionAvailable`);
    const data = await res.json();
    setSolutions(data.solutions);
  };

  const getDeployment = async (crnumber) => {
    if (!crnumber) return;
    const res = await customFetch(`/account/getdeployment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(crnumber),
      credentials: "include",
    });
    const data = await res.json();
    if (data.deployment == true) {
      // setBusinessState({ ...businessState, isDeployed: data[0].deployment });
      setView(4);
    } else
      setTimeout(() => {
        getDeployment(crnumber);
      }, 10000);
  };
  const resendCode = async (e) => {
    const res = await customFetch("/account/ResendVerificationCode", {
      credentials: "include",
      method: "GET",
    });
    if (!res.ok) {
      setLoading(false);
      toast(
        <ToastBody
          icon="error"
          text={"An error has occurred, please try again later"}
        />,
        {
          className: "errorToast",
        }
      );
      return;
    }
    toast(
      <ToastBody
        icon="confirm"
        text={"Verification code resent successfully"}
      />,
      {
        className: "defaultToast",
      }
    );
    props.history.push("/emailverification");
  };

  useEffect(() => {
    const setup = async () => {
      const res = await customFetch("/account/gettingstarted");
      const data = await res.json();
      if (data.url != null) location.href = data.url;
      setView(data.stage);
      if (data.business) setBusinessState({ ...data.business });
      getSolutions();
    };
    setup();
  }, []);

  const signout = async () => {
    const res = await customFetch("/account/logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    props.dispatch(
      userLogin({
        isAuthenticated: false,
        // email: null,
        // id: null,
        // name: null,
        // isActivated: null,
        // hasBusiness: null,
        // url: null,
        ...props.user,
      })
    );
    history.push("/");
  };

  const addsolutionsubscription = async (sol) => {
    let formData = new FormData();
    formData.append("crNumber", businessState.crnumber);
    formData.append("solutionId", sol.id);
    const res = await customFetch(`/account/addsolutionsubscription`, {
      body: formData,
      method: "POST",
      credentials: "include",
    });
    if (!res.ok) {
      toast(
        <ToastBody
          icon="error"
          text={"An error has occurred, please try again later"}
        />,
        {
          className: "errorToast",
        }
      );
      return;
    }
    toast(<ToastBody icon="confirm" text={"Solution Activated"} />, {
      className: "defaultToast",
    });
    setView(5);
  };

  return (
    <>
      <div className="gettingStarted">
        <div className="contentRegion">
          <div>
            <div className="nav">
              <img className="nbfilogo" src="/images/logo-black.webp" />
              {props.user && (
                <SignoutList
                  avatar={props.user.avatar}
                  name={props.user.name}
                  onClick={signout}
                ></SignoutList>
              )}
            </div>
            <div className="header">
              <h3>{t("gettingStartedTitle")}</h3>
              <p>{t("savetimepara")}</p>
              <span>{t("requiredactionstitle")}</span>
            </div>
          </div>
          {view === 1 && (
            <div className="verificationStepsWidth col-12">
              <VerificationSteps totalSteps={4} currentStep={1} />
            </div>
          )}
          <div className="action">
            {view === 1 && (
              <>
                <div className="actionBox">
                  <h6>{t("verifyYourEmailAddress")}</h6>
                  <p>{t("weSentYouACode")}</p>
                  <div className="actionBoxFooter">
                    <button
                      className="verifyEmailActionBox"
                      onClick={() => props.history.push("/emailverification")}
                    >
                      {" "}
                      {t("verifyMyEmailAddress")}
                    </button>
                    {t("or")}{" "}
                    <a
                      className={loading === true ? "disabled" : ""}
                      onClick={() => {
                        setLoading(true);
                        resendCode();
                      }}
                    >
                      {t("reSendTheCode")}
                    </a>
                  </div>
                  <div className="arrowFirst"></div>
                </div>
              </>
            )}
          </div>
          {view === 2 && (
            <div className="verificationStepsWidth col-12">
              <VerificationSteps totalSteps={4} currentStep={2} />
            </div>
          )}
          <div className="action">
            {view === 2 && (
              <>
                <div className="actionBox">
                  <h5>{t("addcompanycard")}</h5>

                  <p>
                    {t("addcompanyparacard")}
                    <p>{t("addcompanycardsolution")}</p>
                  </p>

                  <button
                    className="AddMyCompanyStylingBackGroud"
                    data-bs-toggle="modal"
                    data-bs-target={"#addCompanydetailsmodal"}
                    onClick={<></>}
                  >
                    {t("addmycompanybtn")}
                  </button>
                  <div className="arrowSecond"></div>
                </div>
              </>
            )}
          </div>
          <>
            {view === 3 && (
              <div className="verificationStepsWidth col-12">
                <VerificationSteps totalSteps={4} currentStep={2} />
              </div>
            )}
            <div className="action">
              {view === 3 && (
                <>
                  <div className="actionBox">
                    <div className="actionBoxDeployedFlex">
                      <div className="addcompanystyleGettingStarteddepSVG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                        >
                          <g
                            id="vuesax_linear_cloud-connection"
                            data-name="vuesax/linear/cloud-connection"
                            transform="translate(-492 -252)"
                          >
                            <g
                              id="cloud-connection"
                              transform="translate(492 252)"
                            >
                              <g
                                id="Group"
                                transform="translate(7.732 4.664)"
                                opacity="0.4"
                              >
                                <path
                                  id="Vector"
                                  d="M7.131,17.526c-9.52.677-9.5,14.467,0,15.143h22.54a10.877,10.877,0,0,0,7.4-2.847c6.673-5.833,3.1-17.5-5.693-18.62C28.225-7.791.715-.581,7.225,17.526"
                                  fill="none"
                                  stroke="#292d32"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </g>
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,0V7"
                                transform="translate(28 37.333)"
                                fill="none"
                                stroke="#292d32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                opacity="0.5"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M9.333,4.667A4.667,4.667,0,1,1,4.667,0,4.667,4.667,0,0,1,9.333,4.667Z"
                                transform="translate(23.333 44.333)"
                                fill="none"
                                stroke="#4fb947"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M9.333,0H0"
                                transform="translate(32.667 49)"
                                fill="none"
                                stroke="#4fb947"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <path
                                id="Vector-5"
                                data-name="Vector"
                                d="M9.333,0H0"
                                transform="translate(14 49)"
                                fill="none"
                                stroke="#4fb947"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <path
                                id="Vector-6"
                                data-name="Vector"
                                d="M0,0H56V56H0Z"
                                transform="translate(56 56) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div>
                        <h5>{t("deploycompanytitle")}</h5>

                        <p>
                          {t("wearedeployingpara")} <p>{t("yourbaseofpara")}</p>
                        </p>
                        <div className="arrowSecond"></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {view === 4 && (
              <div className="verificationStepsWidth col-12">
                <VerificationSteps totalSteps={4} currentStep={3} />
              </div>
            )}
            <div className="action">
              {view === 4 && (
                <>
                  <div className="actionBox">
                    <h5>{t("activateSoltitle")}</h5>

                    <p>
                      {t("readytoactivatepara")} <p>{t("clickactivatepara")}</p>
                    </p>
                    <button
                      className="AddMyCompanyStylingBackGroud"
                      onClick={showActivateSolModal}
                    >
                      {t("activateSolutionBtn")}
                    </button>
                    <div className="arrowFourth"></div>
                  </div>
                </>
              )}
            </div>
            {view === 5 && (
              <div className="verificationStepsWidth col-12">
                <VerificationSteps totalSteps={4} currentStep={4} />
              </div>
            )}
            <div className="action">
              {view === 5 && (
                <>
                  <div className="actionBox">
                    <h5>{t("UploadYourBrandLogo")}</h5>

                    <p>
                      <p>{t("BrandYourDashboard")}</p>
                    </p>
                    <button
                      className="AddMyCompanyStylingBackGroud"
                      onClick={showUploadCompanyLogoModal}
                    >
                      {t("UploadLogo")}
                    </button>
                    <div className="arrowFifth"></div>
                  </div>
                </>
              )}
            </div>
          </>
          <div className="privacyparag col-12">
            <p>
              {t("byclickingfooter")}{" "}
              <HashLink to="/termsofservice/#tofSec">
                {t("TermsofUse")}
              </HashLink>{" "}
              {t("and")}{" "}
              <HashLink to="/privacypolicy/#ppSec">
                {t("Privacypolicy")}
              </HashLink>
              .
            </p>
          </div>
        </div>
        <div class="containerimgright">
          <div className="col-md-4 d-none d-md-block">
            <img
              alt=""
              className="signupSideImageSaas"
              src={process.env.PUBLIC_URL + "/images/gettingstarted.png"}
            />

            <div class="bottom-left">
              <h3>{t("Expandwithfundingoptions")} </h3>
              <p>{t("marketplaceconnectsAdd")} </p>
            </div>
          </div>
        </div>
        ''
      </div>

      {/* <ActivateSolutionCenter
        modal={ActivateSolutionModal}
        setModal={setActivateSolutionModal}
        modalId="activatsolutionmodal"
        success={ActivateSolutionsucceed}
        CompanyCrNo={businessState ? businessState.crnumber : null}
      /> */}
      <CompanySolutionCenter
        modal={ActivateSolutionModal}
        setModal={setActivateSolutionModal}
        solutions={solutions}
        setSolution={(sol) => {
          addsolutionsubscription(sol);
        }}
      />

      <UploadCompanyLogo
        modal={UploadCompanyLogoModal}
        setModal={setUploadCompanyLogoModal}
        modalId="uploadcompanylogomodal"
        success={showUploadCompanyLogoModal}
        businessState={businessState}
        setBusinessState={setBusinessState}
      />
      <AddCompanyDetails
        modal={addCompanyDetailsModal}
        setModal={setAddCompanyDetailsModal}
        modalId="addCompanydetails"
        success={addCompanyDetailssucceed}
        UserId={props.user.id}
      />
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(GettingStarted);
