import React from "react";
import "./BankPartnershipProgram.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
const BankPartnershipProgram = (props) => {
    const { t } = useTranslation();
  return (
    <div className="homeBankPartnershipProg">
      <span>{t("BankPartnerShipProg")}</span>
      <h1>{t("WiderScaleTitle")}</h1>
      <div className="col-12">
        <p>
        {t("FintoldPlatformTech")}<br/>
        {t("scaleLowCost")}<br/>
        {t("SMETargetLoan")}<br/> 
        {t("bankOpportunities")}
        </p>
      </div>
    </div>
  );
};

export default BankPartnershipProgram;
