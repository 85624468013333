import React, { useState, useRef } from "react";
import "./GetNotified.css";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "@fintold/packages";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";

const GetNotified = (props) => {
  const [getJobField, setgetJobField] = useState("Alljobs");
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required field"),
  });
  const getSelectedJob = (event) => {
    setgetJobField(event.target.value);
  };

  const handleSubmit = async () => {
    await recaptchaRef.current.execute();
  };

  const notify = async (token, values) => {
    props.onAppliedSccuess();
    const res = await fetch("/general/newsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: values.email,
        jobField: getJobField,
        token,
      }),
    });
    setIsLoading(false);
  };

  const { t } = useTranslation();

  return (
    <div className="GetNotifiedContainer my-5" id="getnotified">
      <div className="">
        <div className="formIcon GetNotifiedForm">
          <img src="/images/JobPost/vuesax-linear-sms-tracking.webp" />
          <h2>Get notified!</h2>
          <span>Follow our new job posts.</span>
        </div>
      </div>
      <div className="GetNotifiedFormFields">
        <Formik
          initialValues={{ email: "" }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <div className="jobForm">
                <div
                  className="
                inputLabelGetNotified"
                >
                  <TextInput
                    placeholder={t("emailaddress@domain.com")}
                    label={t("email")}
                    name={"email"}
                    autoComplete="off"
                  />
                </div>
                <div className="jobSelection">
                  <select
                    className="dropdown col-10"
                    onChange={getSelectedJob}
                    value={getJobField}
                  >
                    <option value="Alljobs">All jobs</option>
                    <option value="SG">{t("S&G")}</option>
                    <option value="Creative">{t("Creative")}</option>
                    <option value="Engineering">{t("Engineering")}</option>
                    <option value="Finance">{t("Finance")}</option>
                  </select>
                </div>
                <button
                  className="NotifyMe"
                  disabled={formik.errors.email || !formik.dirty}
                >
                  Notify Me
                </button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lf768gUAAAAANNZyskUoAL9twYZ5k8REyMKNoh4"
                  onChange={async (token) => {
                    if (token) {
                      setIsLoading(true);
                      await notify(token, formik.values);
                    }
                  }}
                  size={"invisible"}
                  badge={"bottomleft"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default GetNotified;
