import React from "react";
import "./FinToldCard.css";

const FintoldCard = (props) => {
  return (
    <div className="fintoldCard" id="fintoldCardSection">
      <svg
        className="cardSvg"
        xmlns="http://www.w3.org/2000/svg"
        width="68"
        height="68"
        viewBox="0 0 68 68"
      >
        <g
          id="vuesax_linear_card"
          data-name="vuesax/linear/card"
          transform="translate(-492 -508)"
        >
          <g id="card" transform="translate(492 508)">
            <path
              id="Vector"
              d="M0,0H56.667"
              transform="translate(5.667 24.097)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H5.667"
              transform="translate(17 46.764)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M0,0H11.333"
              transform="translate(29.75 46.764)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M12.58,0H44.058C54.145,0,56.667,2.493,56.667,12.438V35.7c0,9.945-2.522,12.438-12.58,12.438H12.58C2.522,48.167,0,45.673,0,35.728V12.438C0,2.493,2.522,0,12.58,0Z"
              transform="translate(5.667 9.931)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H68V68H0Z"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
      <h1>
        Get even more flexibility <br /> with Fintold debit cards.
      </h1>
      <p>
        Get access to cash, pay your suppliers directly. No <br /> transaction
        fees, no setup fees and minimum <br /> documents required.
      </p>
      <div className="comingsoon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g id="maximize-2" transform="translate(-684 -316)">
            <path
              id="Vector"
              d="M0,7V4.5A4.494,4.494,0,0,1,4.5,0H7"
              transform="translate(686 318)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H2.5A4.494,4.494,0,0,1,7,4.5V7"
              transform="translate(699 318)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M6,0V1.5A4.494,4.494,0,0,1,1.5,6H0"
              transform="translate(700 332)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M7,7H4.5A4.494,4.494,0,0,1,0,2.5V0"
              transform="translate(686 331)"
              fill="none"
              stroke="#2323ec"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(684 316)"
              fill="none"
              opacity="0"
            />
          </g>
        </svg>
        Coming Soon
      </div>
      <img className="fintoldcardImg" src="/images/home/fintoldcard.webp" />
    </div>
  );
};
export default FintoldCard;
