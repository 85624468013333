import React, { useState, useEffect } from "react";
import "./JobPosition.css";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import Maximize from "../Aboutus/Maximize/Maximize";
const axios = require("axios");
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

class JobPosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Careers: [{ Department: "Enineering" }],
      redirect: false,
      departments: [
        { departmentName: "Engineering", exist: false },
        { departmentName: "S&G", exist: false },
        { departmentName: "Finance", exist: false },
        { departmentName: "Creative", exist: false },
      ],
      clickedIndex: 0,
    };
  }

  componentDidMount() {
    axios.get(`/general/getcareers`).then((res) => {
      const Careers = res.data;
      this.setState({ Careers });
    });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/JobPost",
            state: { Careers: this.state.Careers },
          }}
        />
      );
    } else {
      return (
        <div className="jobCardModal" onClick={this.props.onCloseModal}>
          <div className="jobIconMini">
            <div className="icon">
              <Maximize />
            </div>
            <div className="postions">
              <div className="jobsCards">
                <div className="EngineeringPositionsTitle">
                  {this.props.Department} job positions.
                </div>
                {this.state.Careers.length <= 1 && (
                  <>
                    <div style={{ margin: "0", alignSelf: "center" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        style={{
                          background: "none",
                          display: "block",
                          shapeRendering: "auto",
                        }}
                        width="110px"
                        height="110px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <circle
                          cx="50"
                          cy="50"
                          r="34"
                          strokeWidth="12"
                          stroke="#49ee3d"
                          strokeDasharray="53.40707511102649 53.40707511102649"
                          fill="none"
                          strokeLinecap="round"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="1s"
                            keyTimes="0;1"
                            values="0 50 50;360 50 50"
                          />
                        </circle>
                      </svg>
                    </div>
                  </>
                )}
                {this.state.Careers.filter(
                  (checkposition) =>
                    checkposition.department == this.props.Department
                ).length > 0 &&
                  this.state.Careers.length > 1 && (
                    <div className="vacancies">
                      {this.state.Careers.filter(
                        (checkposition) =>
                          checkposition.department == this.props.Department
                      ).map((position, index) => (
                        <Link
                          to={`/JobPost/${position.id}/#jobpostSec`}
                          key={index}
                          className="col-6 description"
                        >
                          <div className="cardMODAL">
                            <div className="item">
                              <div className="jobInfo">
                                <span> {position.jobTitle}</span>
                                <span className="Remote">Remote - Egypt</span>
                              </div>
                              <div className="applayIcon">
                                <img src="/images/Careers/vuesax.webp" />
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                {this.state.Careers.filter(
                  (checkposition) =>
                    checkposition.department == this.props.Department
                ).length == 0 &&
                  this.state.Careers.length > 1 && (
                    <>
                      <div className="nojobs">
                        <div>
                          <img src="/images/Careers/case.svg" />
                        </div>
                        <div>
                          <span className="nojobsavaliable">
                            No jobs available!
                          </span>
                        </div>
                        <div className="noopenvacancies">
                          <span> No open vacancies for this department.</span>
                        </div>
                        <div className="wanttonotigied">
                          <Link to="/Careers#getnotified">
                            <span>Want to get notified?</span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
          <div></div>
        </div>
      );
    }
  }
}
export default JobPosition;
