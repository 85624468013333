import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UD } from "@fintold/packages";
import i18n from "i18next";
import { MainModal } from "@fintold/packages";
import { Modal } from "bootstrap";
import "./UploadCompanyBanner.css";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { useLocation } from "react-router-dom";
import { MainButton } from "@fintold/packages";
import { VerificationSteps } from "@fintold/packages";
const UploadCompanyBanner = (props) => {
  const { t } = useTranslation();

  const [uplMainButton, setUplMainButton] = useState(true);
  const [startUpload, setStartUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileToUpload, setFileToUpload] = useState({});
  const [view, setView] = useState(1);
  const { state } = useLocation();

  return (
    <>
      <div
        className="modal-header"
        style={{ width: "43.17vw", marginLeft: "20%" }}
      >
        <div className="Content">
          <div style={{ font: "normal normal bold 35px/41px Raleway" }}>
            {t("CustomizeYourClient")}
          </div>
          <div className="VerificationStepsUploadLogo">
            <VerificationSteps totalSteps={3} currentStep={2} />
          </div>
          <div className="VerificationStepsUploadLogo"></div>
          <div className="UploadCompanyLogo">{t("UploadYourBanner")}</div>
          <div className="BrandYourDashboard">
            {t("UploadTheBannerYouWant")}
          </div>
          <div>
            <UD
              label={t("UploadYourBrand")}
              data={{
                id: state.userId,
              }}
              url="/General/uploadcompanybanner"
              maxFileSize={3145728}
              downloadable={false}
              downloadDoc={null}
              uploaded={false}
              uploadedFile={""}
              removeDocument={() => {}}
              acceptedTypes={["image/png", "image/jpeg"]}
              onSuccess={() => {
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="Container">
          <div></div>
          <div className="Content"></div>
          <div style={{}}></div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(UploadCompanyBanner);
