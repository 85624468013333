import React from "react";
import { useHistory } from "react-router";
import { HashLink } from "react-router-hash-link";
import "./ExpandableCard.css";

const ExpandableCard = (props) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (props.active === true) history.push("/supplychainfinance/#spfSec");
      }}
      className={`expandableCard ${
        props.offset === true ? "upperOffset " : ""
      } ${props.active === true ? "clickable" : ""}`}
    >
      <img className="cardImage" src={props.image} />
      <div className="cardContent">
        <div>
          <small>{props.title}</small>
          <h1>{props.text}</h1>
        </div>
        <div className="cardcontrols">
          {props.active !== true && (
            <div className="cardcomingsoon">Coming soon</div>
          )}
          {props.active == true && <div></div>}
          <div
            className={
              props.active === true ? "maximizeBtn" : "maximizeBtn disabled"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="maximize-2"
              width="16.584"
              height="16.584"
              viewBox="0 0 16.584 16.584"
            >
              <path
                id="Vector"
                d="M0,4.837V3.109A3.105,3.105,0,0,1,3.109,0H4.837"
                transform="translate(1.382 1.382)"
                fill="none"
                stroke={props.darkMaximize === true ? "#292424" : "#FFFFFF"}
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Vector-2"
                data-name="Vector"
                d="M0,0H1.727A3.105,3.105,0,0,1,4.837,3.109V4.837"
                transform="translate(10.365 1.382)"
                fill="none"
                stroke={props.darkMaximize === true ? "#292424" : "#FFFFFF"}
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M4.146,0V1.036A3.105,3.105,0,0,1,1.036,4.146H0"
                transform="translate(11.056 11.056)"
                fill="none"
                stroke={props.darkMaximize === true ? "#292424" : "#FFFFFF"}
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Vector-4"
                data-name="Vector"
                d="M4.837,4.837H3.109A3.105,3.105,0,0,1,0,1.727V0"
                transform="translate(1.382 10.365)"
                fill="none"
                stroke={props.darkMaximize === true ? "#292424" : "#FFFFFF"}
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Vector-5"
                data-name="Vector"
                d="M0,0H16.584V16.584H0Z"
                fill="none"
                opacity="0"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandableCard;
