import React from "react";
import "./VideoHero.css";

const VideoHero = (props) => {
  return (
    <div className="videoHero">
      <div className="overlay"></div>
      <div className="hdLinks">
        <video autoPlay="autoplay" loop="loop" muted="muted">
          {props.hdLinks.map((l) => (
            <source src={l.link} type={l.type} />
          ))}
        </video>
      </div>
      <div className="sdLinks">
        <video autoPlay="autoplay" loop="loop" muted="muted">
          {props.hdLinks.map((l) => (
            <source src={l.link} type={l.type} />
          ))}
        </video>
      </div>
      <div className="videoHeroContent">{props.children}</div>
    </div>
  );
};

export default VideoHero;
