import React, { useRef, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./ActivateSolutionCenter.css";
import { MainModal } from "@fintold/packages";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import i18n from "i18next";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { customFetch } from "../../CustomFetch";

const ActivateSolutionCenter = ({ CompanyCrNo, ...props }) => {
  const modalRef = useRef();
  const [solutions, setSolutions] = useState();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  let history = useHistory();
  //Financial solution name and id are temporary here till it be read from the table
  const supplayFinanceId = "c194f7fb-dd32-4c5f-b4b4-92eddefdbe32";
  const supplayFinanceName = "Supply Chain Finance";

  useEffect(() => {
    const modal = new Modal(modalRef.current);
    if (props.setModal) props.setModal(modal);
  }, [props.setModal]);

  const addsolutionsubscription = async () => {
    if (CompanyCrNo != null) {
      let formData = new FormData();
      formData.append("crNumber", CompanyCrNo);
      formData.append("solutionId", supplayFinanceId);
      const res = await customFetch(`/account/addsolutionsubscription`, {
        body: formData,
        method: "POST",
        credentials: "include",
      });
      if (!res.ok) {
        toast(
          <ToastBody
            icon="error"
            text={"An error has occurred, please try again later"}
          />,
          {
            className: "errorToast",
          }
        );
        return;
      }
      toast(<ToastBody icon="confirm" text={"Solution Activated"} />, {
        className: "defaultToast",
      });
      props.modal.hide();
      if (props.success) props.success();
    }
  };
  return (
    <>
      <div className="mainModalActivateWebsaasn">
        <div
          id={props.modalId}
          className="modal fade"
          data-bs-backdrop="static"
          tabIndex="-1"
          ref={modalRef}
        >
          <div className="headern">
            <button
              onClick={() => {
                if (props.onClose) props.onClose();
                props.modal.hide();
              }}
              aria-label="Close"
              className="dismissn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.279"
                height="10.279"
                viewBox="0 0 10.279 10.279"
              >
                <g transform="translate(1.061 1.061)">
                  <line
                    fill="none"
                    stroke="#338da9"
                    strokeLinecap="round"
                    strokeLinejoin="bevel"
                    strokeWidth="1.5px"
                    x1="8.158"
                    y1="8.158"
                  />
                  <line
                    fill="none"
                    stroke="#338da9"
                    strokeLinecap="round"
                    strokeLinejoin="bevel"
                    strokeWidth="1.5px"
                    x1="8.158"
                    y2="8.158"
                  />
                </g>
              </svg>{" "}
              {props.closeText ? props.closeText : t("Close")}
            </button>
          </div>
          <div
            className={
              props.size != null
                ? `modal-dialog ${props.size}`
                : `modal-dialog modal-xl`
            }
          >
            <div className="modal-content makebackTranspertn">
              <div className="stylingOurSolutionsActn">
                <span>{t("SupplyChainTitle")}</span>
              </div>
              <div className="HelpBuyeresSoln">
                <h4>{t("helpbuyerpara")}</h4>
              </div>
              <div className="ActivateSolutionMicron">
                <p>{t("microfinancpara")}</p>
              </div>
              <div className="ActivateSolutionFlexn">
                <div>
                  <button
                    className="butnActivateSolWebsaasn"
                    onClick={addsolutionsubscription}
                  >
                    {t("ActivatesolutionBtn")}
                  </button>
                </div>
                <div>
                  <HashLink
                    className="StyleMoreDetailsAct"
                    to="/supplychainfinance/#spfSec"
                    onClick={() => {
                      props.modal.hide();
                    }}
                  >
                    {" "}
                    {t("ActivateMoredetails")}
                    <div className="ActivateSolutionSVGWeb">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.83"
                        height="12.89"
                        viewBox="0 0 12.83 12.89"
                      >
                        <g
                          id="ic-actions-new-window"
                          transform="translate(-1.25 -1.146)"
                        >
                          <path
                            id="Path_29"
                            data-name="Path 29"
                            d="M13.33,9.11V12.2A1.133,1.133,0,0,1,12.2,13.33H3.133A1.133,1.133,0,0,1,2,12.2V3.133A1.133,1.133,0,0,1,3.133,2H6.266"
                            transform="translate(0 -0.044)"
                            fill="none"
                            stroke="#007094"
                            stroke-linecap="round"
                            stroke-linejoin="bevel"
                            stroke-width="1.5"
                          />
                          <line
                            id="Line_41"
                            data-name="Line 41"
                            x1="6.872"
                            y2="6.577"
                            transform="translate(6.113 2.228)"
                            fill="none"
                            stroke="#007094"
                            stroke-linecap="round"
                            stroke-linejoin="bevel"
                            stroke-width="1.5"
                          />
                          <path
                            id="Path_30"
                            data-name="Path 30"
                            d="M17,1.9h1.751a1.037,1.037,0,0,1,1.082.974V4.732"
                            transform="translate(-6.502)"
                            fill="none"
                            stroke="#007094"
                            stroke-linecap="round"
                            stroke-linejoin="bevel"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </div>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(ActivateSolutionCenter);
