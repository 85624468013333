import React, { useState, useEffect } from "react";
import "./Founder.css";
import { useTranslation } from "react-i18next";
import Avatar from "../Avatar/Avatar";
import TeamMember from "../TeamMemberPopup/TeamMember";

import Modal from "react-modal";
const customStyles = {
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    marginTop: "75px",
    width: "69vw",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 2000,
  },
};

const Founder = () => {
  const { t } = useTranslation();
  const [member, setMember] = useState({
    name: t("AhmedMoussa"),
    desc: t("TheFinancialMastermind"),
    text: t("WithOverYears"),
    img: "/images/aboutus/ceo.webp",
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="founderContainer">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <TeamMember member={member} />
      </Modal>
      <div className="ourFounder">{t("OurFounders")}</div>

      <div className="founder-avatars-container">
        <div
          onClick={() => {
            setMember({
              name: t("AhmedMoussa"),
              desc: t("TheFinancialMastermind"),
              text: t("WithOverYears"),
              img: "/images/Aboutus/ceo.webp",
            });
            openModal();
          }}
          className="RightAvatar"
        >
          <Avatar
            FounderPosition="CEO"
            FounderName="Ahmed Moussa"
            FounderPicPath="/images/Aboutus/ceo.webp"
          />
        </div>
        {/* <div 
          onClick={() => {
            setMember({
              name: t("KarimMoussa"),
              desc: t("ctoDesc"),
              text: t("ctoText"),
              img: "/images/Aboutus/cto.webp",
            });
            openModal();
          }}
          className="LeftAvatar"
        >
          <Avatar
            FounderPosition="CTO"
            FounderName="Karim Moussa"
            FounderPicPath="/images/Aboutus/cto.webp"
          />
        </div> */}
      </div>
    </div>
  );
};
export default Founder;
