import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MainButton } from "@fintold/packages";
import { TextInput } from "@fintold/packages";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { MainModal } from "@fintold/packages";
import "./ForgotPassword.css";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Invalid email format"),
});

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const [view, setView] = useState(1);
  const [emailError, setEmailError] = useState("");
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    await recaptchaRef.current.execute();
  };

  const forgot = async (token, values, resetForm) => {
    try {
      const res = await fetch("/account/forgotpassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values["email"], token }),
      });
      setIsLoading(false);
      resetForm();
      setView(2);
    } catch (e) {
      setIsLoading(false);
      toast(<ToastBody icon="error" text="Error setting password" />, {
        className: "errorToast",
      });
    }
  };

  return (
    <div className="forgotPasswordsaas">
      <MainModal
        id="forgotpassmodal"
        modal={props.modal}
        setModal={props.setModal}
        closeText={t("close")}
        hideHelper={true}
        size="modal-lg"
        onClose={() => setView(1)}
      >
        <div class="modal-header">
          <h1>{t("Resetyourpassword")}</h1>
        </div>
        <div className="modal-body">
          {view === 1 && (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <TextInput
                        placeholder={t("Enteremail")}
                        label={t("emailAddress")}
                        name={"email"}
                        autoComplete="off"
                        actionLink={
                          <div className="action">
                            {t("NeedHelpCall")}{" "}
                            <a className="highlighted" href="tel:01203057774">
                              {" " + t("CustomerServiceNumber")}
                            </a>
                          </div>
                        }
                      />
                    </div>
                    <div className="col-12">
                      <MainButton
                        btnType={
                          formik.isSubmitting || isLoading ? "loading" : "main"
                        }
                        text={
                          formik.isValid && formik.dirty
                            ? t("Continue")
                            : t("Enteremailcontinue")
                        }
                        type="submit"
                        onClick={null}
                        disabled={!(formik.isValid && formik.dirty)}
                      />
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lf768gUAAAAANNZyskUoAL9twYZ5k8REyMKNoh4"
                        onChange={async (token) => {
                          if (token) {
                            setIsLoading(true);
                            await forgot(
                              token,
                              formik.values,
                              formik.resetForm
                            );
                          }
                        }}
                        size={"invisible"}
                        badge={"bottomleft"}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {view === 2 && (
            <>
              <div className="confirmTabWebsaas">
                <img src="/images/crayon-1779.png" />
                <div>
                  <h3 className="ConfirmHeadwebsaas">{t("linkCreated")}</h3>
                  <span className="ConfirmSubHeadwebsaas">
                    {t("generatednewlink")}
                  </span>
                </div>
              </div>
              <div>
                <MainButton
                  btnType={"main"}
                  text={t("Close")}
                  type="button"
                  onClick={() => {
                    setView(1);
                    props.modal.hide();
                  }}
                  disabled={false}
                />
              </div>
            </>
          )}
        </div>
      </MainModal>
    </div>
  );
};

export default ForgotPassword;
