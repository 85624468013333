import * as React from "react";
import { connect } from "react-redux";
import Footer from "./homepage/Footer";
import NavBar from "./homepage/NavBar";
import VideoHero from "./homepage/VideoHero";
import WhiteSecondaryButton from "./common/buttons/WhiteSecondaryButton.jsx";
import HeroContent from "./homepage/HeroContent";
import "./Home.css";
import CardsContainer from "./homepage/CardsContainer";
import ExpandableCard from "./homepage/ExpandableCard";
import ReadyToSignup from "./homepage/ReadyToSignup";
import FAQContainer from "./homepage/FAQContainer";
import FintoldCard from "./homepage/FinToldCard";
import LaptopScreen from "./homepage/LaptopScreen";
import MaximumSecurity from "./homepage/MaximumSecurity";
import FundingOptions from "./homepage/FundingOptions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div id="homepageSec" className="homepage">
        <NavBar initialFont="white" />
        <VideoHero
          hdLinks={[{ link: "/videos/herohd.mp4", type: "video/mp4" }]}
          sdLinks={[{ link: "/videos/hero.mp4", type: "video/mp4" }]}
        >
          <HeroContent>
            <small>Banks. NBFIs. Clients. All in One Place</small>
            <h1>
              Egypt's First Financial <br /> Market Network.
            </h1>
            <WhiteSecondaryButton onClick={() => history.push("/contactus")}>
              <HashLink to="/contactus/#contactSec">Request a demo</HashLink>
            </WhiteSecondaryButton>
          </HeroContent>
        </VideoHero>
        <div className="HomeSection">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.453"
            height="45.453"
            viewBox="0 0 45.453 45.453"
          >
            <g id="empty-wallet" transform="translate(-172 -188)">
              <g id="Group" transform="translate(176.735 191.874)">
                <path
                  id="Vector"
                  d="M29.431,10.984a3.8,3.8,0,0,0-1.136,3.087,4.038,4.038,0,0,0,4.091,3.542h3.6v2.254a7.137,7.137,0,0,1-7.121,7.121H7.121A7.137,7.137,0,0,1,0,19.867V7.121A7.137,7.137,0,0,1,7.121,0H28.863a7.137,7.137,0,0,1,7.121,7.121V9.848H32.158A3.775,3.775,0,0,0,29.431,10.984Z"
                  transform="translate(0 10.803)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M0,19.629V10.974A5.393,5.393,0,0,1,3.485,5.917L18.522.236a3.6,3.6,0,0,1,4.867,3.371v7.2"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
              <path
                id="Vector-3"
                data-name="Vector"
                d="M9.714,1.932v3.9A1.944,1.944,0,0,1,7.82,7.765H4.108A4.038,4.038,0,0,1,.017,4.223,3.8,3.8,0,0,1,1.154,1.136,3.775,3.775,0,0,1,3.881,0H7.82A1.944,1.944,0,0,1,9.714,1.932Z"
                transform="translate(205.01 212.526)"
                fill="none"
                stroke="#2323ec"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Vector-4"
                data-name="Vector"
                d="M0,0H13.257"
                transform="translate(185.257 210.727)"
                fill="none"
                stroke="#2323ec"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Vector-5"
                data-name="Vector"
                d="M0,0H45.453V45.453H0Z"
                transform="translate(172 188)"
                fill="none"
                opacity="0"
              />
            </g>
          </svg>
          <h1>
            Wide range of financial <br /> solutions to offer your <br />
            clients.
          </h1>
          <p>
            Activate the financial solutions you want to offer to your clients
            in
            <br />
            one click. With no documents required!
          </p>
        </div>
        <CardsContainer>
          <ExpandableCard
            darkMaximize={false}
            active={true}
            title="Supply chain finance"
            image={"/images/home/spfcard.webp"}
            text="Help buyers & suppliers access funding automatically to save costs and reduce waste."
          />
          <ExpandableCard
            darkMaximize={false}
            active={false}
            title="Leasing"
            offset={true}
            image={"/images/home/leasecard.webp"}
            text="Experience our asset finance services with reasonable pricing."
          />
          <ExpandableCard
            darkMaximize={true}
            active={false}
            title="Car loans"
            image={"/images/home/carloancard.webp"}
            text="Power your business with our loan management system adaptable for your needs."
          />
          <ExpandableCard
            darkMaximize={false}
            active={false}
            title="Consumer finance"
            image={"/images/home/cfcard.webp"}
            text="Operate consumer finance contracts & manage suppliers through our system."
          />
          <ExpandableCard
            darkMaximize={false}
            active={false}
            title="Mortgage finance"
            image={"/images/home/mortgagecard.webp"}
            text="Smooth real estate loans to combine all the property’s specifications in one place."
          />
          <ExpandableCard
            darkMaximize={false}
            active={false}
            title="Micro finance"
            image={"/images/home/microcard.webp"}
            text="Manage your loans with our customizable straightforward system."
          />
        </CardsContainer>
        <FundingOptions />
        <MaximumSecurity />
        <LaptopScreen />
        <FintoldCard />
        <FAQContainer
          data={[
            {
              q: t("faq1"),
              a: t("faqa1"),
            },
            {
              q: t("faq2"),
              a: t("faqa2"),
            },
            {
              q: t("faq3"),
              a: t("faqa3"),
            },
          ]}
        />
        <ReadyToSignup />
      </div>
      <Footer />
    </>
  );
};

export default connect()(Home);
