import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Footer.css";

const Footer = (props) => {
  return (
    <div className="fintoldFooter">
      <div className="footerContent">
        <div className="column">
          <img src="/images/home/findtoldfooter.webp" />
        </div>
        <div className="column">
          <h6>PRODUCTS</h6>
          <ul>
            <li>
              <HashLink to="/supplychainfinance/#spfSec">
                Supply Chain Finance
              </HashLink>
            </li>
            <li>
              <HashLink to="/factoring/#factSec">Factoring</HashLink>
            </li>
            <li>
              <a className="disabled">Leasing</a>
            </li>
            <li>
              <a className="disabled">Car Loans</a>
            </li>
            <li>
              <a className="disabled">Consumer Finance</a>
            </li>
          </ul>
        </div>
        {/* <div className="column">
          <h6>FEATURES</h6>
          <ul>
            <li>
              <Link to="/supplychainfinance">Easy Registration</Link>
            </li>
            <li>
              <a className="disabled">Solution Center</a>
            </li>
            <li>
              <a className="disabled">Pricing</a>
            </li>
            <li>
              <a className="disabled">NBFI Dashboard</a>
            </li>
            <li>
              <a className="disabled">Client Dashboard</a>
            </li>
          </ul>
        </div> */}
        <div className="column">
          <h6>FINTOLD</h6>
          <ul>
            <li>
              <HashLink to="/aboutus/#aboutusSec">About Us</HashLink>
            </li>
            <li>
              <HashLink to="/Careers/#careerSec">Careers</HashLink>
            </li>
            <li>
              <HashLink to="/BanksRelations/#BPSec">Banks Relations</HashLink>
            </li>
            <li>
              <HashLink to="/contactus/#contactSec">Contact Us</HashLink>
            </li>
          </ul>
        </div>
        <div className="column">
          <h6>READY TO JOIN?</h6>
          <ul>
            <li>
              <HashLink className="signupLink" to="/contactus/#contactSec">
                Request a demo
              </HashLink>
            </li>
            <li>
              {/* <Link className="loginLink" to="/login">
                Login now
              </Link> */}
            </li>
          </ul>
        </div>
      </div>
      <div className="policyAndTerms">
        <p className="cp">
          Copyright © 2021 - Fintold is a registered trademark under Tahrir
          Capital Holdings.
        </p>
        <div className="tplinks">
          {" "}
          <HashLink to="/privacypolicy/#ppSec">Privacy policy</HashLink>
          <HashLink to="/termsofservice/#tofSec">Terms of service</HashLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
