import React from "react";
import "./FinancialDesign.css";

const FinancialDesign = (props) => {
  return (
    <div className="financialDesign">
      <img src="/images/home/financialdesign.webp" />
      <div>
        <small>Financial design</small>
        <h1>
          Financially designed to <br /> grant businesses better <br /> access
          to liquidity.
        </h1>
      </div>
      <div>
        <div className="feature">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2.1875vw"
              height="4.023148vh"
              viewBox="0 0 42 43.446"
            >
              <g
                id="noun-exchange-4518178"
                transform="translate(-187.594 -111.995)"
              >
                <path
                  id="Path_111"
                  data-name="Path 111"
                  d="M211.932,245.326a2.982,2.982,0,0,0-2.614,3.019v4.467l-16.777-16.777a2.9,2.9,0,1,0-4.1,4.1l16.777,16.777h-4.591a2.9,2.9,0,0,0-2.9,3.179,2.983,2.983,0,0,0,3.019,2.614h8.566a5.792,5.792,0,0,0,5.792-5.793v-8.689a2.9,2.9,0,0,0-3.179-2.9Z"
                  transform="translate(0 -107.263)"
                  fill="#2323ec"
                />
                <path
                  id="Path_112"
                  data-name="Path 112"
                  d="M309.494,117.788h4.591a2.9,2.9,0,0,0,2.9-3.179A2.983,2.983,0,0,0,313.962,112H305.4a5.792,5.792,0,0,0-5.792,5.793v8.689a2.9,2.9,0,0,0,3.179,2.9,2.982,2.982,0,0,0,2.614-3.019v-4.467l16.777,16.777a2.9,2.9,0,1,0,4.1-4.1Z"
                  transform="translate(-97.527 0)"
                  fill="#2323ec"
                />
              </g>
            </svg>
          </div>
          <div>
            <h6>Improve your business cash flow.</h6>
            <p>
              The solution allows businesses to convert outstanding invoices
              into
              <br />
              liquidity to meet current business's cash flow needs.
            </p>
          </div>
        </div>
        <div className="feature">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2.1875vw"
              height="3.8863889vh"
              viewBox="0 0 42 41.973"
            >
              <g id="noun-ppc-4589084" transform="translate(-170.802 -100.802)">
                <path
                  id="Path_113"
                  data-name="Path 113"
                  d="M301.078,232.3a6.44,6.44,0,0,0-.879,5.784l4.938,14.815a5.247,5.247,0,0,0,4.722,3.588h.256a5.246,5.246,0,0,0,4.807-3.115l1.449-3.279c0-.065.065-.124.1-.19l4.486,4.486h0a2.625,2.625,0,1,0,3.712-3.712l-4.5-4.466.19-.1,3.279-1.449h0a5.247,5.247,0,0,0-.472-9.772l-14.842-4.932a6.262,6.262,0,0,0-7.247,2.341Zm5.207,2.545a1.2,1.2,0,0,1,.38.066l14.815,4.965-3.279,1.449a13.117,13.117,0,0,0-6.637,6.637l-1.449,3.279-4.938-14.815a1.181,1.181,0,0,1,1.108-1.581Z"
                  transform="translate(-113.953 -113.711)"
                  fill="#2323ec"
                />
                <path
                  id="Path_114"
                  data-name="Path 114"
                  d="M222.032,148.316l-1.968-1.968a2.625,2.625,0,1,0-3.712,3.712l1.968,1.968a2.625,2.625,0,1,0,3.712-3.712Z"
                  transform="translate(-39.536 -39.533)"
                  fill="#2323ec"
                />
                <path
                  id="Path_115"
                  data-name="Path 115"
                  d="M427.272,152.036l1.968-1.968a2.625,2.625,0,1,0-3.712-3.712l-1.968,1.968a2.625,2.625,0,1,0,3.712,3.712Z"
                  transform="translate(-222.477 -39.541)"
                  fill="#2323ec"
                />
                <path
                  id="Path_116"
                  data-name="Path 116"
                  d="M218.32,353.555l-1.968,1.968a2.625,2.625,0,1,0,3.712,3.712l1.968-1.968a2.625,2.625,0,1,0-3.712-3.712Z"
                  transform="translate(-39.536 -222.473)"
                  fill="#2323ec"
                />
                <path
                  id="Path_117"
                  data-name="Path 117"
                  d="M173.425,262.85H176.7a2.623,2.623,0,1,0,0-5.246h-3.279a2.623,2.623,0,1,0,0,5.246Z"
                  transform="translate(0 -138.438)"
                  fill="#2323ec"
                />
                <path
                  id="Path_118"
                  data-name="Path 118"
                  d="M332.85,106.7v-3.279a2.623,2.623,0,1,0-5.246,0V106.7a2.623,2.623,0,1,0,5.246,0Z"
                  transform="translate(-138.438 0)"
                  fill="#2323ec"
                />
              </g>
            </svg>
          </div>
          <div>
            <h6>Quickest access to money.</h6>
            <p>
              Automating the whole process allows for shorter to no waiting
              times
              <br />
              to complete actions. Businesses can upload and cash out their
              <br />
              invoices online without paper work or wait times.
            </p>
            <div className="moreDetails">
              {/* More details on financial process. */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialDesign;
