import React, { useState, useEffect } from "react";
import "./GetAMeeting.css";
import { useTranslation } from "react-i18next";
import NotifyMe from "./NotifyMe/NotifyMe";
import Footer from "../homepage/Footer";
import NavBar from "../homepage/NavBar";

const GetAMeeting = (props) => {
  const { t } = useTranslation();
  return (
    <div id="contactSec">
      <NavBar initialFont="black" />
      <div className="GetAMeetingContainer">
        <div className="GetAMeetingDescription">
          <div className="NeedHelp"> {t("NeedHelp")} </div>
          <div className="SitDown">{t("SitDown")}</div>
          <div className="OurExperts">{t("OurExperts")}</div>
        </div>
        <div className="GetMeetingPanner">
          <img src="/images/GetAMeeting/andrew-power-y9L5-wmifaY-unsplash.webp" />
        </div>
        <div className="NotifyMeAndContact">
          <div className="NotifyMeText">
            <div className="EnterContactHeaderText">
              {t("EnterYourContact")}{" "}
            </div>
            <div className="EnterContactDetailsText">
              {t("EnterContactDetails")}
            </div>
            <div className="NotifyMeComonent"></div>
          </div>
          <NotifyMe />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default GetAMeeting;
