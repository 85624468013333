import React, { useState, useEffect } from "react";
import "./TeamMember.css";
import { useTranslation } from "react-i18next";
import Maximize from "../Maximize/Maximize";
import { propTypes } from "react-bootstrap/esm/Image";

const TeamMember = (props) => {
  const { t } = useTranslation();
  return (
    <div className="TeamMemberContainer">
      <div className="TeamMemberDetails">
        <div className="MemberName">{props.member.name}</div>
        <div className="MemberTitle">{props.member.desc}</div>
        <div className="MemberExperience">{props.member.text}</div>
      </div>
      <div className="TeamMemberImage">
        <img src={props.member.img} />
        {/* <Maximize /> */}
      </div>
    </div>
  );
};
export default TeamMember;
