import * as React from "react";
import {
  Route,
  Redirect,
  Switch,
  Router,
  useLocation,
  useHistory,
} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Aboutus from "./components/Aboutus/Aboutus";
import Careers from "./components/Careers/Careers";
import TermsOfService from "./components/TermsOfService/TermsOfService";
import SignUp from "./components/Identity/Signup";
import Login from "./components/Identity/Login";
import GetAMeeting from "./components/GetAMeeting/GetAMeeting";
import EmailVerification from "./components/Identity/EmailVerification";
import ResetPassword from "./components/Identity/ResetPassword";
import GettingStarted from "./components/AddCompany/GettingStarted";
import JobPost from "./components/JobPost/JobPost";
import ChooseBanner from "./components/AddCompany/ChooseBanner/ChooseBanner";
import BrandingDone from "./components/AddCompany/BrandingDone/BrandingDone";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import SPFHome from "./components/spfhomepage/index";
import { userLogin } from "./store/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./indexRTL.css";
import "./custom.css";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./components/TermsOfService/PrivacyPolicy";
import BankPartnerHome from "./components/bankhomepage/index";
import { customFetch } from "./CustomFetch";
import ClientPasswordReset from "./components/Identity/ClientPasswordReset";
import FactoringHome from "./components/factoringhomepage/index"

const App = (props: any) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  React.useEffect(() => {
    const { actions } = props;
    if (i18n.dir() === "rtl") {
      import("./indexRTL.css");
      import("bootstrap/dist/css/bootstrap.rtl.css");
      document.documentElement.dir = "rtl";
      document.documentElement.lang = "ar";
    } else {
      import("./index.css");
      import("bootstrap/dist/css/bootstrap.css");
      document.documentElement.dir = "ltr";
      document.documentElement.dir = "en";
    }
  }, []);
  React.useEffect(() => {
    const relogin = async () => {
      const res = await customFetch("/account/relogin", {
        credentials: "include",
      });
      const data = await res.json();
      if (data.login === true) {
        const user = JSON.parse(data.user).user;
        //if (data.user._url !== null) window.location.href = data.url;
        //else {
        //checking request for having already business
        const resbusiness = await customFetch(`/account/gettingstarted`);

        const datahavebusiness = await resbusiness.json();
        props.actions.userLogin({
          isAuthenticated: true,
          email: user.Email,
          id: user.Id,
          name: user.FirstName + " " + user.LastName,
          isActivated: user.IsActivated,
        });
        if (
          location.pathname.includes("login") ||
          location.pathname.includes("signup") ||
          location.pathname.includes("gettingstarted")
        ) {
          if (datahavebusiness.url != null) {
            window.location.href = datahavebusiness.url;
          } else {
            history.push({
              pathname: "/gettingstarted",
            });
          }
        }
        // }
      }
    };
    relogin();
  }, []);
  return (
    <>
      <Router history={history}>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              path="/signup"
              render={(renderProps) => (
                <>
                  <SignUp {...renderProps} />
                </>
              )}
            />
            <Route
              path="/resetpassword"
              render={(renderProps) =>
                props.user.isAuthenticated ? (
                  <Redirect to={"/"} />
                ) : (
                  <ClientPasswordReset {...renderProps} />
                )
              }
            />
            <Route path="/setpassword" component={ResetPassword} />
            <Route
              path="/login"
              render={(renderProps) => (
                <>
                  <Login {...renderProps} />
                </>
              )}
            />
            <Route path="/gettingstarted" component={GettingStarted} />
            <Route path="/aboutus" component={Aboutus} />
            <Route path="/TermsOfService" component={TermsOfService} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/emailverification" component={EmailVerification} />
            <Route
              path="/Careers"
              render={(renderProps) => (
                <>
                  <Careers {...renderProps} />
                </>
              )}
            />
            <Route
              path="/contactus"
              render={(renderProps) => (
                <>
                  <GetAMeeting {...renderProps} />
                </>
              )}
            />
            <Route path="/supplychainfinance" component={SPFHome} />
            <Route path="/factoring" component={FactoringHome} />
            <Route
              path="/JobPost/:id"
              render={(renderProps) => (
                <>
                  <JobPost {...renderProps} />
                </>
              )}
            />
            <Route path="/ChooseBanner" component={ChooseBanner} />
            <Route path="/BrandingDone" component={BrandingDone} />
            <Route
              path="/BanksRelations"
              render={(renderProps) => (
                <>
                  <BankPartnerHome {...renderProps} />
                </>
              )}
            />
          </Switch>
        </Layout>
      </Router>
      <ToastContainer
        closeButton={false}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={false}
      />
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      userLogin: bindActionCreators(userLogin, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
