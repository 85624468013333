import React from "react";
import { HashLink } from "react-router-hash-link";
import "./Dashboard.css";

const Dashboard = (props) => {
  return (
    <div className="spfdashboardsec">
      <img className="img1-d1" src="/images/home/dashboard1.webp" />
      <img className="img2-d2" src="/images/home/dashboard2.webp" />
      <img className="img3-d3" src="/images/home/dashboard3.webp" />
      <img className="img4-d4" src="/images/home/dashboard4.webp" />
      <img className="img5-d5" src="/images/home/dashboard5.webp" />
      <h2>DASHBOARD</h2>
      <h1>
        Complete control over the <br /> financial process.
      </h1>
      <p>
        Through your dashboard, you will be able <br /> to manage the solutions
        you offer and clients <br /> registered on your website.
      </p>
      <HashLink to="#buyerfeaturesSection">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="49.453"
          height="49.453"
          viewBox="0 0 49.453 49.453"
        >
          <g id="arrow-down" transform="translate(-236 -252)" opacity="0.6">
            <path
              id="Vector"
              d="M32.639,0,19.2,13.435a4.092,4.092,0,0,1-5.77,0L0,0"
              transform="translate(244.407 270.442)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H49.453V49.453H0Z"
              transform="translate(285.453 301.453) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </svg>
      </HashLink>
    </div>
  );
};

export default Dashboard;
