import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  ToastBody,
  MainButton,
  PasswordInput,
  LoadingSpinner,
} from "@fintold/packages";
import { customFetch } from "../../CustomFetch";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import "./ResetPassword.css";

function getLink() {
  const { search } = useLocation();
  const index = search.indexOf("link");
  const link = search.substring(index + 5);
  return link;
}

const ClientPasswordReset = (props) => {
  const { t } = useTranslation();
  const [same, setSame] = useState(false);
  const [valid, setValid] = useState(null);
  const { promiseInProgress } = usePromiseTracker();
  const link = getLink();

  useEffect(() => {
    trackPromise(validateLink());
  }, []);

  const validateLink = async () => {
    const res = await customFetch(`/account/validatelink?link=${link}`);
    if (!res.ok) {
      toast(<ToastBody icon="error" text={t("ErrorCccurredTryAgainLater")} />, {
        className: "errorToast",
      });
      return;
    }
    const data = await res.json();
    if (data.valid) setValid(true);
    else setValid(false);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("PasswordMustLeast8Characters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("PasswordMustAtLeastLetterNumberSymbol")
      )
      .required(),
    confirmPassword: Yup.string().test(
      t("PasswordsMatch"),
      t("PasswordsMustMatch"),
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const handleSubmit = async (values) => {
    setSame(false);
    try {
      const res = await customFetch(
        `account/resetpasswordbylink?link=${link}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values["password"]),
        }
      );
      const data = await res.json();
      if (res.ok) {
        if (data.valid == true) {
          toast(<ToastBody icon="confirm" text={t("passwordResetSucc")} />, {
            className: "default",
          });
          props.history.replace("/login");
        }
        if (data.same == true) {
          setSame(true);
        } else setValid(false);
      } else
        toast(<ToastBody icon="error" text={t("ErrorSettingPassword")} />, {
          className: "errorToast",
        });
    } catch (e) {
      toast(<ToastBody icon="error" text={t("ErrorSettingPassword")} />, {
        className: "errorToast",
      });
    }
  };

  return (
    <div className="container resetPassword">
      <img className="logo" width="90" src="/images/logo-black.webp" />
      <h1 className="resetHead">{t("createANewPassword")}</h1>
      {/* <p className="resetSubHead">{t("youAreUsingGenPass")}</p> */}
      <div className="resetContent row">
        <LoadingSpinner />
        {promiseInProgress === false && (
          <>
            {valid && (
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <Form className="col-md-10 mx-auto my-4">
                    <PasswordInput
                      test={true}
                      placeholder={t("enterPassword")}
                      label={t("password")}
                      name="password"
                      showicon="true"
                      passRegex="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                    />
                    <PasswordInput
                      test={false}
                      placeholder={t("enterPasswordAgain")}
                      label={t("confirmPassword")}
                      name="confirmPassword"
                    />
                    {same && (
                      <h6 className="errorText mb-4">{t("samePasswordErr")}</h6>
                    )}
                    <MainButton
                      btnType={formik.isSubmitting ? "loading" : "main"}
                      text={
                        formik.isSubmitting
                          ? t("pleaseWait")
                          : t("ResetPassword")
                      }
                      type="submit"
                      onClick={null}
                      disabled={!(formik.isValid && formik.dirty)}
                    />
                  </Form>
                )}
              </Formik>
            )}
            {!valid && (
              <h1
                style={{ textAlign: "center" }}
                className="col-md-10 mx-auto my-4"
              >
                invalid link
              </h1>
            )}
          </>
        )}
        <div className="helper">
          {t("havingProbLoggingIn")}{" "}
          <a href={`tel:${t("CustomerServiceNumber")}`}>
            {t("contactSupport")}
          </a>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    business: state.business,
  };
}

export default connect(mapStateToProps)(ClientPasswordReset);
