import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userLogin } from "../../store/user";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { MainButton } from "@fintold/packages";
import "./EmailVerification.css";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { customFetch } from "../../CustomFetch";

const EmailVerification = (props) => {
  const [valid, setValid] = useState(true);
  const [code, setCode] = useState([]);
  const [resendCard, setResendCard] = useState(false);
  const [user, setUser] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const range = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => min + i);
  let className = "";
  let temp = [];

  useEffect(() => {
    setTimeout(() => {
      setResendCard(true);
    }, 40000);
  }, []);

  useEffect(() => {
    const getLoggedInUser = async () => {
      const res = await customFetch("/account/getloggedinuser");
      try {
        const data = await res.text();

        setUser(data);
      } catch (e) {
        console.log("error " + e);
      }
    };
    getLoggedInUser();
  }, []);

  const pushToCode = (digit, index) => {
    if (index.toString() === code.length.toString()) {
      temp = code.concat(digit);
    } else {
      code.splice(index, 1);
      code.splice(index, 0, digit);
      temp = [...code];
    }
    setCode(temp);
  };

  const pasteCode = (e) => {
    const code = (e.clipboardData || window.clipboardData).getData("text");
    if (!code || isNaN(parseInt(code)) || code.length != 6) return;
    setCode([...code]);
  };

  const resendCode = async (e) => {
    const res = await customFetch("/account/ResendVerificationCode", {
      credentials: "include",
      method: "GET",
    });
    if (!res.ok) {
      toast(
        <ToastBody
          icon="error"
          text={"An error has occurred, please try again later"}
        />,
        {
          className: "errorToast",
        }
      );
      return;
    }
    setResendCard(false);
    setTimeout(() => {
      setResendCard(true);
    }, 40000);
    toast(
      <ToastBody
        icon="confirm"
        text={"Verification code resent successfully"}
      />,
      {
        className: "defaultToast",
      }
    );
  };

  valid ? (className = "codeInput") : (className = "codeInputError");

  const handleSubmit = async (e) => {
    let codeAsNumber = 0;
    setValid(true);

    range(0, 5).map(
      (index) =>
        (codeAsNumber += parseInt(code[index]) * Math.pow(10, 6 - 1 - index))
    );
    const res = await customFetch("/account/activateuser", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(codeAsNumber),
      headers: {
        "Content-Type": "application/json",
      },
    });
    setLoading(false);
    if (!res.ok) {
      toast(<ToastBody icon="error" text={t("ErrorCccurredTryAgainLater")} />, {
        className: "errorToast",
      });
      return;
    }
    const data = await res.json();
    if(data.validated == false)
    {
      setValid(false);
      if(data.resent == true)
        toast(<ToastBody icon="error" text={t("VerificationMaximumTrials")} />, {
          className: "errorToast",
        });
      return;
    }else{
      props.dispatch(
        userLogin({
          ...props.user,
          isActivated: true,
        })
      );
      props.history.push({
        pathname: "/gettingstarted",
        state: {
          businessData: null,
          isDeployed: false,
          hasSolution: false,
          url: null,
        },
      });
    }
    setLoading(false);
    setCode([]);
  };

  return (
    <div className="emailVerification">
      <div className="mainContainer">
        <div>
          <div
            className="verificationUpperTag"
            onClick={() =>
              props.history.push({
                pathname: "/gettingstarted",
                state: {
                  businessData: null,
                  isDeployed: false,
                  hasSolution: false,
                  url: null,
                },
              })
            }
          >
            <svg
              style={{ marginInlineEnd: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="17"
              height="17"
              viewBox="0 0 172 172"
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#007094">
                <path d="M40.90039,30.76628l-10.13411,10.13411l45.09961,45.09961l-45.09961,45.09961l10.13411,10.13411l45.09961,-45.09961l45.09961,45.09961l10.13411,-10.13411l-45.09961,-45.09961l45.09961,-45.09961l-10.13411,-10.13411l-45.09961,45.09961z"></path>
              </g>
            </svg>
            {t("DoItLater")}
          </div>
          <h1 className="codeHeader">{t("EnterVerificationCode")}</h1>
          <p className="codeParagraph">
            {t("WeSentVerificationCodeTo")} {user} {t("PleaseEnterCode")}{" "}
            {/* <a
              onClick={() => {
                props.history.push("signup");
              }}
            >
              {t("ChangeEmail")}
            </a> */}
          </p>
          <div className="codeInputLabel">
            <label htmlFor="digit" className="emailLabel">
              {t("EmailVerificationCode")}
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="17.49"
              viewBox="0 0 17.49 17.49"
            >
              <path
                d="M8.745,1.457A7.287,7.287,0,1,1,1.457,8.745,7.3,7.3,0,0,1,8.745,1.457ZM8.745,0A8.745,8.745,0,1,0,17.49,8.745,8.745,8.745,0,0,0,8.745,0Zm.729,13.117H8.016V7.287H9.474ZM8.745,4.19a.911.911,0,1,1-.911.911A.911.911,0,0,1,8.745,4.19Z"
                fill="#787573"
              />
            </svg>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="codeContainer">
              {range(0, 5).map((inputId) => (
                <input
                  contenteditable="true"
                  onPaste={pasteCode}
                  key={inputId}
                  id={inputId}
                  className={className}
                  type="text"
                  maxLength="1"
                  value={code[inputId] ? code[inputId] : ""}
                  onChange={(e) => {
                    let nextElement = inputId != 5 ? inputId + 1 : inputId - 5;
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      pushToCode(e.target.value, inputId);
                      if (re.test(e.target.value)) {
                        document.getElementById(nextElement).focus();
                      }
                    }
                  }}
                />
              ))}
            </div>
            {resendCard && (
              <span className="card flex">
                <span>{t("AskForAnotherCode")}</span>
                <span className="flex">
                  <button className="cardBtn" onClick={resendCode}>
                    {t("ResendCode")}
                  </button>
                  <a
                    onClick={(e) => {
                      setResendCard(false);
                    }}
                  >
                    {" "}
                    <svg
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="17"
                      height="17"
                      viewBox="0 0 172 172"
                    >
                      <path d="M0,172v-172h172v172z" fill="none"></path>
                      <g fill="#007094">
                        <path d="M40.90039,30.76628l-10.13411,10.13411l45.09961,45.09961l-45.09961,45.09961l10.13411,10.13411l45.09961,-45.09961l45.09961,45.09961l10.13411,-10.13411l-45.09961,-45.09961l45.09961,-45.09961l-10.13411,-10.13411l-45.09961,45.09961z"></path>
                      </g>
                    </svg>
                  </a>
                </span>
              </span>
            )}
            {!valid && <p className="errorParagraph">{t("WrongCode")}</p>}

            <MainButton
              btnType={loading ? "loading" : "main"}
              text={loading ? t("verifying") : t("VerifyEmail")}
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                handleSubmit();
              }}
              disabled={
                code.length < 6 ||
                [...code].filter((num) => num == "").length > 0
              }
            />
          </form>
          <p className="bottomParagraph">
            {t("Didn'tReceiveCode")}{" "}
            <a
              className="verificationLowerTag"
              style={{ color: "#1D1C1C" }}
              onClick={(e) => {
                resendCode();
                e.preventDefault();
                setResendCard(true);
              }}
            >
              {t("SendItAgain")}
            </a>{" "}
            {/* {t("Or")}{" "}
            <a
              className="verificationLowerTag"
              style={{ color: "#1D1C1C" }}
              onClick={() => {
                props.history.push("signup");
              }}
            >
              {t("ChangeEmail")}
            </a>{" "} */}
            {t("Or")}{" "}
            <a
              className="verificationLowerTag"
              onClick={() =>
                props.history.push({
                  pathname: "/gettingstarted",
                  state: {
                    businessData: null,
                    isDeployed: false,
                    hasSolution: false,
                    url: null,
                  },
                })
              }
            >
              {t("DoItLater")}
            </a>
          </p>
        </div>
        <footer className="verificationFooter">
          {t("ThisWebsiteIsProtectedWithRecaptcha")}
        </footer>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(EmailVerification);
