import React from "react";
import "./Customize.css";

const Customize = (props) => {
  return (
    <div className="spfcustomize">
      <img src={"/images/home/customize.webp"} />
      <div className="overlay"></div>
      <h1>Customize. Launch. Manage.</h1>
      <p>
        We allow you full customization over your
        <br /> solution activation process, required
        <br /> documents and set of rules.
      </p>
    </div>
  );
};

export default Customize;
