import React from "react";
import "./WhiteSecondaryButton.css";

const WhiteSecondaryButton = (props) => {
  return (
    <button
      className={
        props.inverted !== true ? "whitesecBtn" : "whitesecBtn inverted"
      }
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default WhiteSecondaryButton;
