import Reat from "react";
import "./Everyone.css";

const Everyone = (props) => {
  return (
    <div className="everyone">
      <div className="images">
        <div className="col1">
          <img src="/images/home/reg1.webp" />
          <img src="/images/home/reg2.webp" />
        </div>
        <div className="col2">
          <img src="/images/home/reg3.webp" />
          <img src="/images/home/reg4.webp" />
        </div>
      </div>
      <div className="regText">
        <small>Works for everyone.</small>
        <h1>
          Let them
          <br /> register &<br /> login the
          <br /> way they
          <br /> want.
        </h1>
        <p>
          Allow your customers the option to register
          <br /> through facebook, LinkedIn or email
          <br /> address.
        </p>
      </div>
    </div>
  );
};

export default Everyone;
