import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { Dropdown } from "bootstrap";
// import { LoadingSpinner } from "@fintold/packages";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";

const DropDownInput = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isValid = meta.touched && typeof meta.error == "undefined";
  const inputDropDownRef = useRef();
  const [dropDown, setDropDown] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSelection, setShowSelection] = useState(false);
  const { promiseInProgress } = usePromiseTracker({ area: "dropdownlist" });

  const getList = async (order, id) => {
    const list = await props.getList(order, id);
    setList(list);
  };

  useEffect(() => {
    trackPromise(getList(0), "dropdownlist");
  }, []);

  useEffect(() => {
    setDropDown(new Dropdown(inputDropDownRef.current));
    inputDropDownRef.current.addEventListener("show.bs.dropdown", function () {
      setShowSelection(false);
    });
    inputDropDownRef.current.addEventListener(
      "hidden.bs.dropdown",
      function () {
        inputDropDownRef.current.blur();
        setShowSelection(true);
        helpers.setTouched(true);
      }
    );
  }, []);

  const handleChange = (e) => {
    setSearchWord(e.target.value);
    search(e.target.value);
  };

  const search = (char) => {
    if (char == "") {
      setFilteredList([]);
    } else {
      const filtered = list.filter(
        (item) =>
          item.name && item.name.toLowerCase().startsWith(char.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  const goBack = async () => {
    let temp = [...selectedItems];
    temp.pop();
    setSelectedItems(temp);

    temp = meta.value;
    temp.pop();
    helpers.setValue(temp);
    trackPromise(getList(temp.length, temp[temp.length - 1]), "dropdownlist");
  };

  let className = "customInput";
  if (!isValid && meta.touched) className += " error";
  return (
    <div className="inputControl customDropDown">
      <div
        className={
          props.disabled === true ? "inputLabel disabled" : "inputLabel"
        }
      >
        <label htmlFor={props.id || props.name}>{label}</label>
        <div>
          {isValid && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="17.49"
              viewBox="0 0 17.49 17.49"
            >
              <path
                d="M8.745,1.457A7.287,7.287,0,1,1,1.457,8.745,7.3,7.3,0,0,1,8.745,1.457ZM8.745,0A8.745,8.745,0,1,0,17.49,8.745,8.745,8.745,0,0,0,8.745,0ZM7.317,12.389,4.038,9.241,5.055,8.2,7.3,10.336,12.416,5.1l1.036,1.027L7.317,12.389Z"
                fill="#12aa3b"
              />
            </svg>
          )}
          {!isValid && meta.touched && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="17.49"
              viewBox="0 0 17.49 17.49"
            >
              <path
                d="M8.745,1.457A7.287,7.287,0,1,1,1.457,8.745,7.3,7.3,0,0,1,8.745,1.457ZM8.745,0A8.745,8.745,0,1,0,17.49,8.745,8.745,8.745,0,0,0,8.745,0Zm.729,13.117H8.016V7.287H9.474ZM8.745,4.19a.911.911,0,1,1-.911.911A.911.911,0,0,1,8.745,4.19Z"
                fill="#E92121"
              />
            </svg>
          )}
          {!isValid && !meta.touched && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="17.49"
              viewBox="0 0 17.49 17.49"
            >
              <path
                d="M8.745,1.457A7.287,7.287,0,1,1,1.457,8.745,7.3,7.3,0,0,1,8.745,1.457ZM8.745,0A8.745,8.745,0,1,0,17.49,8.745,8.745,8.745,0,0,0,8.745,0Zm.729,13.117H8.016V7.287H9.474ZM8.745,4.19a.911.911,0,1,1-.911.911A.911.911,0,0,1,8.745,4.19Z"
                fill="#787573"
              />
            </svg>
          )}
        </div>
      </div>
      <div className="dropdown">
        <input type="hidden" {...field} {...props}></input>
        <input
          placeholder={placeholder}
          className={className}
          data-bs-auto-close="outside"
          data-bs-toggle="dropdown"
          id="tttteee"
          aria-expanded="false"
          autoComplete="off"
          ref={inputDropDownRef}
          value={
            showSelection == true
              ? selectedItems
                  .map((i) => `${i} >`)
                  .join(" ")
                  .replace(/^\>+|\>+$/g, "")
              : inputVal
          }
          onChange={(e) => {
            setInputVal(e.target.value);
            handleChange(e);
          }}
        />
        <div className="inputFooter">
          <p className="errorText">
            {meta.touched && meta.error && meta.error}
          </p>
          {props.actionLink}
        </div>
        <ul id="jjhhjj" class="dropdown-menu" aria-labelledby="tttteee">
          {meta.value.length > 0 && (
            <li className="selectionList">
              <span className="dropdown-item listItemName">
                <span className="goBackBtn" onClick={goBack}>
                  {" "}
                  {"<"} Go back{" "}
                </span>
                {selectedItems.map((item, index) => {
                  if (index == props.listSize - 1)
                    return <span className="dropSelection">{`${item}`}</span>;
                  else
                    return (
                      <span className="dropSelection">{`${item} > `}</span>
                    );
                })}
              </span>
            </li>
          )}
          {promiseInProgress === true && (
            <li>{/* <LoadingSpinner area="dropdownlist" /> */}</li>
          )}
          {promiseInProgress === false && (
            <>
              {meta.value.length < props.listSize &&
                ((filteredList.length > 0 &&
                  filteredList.map((item) => (
                    <>
                      <li
                        key={item.id}
                        onClick={async () => {
                          helpers.setValue([...meta.value, item.id]);
                          setSelectedItems([...selectedItems, item.name]);
                          await trackPromise(
                            getList(meta.value.length + 1, item.id),
                            "dropdownlist"
                          );
                          setInputVal("");
                          setFilteredList([]);
                          if (meta.value.length + 1 == props.listSize) {
                            dropDown.hide();
                          }
                        }}
                      >
                        <span className="dropdown-item listItemName">
                          <span className="front">
                            {(searchWord &&
                              `${item.name
                                .toString()
                                .substr(0, searchWord.length)}`) ||
                              ""}
                          </span>
                          {searchWord.length < item.name.toString().length && (
                            <span className="latter">
                              {(searchWord &&
                                `${item.name
                                  .toString()
                                  .substr(searchWord.length)}`) ||
                                `${item.name}`}
                            </span>
                          )}
                        </span>
                      </li>
                      <div className="divider"></div>
                    </>
                  ))) ||
                  (searchWord.length > 0 && <li></li>) ||
                  list.map((item) => (
                    <>
                      <li
                        key={item.id}
                        onClick={async () => {
                          helpers.setValue([...meta.value, item.id]);
                          setSelectedItems([...selectedItems, item.name]);
                          await trackPromise(
                            getList(meta.value.length + 1, item.id),
                            "dropdownlist"
                          );
                          if (meta.value.length + 1 == props.listSize) {
                            dropDown.hide();
                          }
                        }}
                      >
                        <span class="dropdown-item">{item.name}</span>
                      </li>
                      <div className="divider"></div>
                    </>
                  )))}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DropDownInput;
