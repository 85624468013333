import React from "react";
import Footer from "../homepage/Footer";
import BlackNavBar from "./BlackNavBar";
import BankPartnershipProgram from "./BankPartnershipProgram";
import InnovativeSPF from "./InnovativeSPF";
import Dashboard from "./Dashboard";
import BankWorkflow from "./BankWorkflow";
import ContactDetails from "./ContactDetails";
import { useTranslation } from "react-i18next";

const Index = (props) => {
  const { t } = useTranslation();
  return (
    <div id="BPSec">
      <BlackNavBar initialFont="white" />
      <BankPartnershipProgram />
      <InnovativeSPF />
      <Dashboard />
      <div id="EasyBankWorkFlowSection">
        <BankWorkflow />
      </div>
      <ContactDetails />
      <Footer />{" "}
    </div>
  );
};

export default Index;
