import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "../../store/user";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { PasswordInput, TextInput, MainButton } from "@fintold/packages";
import "./Signup.css";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";


const SignUp = (props) => {
  const { t } = useTranslation();
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);


  // Yup.addMethod(Yup.mixed, "IsEmailExist", function (msg) {
  //   return this.test({
  //     name: "email",
  //     message: msg,
  //     test: (value) => {
  //       return new Promise((resolve, reject) => {
  //         fetch(`/account/isusereg?email=${value}`)
  //           .then(async (res) => {
  //             const exist = await res.json();
  //             resolve(!exist);
  //           })
  //           .catch((err) => reject());
  //       });
  //     },
  //   });
  // });

  const SignupSchema = Yup.object().shape({
    FirstName: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Email: Yup.string()
      .email("Invalid email")
      .required("Email can't be empty"),
      // .IsEmailExist("email already taken"),
    Password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "password must contain at least 1 letter, 1 number and 1 symbol"
      )
      .required("Password is required"),
  });

  const handleSubmit = async (values) => {
    await recaptchaRef.current.execute();
  };

  const signup = async (token, values) => {
    let data = {};
    let res = "";
    if (props.user.id !== null && props.user.id.length > 0) {
      res = await fetch("/account/changeuseremail", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          userData: values,
        }),
      });
    } else {
      res = await fetch("/account/signup", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          ...values,
          token,
        }),
      });
    }
    await fetch("/account/renew-csrf-token");
    setIsLoading(false);
    data = await res.json();
    if (data !== null) {
      props.dispatch(
        userLogin({
          isAuthenticated: true,
          email: values.Email,
          id: data.Id,
          name: values.FirstName + " " + values.LastName,
          isActivated: data.IsActivated,
        })
      );
    }
    if (data.IsActivated !== true)
      props.history.push({
        pathname: "/emailverification",
        state: {
          business: null,
          isDeployed: false,
          hasSolution: false,
          url: null,
        },
      });
    else
      props.history.push({
        pathname: "/gettingstarted"
      });
  };

  return (
    <div className="signupPage">
      <div className="signupParentContainer">
        <div>
          <span>
            <img
              alt=""
              className="logo"
              src={process.env.PUBLIC_URL + "/images/logo.png"}
            />
          </span>
          <div className="col-11">
            <h1 className="signupHeader">Enter personal details.</h1>

            <p className="signupParagraph">
              Please enter required personal details below to start an account
              on fintold.{" "}
            </p>
            <a href="/login"></a>
          </div>

          <div className="col-12">
            <Formik
              initialValues={{
                FirstName:
                  props.user.name.length > 0
                    ? props.user.name.split(" ")[0]
                    : "",
                LastName:
                  props.user.name.length > 0
                    ? props.user.name.split(" ")[1]
                    : "",
                Email: props.user.email.length > 0 ? props.user.email : "",
                Password: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={SignupSchema}
            >
              {(formik) => (
                <Form style={{ marginTop: "38px" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <TextInput
                        label={t("YourName")}
                        name="FirstName"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInput name="LastName" showicon="true" />
                    </div>
                  </div>
                  <div>
                    <TextInput
                      placeholder="emailaddress@domain.com"
                      label={t("EmailAddress")}
                      name="Email"
                      autoComplete="off"
                      showicon="true"
                    />
                  </div>
                  <div>
                    <PasswordInput
                      test={true}
                      placeholder="enter your password"
                      label={t("Password")}
                      name="Password"
                      showicon="true"
                    />
                  </div>
                  <div>
                    <MainButton
                      btnType={
                        formik.isSubmitting || isLoading ? "loading" : "main"
                      }
                      text={
                        formik.isSubmitting || isLoading
                          ? t("PleaseWait")
                          : t("SignupNow")
                      }
                      type="submit"
                      onClick={null}
                      disabled={!(formik.isValid && formik.dirty)}
                    />
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf768gUAAAAANNZyskUoAL9twYZ5k8REyMKNoh4"
                      onChange={async (token) => {
                        if (token) {
                          setIsLoading(true);
                          await signup(token, formik.values);
                        }
                      }}
                      size={"invisible"}
                      badge={"bottomleft"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="AlreadyHaveFooter">
              <span>Already have an account? </span>
              <a href="/login">Log in Now</a>
            </div>
          </div>
          <div>
            <footer className="privacyPolicySig">
              {t("ByClickingSignup")}&nbsp;
              <a href="/TermsOfService">{t("TermsOfUse")}</a>&nbsp;{t("and")}
              &nbsp;
              <a href="/privacypolicy">{t("PrivacyPolicy")}</a>
            </footer>
          </div>
        </div>
        <div className="col-5 containersignupimgright">
          <div>
            <img
              alt=""
              className="signupSideImageIdentity"
              src={process.env.PUBLIC_URL + "/images/signupimg.png"}
            />
            <div className="col-4 writingOnSignupImage">
              <h3>Reshaping future.</h3>
              <p className="writingOnSignupImageParag">
                Registration process is simple, no documents required and you
                can start offering solutions in minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SignUp);
