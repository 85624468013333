import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import WhiteSecondaryButton from "../common/buttons/WhiteSecondaryButton";
import { useLocation, useHistory } from "react-router-dom";
import { Dropdown } from "bootstrap";
import "./NavBar.css";

const NavBar = (props) => {
  const history = useHistory();
  const [dropDown, setDropDown] = useState(null);
  const [dropDownClassList, setDropDownClassList] = useState({
    classes: null,
    dropDownShown: false,
  });
  const dropDownRef = useRef();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const [scroll, setScroll] = useState(0);
  const unBorderedPages = ["/"];

  useEffect(() => {
    setDropDown(new Dropdown(dropDownRef.current));
    window.addEventListener("scroll", listenToScroll);
  }, []);

  useEffect(() => {
    if (dropDownClassList.classes) {
      setDropDownClassList({
        ...dropDownClassList,
        dropDownShown: dropDownRef.current.classList.value.includes("show"),
      });
    }
  }, [dropDownClassList.classes]);

  const updateState = () => {
    if (dropDownRef.current)
      setDropDownClassList({
        ...dropDownClassList,
        classes: dropDownRef.current.classList.value,
      });
  };

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    setScroll(scrolled);
  };

  return (
    <div className="fintoldNav">
      <nav
        className={
          scroll > 0.01 || props.initialFont === "black"
            ? "navbar navbar-light navbar-expand-lg  fixed-top fixedNav"
            : "navbar navbar-dark navbar-expand-lg fixed-top"
        }
      >
        <div
          className={
            unBorderedPages.includes(pathname)
              ? "container-fluid navbarContent"
              : "container-fluid navbarContent withBorder"
          }
        >
          <HashLink className="navbar-brand pcbrand" to="/#homepageSec">
            {(scroll > 0.01 || props.initialFont === "black") && (
              <img src="/images/home/blacklogo.webp" />
            )}
            {scroll <= 0.01 && props.initialFont === "white" && (
              <img src="/images/home/whitelogo.webp" />
            )}
          </HashLink>
          <HashLink className="navbar-brand mobileBrand" to="/#homepageSec">
            <img src="/images/home/blacklogo.webp" />
          </HashLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#fintoldnavcontent"
            aria-controls="fintoldnavcontent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="fintoldnavcontent">
            <ul className="navbar-nav  mx-auto mb-2 mb-lg-0">
              <li
                className={
                  pathname.startsWith("/supplychainfinance") ||
                  pathname.startsWith("/factoring")
                    ? "nav-item withBorder"
                    : "nav-item"
                }
              >
                <button
                  className={
                    pathname.includes("supplychainfinance") ||
                    pathname.includes("factoring")
                      ? "nav-link withBorder"
                      : "nav-link"
                  }
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ref={dropDownRef}
                  onClick={updateState}
                >
                  {dropDownClassList.dropDownShown && (
                    <span className="caret up"></span>
                  )}
                  <span>Solutions</span>
                </button>
                <ul className="dropdown-menu solutionsDropDown">
                  <li>
                    <NavHashLink
                      to="/supplychainfinance/#spfSec"
                      onClick={updateState}
                    >
                      <div>
                        <span>{t("supplyChainFinance")}</span>
                        <span>{t("automatedSCFSolution")}</span>
                        <span>
                          {t("moreDetails")}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.25vw"
                            height="1.25vw"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="arrow-right"
                              transform="translate(-620 -188)"
                            >
                              <path
                                id="Vector"
                                d="M0,0,6.07,6.07,0,12.14"
                                transform="translate(634.43 193.93)"
                                fill="none"
                                stroke="#2323ec"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,0H16.83"
                                transform="translate(623.5 200)"
                                fill="none"
                                stroke="#2323ec"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(644 212) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink to="/factoring/#factSec" onClick={updateState}>
                      <div>
                        <span>{t("factoring")}</span>
                        <span>{t("betterCashFlow&Access")}</span>
                        <span>
                          {t("moreDetails")}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.25vw"
                            height="1.25vw"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="arrow-right"
                              transform="translate(-620 -188)"
                            >
                              <path
                                id="Vector"
                                d="M0,0,6.07,6.07,0,12.14"
                                transform="translate(634.43 193.93)"
                                fill="none"
                                stroke="#2323ec"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,0H16.83"
                                transform="translate(623.5 200)"
                                fill="none"
                                stroke="#2323ec"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(644 212) rotate(180)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </NavHashLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavHashLink className="nav-link" to="/#fintoldCardSection">
                  fintold Card *
                </NavHashLink>
              </li>
              <li
                className={
                  pathname.startsWith("/aboutus")
                    ? "nav-item withBorder"
                    : "nav-item"
                }
              >
                <NavHashLink
                  activeClassName="withBorder"
                  className="nav-link"
                  to="/aboutus/#aboutusSec"
                >
                  About
                </NavHashLink>
              </li>
              <li
                className={
                  pathname.startsWith("/contactus")
                    ? "nav-item withBorder"
                    : "nav-item"
                }
              >
                <NavHashLink
                  activeClassName="withBorder"
                  className="nav-link"
                  to="/contactus/#contactSec"
                >
                  Contact
                </NavHashLink>
              </li>
            </ul>
            <div className="controlCont">
              <WhiteSecondaryButton
                inverted={
                  scroll > 0.01 || props.initialFont === "black" ? true : false
                }
              >
                <HashLink to="/contactus/#contactSec">Request demo</HashLink>
              </WhiteSecondaryButton>
              <NavLink className="nav-link" to="/login">
                Login now
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
