import React from "react";
import "./FinancialDesign.css";

const FinancialDesign = (props) => {
  return (
    <div className="financialDesign">
      <img src="/images/home/financialdesign.webp" />
      <div>
        <small>Financial design</small>
        <h1>
          Financially designed to <br /> meet both buyers' and <br /> suppliers'
          needs.
        </h1>
      </div>
      <div>
        <div className="feature">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
            >
              <g transform="translate(-428 -444)">
                <g transform="translate(444.625 457.125)">
                  <path
                    d="M0,9.2a2.994,2.994,0,0,0,2.923,3.063h3.29A2.606,2.606,0,0,0,8.75,9.59,2.311,2.311,0,0,0,7.018,7.052L1.75,5.215A2.3,2.3,0,0,1,.018,2.677,2.617,2.617,0,0,1,2.555,0h3.29A2.994,2.994,0,0,1,8.768,3.063"
                    transform="translate(0 1.732)"
                    fill="none"
                    stroke="#2323ec"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    d="M0,0V15.75"
                    transform="translate(4.375)"
                    fill="none"
                    stroke="#2323ec"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </g>
                <path
                  d="M35,17.5A17.5,17.5,0,1,1,17.5,0"
                  transform="translate(431.5 447.5)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  d="M7,7V0H0"
                  transform="translate(459.5 447.5)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  d="M0,8.75,8.75,0"
                  transform="translate(457.75 447.5)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  d="M0,0H42V42H0Z"
                  transform="translate(428 444)"
                  fill="none"
                  opacity="0"
                />
              </g>
            </svg>
          </div>
          <div>
            <h6>Financial leverage for buyers.</h6>
            <p>
              Empower your buyers with leverage over their suppliers, allowing
              <br />
              them to offer upfront payments for their suppliers on purchases,{" "}
              <br />
              extend invoices and allow suppliers to cash out invoices.
            </p>
          </div>
        </div>
        <div className="feature">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
            >
              <g id="money-recive" transform="translate(-364 -444)">
                <g id="Group" transform="translate(380.625 457.125)">
                  <path
                    id="Vector"
                    d="M0,9.2a2.994,2.994,0,0,0,2.923,3.063h3.29A2.606,2.606,0,0,0,8.75,9.59,2.311,2.311,0,0,0,7.018,7.052L1.75,5.215A2.3,2.3,0,0,1,.018,2.677,2.617,2.617,0,0,1,2.555,0h3.29A2.994,2.994,0,0,1,8.768,3.063"
                    transform="translate(0 1.732)"
                    fill="none"
                    stroke="#2323ec"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M0,0V15.75"
                    transform="translate(4.375)"
                    fill="none"
                    stroke="#2323ec"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                </g>
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M35,17.5A17.5,17.5,0,1,1,17.5,0"
                  transform="translate(367.5 447.5)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-4"
                  data-name="Vector"
                  d="M0,0V7H7"
                  transform="translate(393.75 449.25)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-5"
                  data-name="Vector"
                  d="M8.75,0,0,8.75"
                  transform="translate(393.75 447.5)"
                  fill="none"
                  stroke="#2323ec"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-6"
                  data-name="Vector"
                  d="M0,0H42V42H0Z"
                  transform="translate(364 444)"
                  fill="none"
                  opacity="0"
                />
              </g>
            </svg>
          </div>
          <div>
            <h6>New funding source for suppliers.</h6>
            <p>
              Our solution allows suppliers to cash out any invoice uploaded by
              <br />
              their buyers on the system. Cash outs requests are made online and{" "}
              <br />
              upon approval money is transacted to supplier's bank account.
            </p>
            <div className="moreDetails">
              {/* More details on financial process. */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialDesign;
