import React from "react";
import "./FundingOption.css";

const FundingOption = (props) => {
  return (
    <div className="fundingOption">
      <div style={{ background: props.background }} className="cont">
        <small style={{ color: props.smallTitleColor }}>Fund through.</small>
        <h1 style={{ color: props.titleColor }}>{props.title}</h1>
        <p style={{ color: props.descriptionColor }}>{props.description}</p>
      </div>
      <div className="img">
        <img src={props.img} />
      </div>
    </div>
  );
};

export default FundingOption;
