import React, { useState, useEffect } from "react";
import "./Retelling.css";
import { useTranslation } from "react-i18next";

const Retelling = () => {
  const { t } = useTranslation();
  return (
    <div className="RetellingContainer">
      <div>
        <div className="retellingDescription">{t("Re-tellingTheStory")}</div>
        <div className="ourSaas">{t("OurSaaSPlatformOffersAll")}</div>
      </div>
    </div>
  );
};
export default Retelling;
