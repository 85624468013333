import React from "react";
import { HashLink } from "react-router-hash-link";
import "./ReadyToSignup.css";

const ReadyToSignup = (props) => {
  return (
    <div className="homereadytosignup">
      <div className="images">
        <div className="s1">
          <img src="/images/home/ready1.webp" />
        </div>
        <div className="s2">
          <img src="/images/home/ready2.webp" />
          <img src="/images/home/ready3.webp" />
        </div>
      </div>
      <div className="sectionText">
        <h1>
          Everything <br /> you need to <br /> run and grow <br /> your
          financial <br /> business.
        </h1>
        <HashLink to="/contactus/#contactSec">
          <button>
            If you are ready, click here to request a demo.
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="arrow-left"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Vector"
                d="M6.07,0,0,6.07l6.07,6.07"
                transform="translate(3.5 5.93)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
              />
              <path
                id="Vector-2"
                data-name="Vector"
                d="M16.83,0H0"
                transform="translate(3.67 12)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
              />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M0,0H24V24H0Z"
                transform="translate(24 24) rotate(180)"
                fill="none"
                opacity="0"
              />
            </svg>
          </button>
        </HashLink>
      </div>
    </div>
  );
};

export default ReadyToSignup;
