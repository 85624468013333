import React from "react";
import { HashLink } from "react-router-hash-link";
import "./BankWorkflow.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
const BankWorkflow = (props) => {
  const { t } = useTranslation();
  return (
    <div className="BankWorkflowSec">
      <div className="TextFlexSubTitle">
        <h2>
          {t("EasyBankWork")}
          <br />
          {t("processEfficiency")}
        </h2>
        <p>
          {t("BuiltBanks")}
          <br /> {t("easyCompatible")}
          <br /> {t("InternalBankingSystem")}
          <br /> {t("BankInteg")}
        </p>
      </div>
      <div className="TexFlexColumn">
        <div className="TextFlex">
          <div className="flexRowWorkFlow">
            <div className="dotNum">1</div>
            <div className="TextBankWorkflow">
              {t("ReceiveBuyer")}
              <br />
              {t("BankPartnerDash")}
            </div>
          </div>
          <div className="flexRowWorkFlow">
            <div className="dotNum">3</div>
            <div className="TextBankWorkflow">
              {t("BuyersOnboard")}
              <br />
              {t("ApprovedBanks")}
              {""}
            </div>
          </div>
        </div>
        <div className="TextFlex">
          <div className="flexRowWorkFlow">
            <div className="dotNum">2</div>
            <div className="TextBankWorkflow">
              {t("CompleteProcess")}
              <br />
              {t("bankingSys")}
            </div>
          </div>
          <div className="flexRowWorkFlow">
            {"  "} <div className="dotNum">4</div>
            <div className="TextBankWorkflow">
              {t("ReceiveReview")}
              <br />
              {t("OnSys")}
            </div>
          </div>
        </div>
      </div>
      <div className="TexFlexColumn-MobileView">
        <div className="TextFlex">
          <div className="flexRowWorkFlow">
            <div className="dotNum">1</div>
            <div className="TextBankWorkflow">
              {t("ReceiveBuyer")}
              <br />
              {t("BankPartnerDash")}
            </div>
          </div>
          <div className="flexRowWorkFlow">
            <div className="dotNum">2</div>
            <div className="TextBankWorkflow">
              {t("CompleteProcess")}
              <br />
              {t("bankingSys")}
            </div>
          </div>
        </div>
        <div className="TextFlex">
          <div className="flexRowWorkFlow">
            <div className="dotNum">3</div>
            <div className="TextBankWorkflow">
              {t("BuyersOnboard")}
              <br />
              {t("ApprovedBanks")}
              {""}
            </div>
          </div>
          <div className="flexRowWorkFlow">
            {"  "} <div className="dotNum">4</div>
            <div className="TextBankWorkflow">
              {t("ReceiveReview")}
              <br />
              {t("OnSys")}
            </div>
          </div>
        </div>
      </div>
      <img className="workflowImg" src="/images/home/BankWorkflow.webp" />
    </div>
  );
};

export default BankWorkflow;
