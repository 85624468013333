import React, { useState, useEffect } from "react";
import "./JobPost.css";
import { useTranslation } from "react-i18next";
import ShareWithFriends from "../Careers/ShareWithFriends/ShareWithFriends";
import parse from "html-react-parser";
import { HashLink } from "react-router-hash-link";
import { AiFillCheckCircle } from "react-icons/fa";
import ApplyForJob from "./ApplyForJob/ApplyForJob";
import OpenPositions from "../Careers/OpenPositions/OpenPositions";
import Navbar from "../homepage/NavBar";
import Footer from "../homepage/Footer";
const axios = require("axios").default;

import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const JobPost = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [sucsess, setSucsess] = React.useState(false);
  const [Career, setCareer] = React.useState({
    id: 12,
    department: "S&G",
    jobTitle: "HR Executive",
    location: "Egypt : cairo",
    responsibilities:
      "&lt;ul&gt;\r\n\t&lt;li&gt;Handling the talent acquisition process from job posting up until onboarding new hires.&lt;/li&gt;\r\n\t&lt;li&gt;Creating, maintaining and updating employee files.&lt;/li&gt;\r\n\t&lt;li&gt;Preparing and processing payrolls, social insurance and tax reporting and records.&lt;/li&gt;\r\n\t&lt;li&gt;Inserting and following up on employees&amp;rsquo; data to ensure information is current and complete on our HR system.&lt;/li&gt;\r\n\t&lt;li&gt;Complying with all applicable national laws and, in particular, those related to employment, insurance, and tax.&lt;/li&gt;\r\n\t&lt;li&gt;Preparing the annual HR budget plans and updating them on a quarterly basis in collaboration with the management.&lt;/li&gt;\r\n\t&lt;li&gt;Collaborating with outside vendors, such as benefits vendors (e.g., medical insurance)&lt;/li&gt;\r\n\t&lt;li&gt;Recording and maintaining the attendance and leaves of the company&amp;rsquo;s employees.&lt;/li&gt;\r\n&lt;/ul&gt;\r\n",
    qualifications:
      "&lt;ul&gt;\r\n\t&lt;li&gt;Bachelor&amp;rsquo;s degree in any field (law and human resources are a plus)&lt;/li&gt;\r\n\t&lt;li&gt;2+ years of relevant experience in Human Resources.&lt;/li&gt;\r\n\t&lt;li&gt;Solid knowledge of labour and social insurance laws&lt;/li&gt;\r\n\t&lt;li&gt;Very good command of the English language&lt;/li&gt;\r\n\t&lt;li&gt;Strong computer skills (especially Microsoft Excel)&lt;/li&gt;\r\n&lt;/ul&gt;\r\n",
  });
  console.log(props.match.params.id);
  const { id } = props.match.params;
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onAppliedSccuess = () => setSucsess(true);

  const { t } = useTranslation();
  var propssTest = {
    id: 9,
    department: "Engineering",
    jobTitle: "Android Developer",
    responsibilities:
      "&lt;ul&gt;\r\n\t&lt;li&gt;Writing clean and efficient codes for Android applications&lt;/li&gt;\r\n\t&lt;li&gt;Monitoring the performance of live apps and work on optimizing them at the code level&lt;/li&gt;\r\n\t&lt;li&gt;Identifying and resolving bottlenecks, rectifying bugs and enhancing application performance&lt;/li&gt;\r\n\t&lt;li&gt;Performing unit and instrumentation tests on code&lt;/li&gt;\r\n\t&lt;li&gt;Collaborating with cross-functional teams to define and design new features&lt;/li&gt;\r\n\t&lt;li&gt;Staying up to date with new mobile technology trends, applications, and protocols&lt;/li&gt;\r\n&lt;/ul&gt;\r\n",
    qualifications:
      "&lt;ul&gt;\r\n\t&lt;li&gt;Degree in Computer Science, Engineering or a related field&lt;/li&gt;\r\n\t&lt;li&gt;You have prior experience as an Android Developer using Kotlin, Java, Android SDK, Android NDK&lt;/li&gt;\r\n\t&lt;li&gt;You have experience in publishing an application on the Google Play store&lt;/li&gt;\r\n\t&lt;li&gt;Proven work experience in design patterns mobile architecture using frameworks such as MVVM/MVC/MVP&lt;/li&gt;\r\n\t&lt;li&gt;Familiarity with RESTful APIs to effectively integrate Android applications&lt;/li&gt;\r\n\t&lt;li&gt;Proficient understanding of code versioning tools such as Git&lt;/li&gt;\r\n\t&lt;li&gt;Familiarity with various testing tools&lt;/li&gt;\r\n\t&lt;li&gt;You are a problem solver with good analytical skills&lt;/li&gt;\r\n&lt;/ul&gt;\r\n",
  };

  // componentDidMount() {
  //   axios.get(`/general/getcareer/${id}`).then((res) => {
  //     const Career = res.data;
  //     this.set({ Career });
  //     console.log(res);
  //   });
  // }

  useEffect(() => {
    console.log("useEffect");
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/general/getcareer?id=${id}`);
        console.log(data);
        setCareer(data);
      } catch (error) {
        console.error(error);
      }

      // const CareerDetails = res.data;
      // setCareer({ CareerDetails });
      // console.log(res);
    };

    fetchData();
  }, [id]);
  return (
    <div id="jobpostSec">
      <Navbar initialFont="black" />
      <div class="careesPostContainer">
        <div className=" container-sm">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <ShareWithFriends />
          </Modal>
          <div className="careerPostLanding p-lg-5">
            <span>
              All jobs <span style={{ margin: "0 0.5vw" }}>&gt;</span>{" "}
              <span className="bolded">{Career.department} </span>
            </span>
            <div className="JobTitle">
              <h2>{Career.jobTitle}</h2>
              <div className="loctation">
                <h6>{Career.location}</h6>
                <h6>Work environment: Remote</h6>
              </div>
            </div>
            <div className="apply">
              <div className="ApplyButton">
                <button>
                  <HashLink to="#applyform">Apply now</HashLink>
                </button>
              </div>
              <div className="apply">
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <li className="Share" onClick={openModal}>
                      <img src="./images/JobPost/vuesax-linear-profile-2user.webp" />
                      <span> Share with friends</span>
                    </li>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="Jobdesc">
              <h3>Responsibilities</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: parse(Career.responsibilities),
                }}
              ></div>
            </div>
            <div className="Jobreq">
              <h3>Qualifications</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: parse(Career.qualifications),
                }}
              ></div>
              {/* <ul>
                <li>
                  <img src="./images/JobPost/tick-circle-1.webp" />
                </li>
                <li>
                  <span>
                    Actively lead, mentor develop a team of high performing
                    direct channel quota-carrying professionals.
                  </span>
                </li>
              </ul> */}
            </div>
            {!sucsess && (
              <>
                {" "}
                <div id="applyform" className="applyForm text-left">
                  <div className="formIcon">
                    <img src="/images/JobPost/vuesax-linear-sms-tracking.webp" />
                    <h2>Apply now!</h2>
                    <span>
                      We have received your application and will contact you
                      soon.
                    </span>
                    <p>Other job positions</p>
                  </div>
                </div>
                <ApplyForJob
                  department={Career.jobTitle}
                  onAppliedSccuess={onAppliedSccuess}
                />
              </>
            )}
            {sucsess && (
              <div className="applyForm text-left">
                <div className="formIcon">
                  <img src="/images/JobPost/vuesax-linear-sms-tracking.webp" />
                  <h2>You applied!</h2>
                  <span>
                    We have received your application and will contact you soon.
                  </span>
                  <p>Other job positions</p>
                </div>
              </div>
            )}
          </div>

          <OpenPositions />
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default JobPost;
