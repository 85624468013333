import React, { useState, useEffect } from "react";
import "./Established.css";
import { useTranslation } from "react-i18next";

const Established = () => {
  const { t } = useTranslation();
  return (
    <div id="establishedSec" className="establishedContainer">
      <img
        className="img1"
        src="/images/Aboutus/cdd20-NdLmjxbbjfo-unsplash.webp"
      />
      <img
        className="img2"
        src="/images/Aboutus/cdd20-GpPkBWuN_qc-unsplash.webp"
      />
      <img className="img3" src="/images/Aboutus/ab3.webp" />
      <div className="Established">
        <div className="establishedIn">{t("EstablishedIn")}</div>
        <div className="Year2021">{t("2021")}</div>
        <div className="establishedDescription">
          {t("DesignedwholeNewMarketNetwork")}
        </div>
      </div>
    </div>
  );
};
export default Established;
