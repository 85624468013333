export const USER_LOGIN = "USER_LOGIN";

export const UserState = {
  isAuthenticated: false,
  email: "",
  id: "",
  name: "",
  isActivated: null,
  hasBusiness: null,
  businesses: [],
  BusinessProfileData: null,
  url: null,
};

export function userLogin(user) {
  return { type: USER_LOGIN, user };
}

export function reducer(state = UserState, action) {
  switch (action.type) {
    case USER_LOGIN:
      return action.user;
    default:
      return state;
  }
}
