import React from "react";
import "./ExpandableCard.css";

const ExpandableCard = (props) => {
  return (
    <div
      style={{
        background: props.background,
      }}
      className={`
        spfExpandableCard ${props.offset === true ? "upperOffset " : ""} ${
        props.first === true ? "first" : ""
      }
      `}
    >
      <div className="expBtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="maximize-2"
          width="16.584"
          height="16.584"
          viewBox="0 0 16.584 16.584"
        >
          <path
            id="Vector"
            d="M0,4.837V3.109A3.105,3.105,0,0,1,3.109,0H4.837"
            transform="translate(1.382 1.382)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H1.727A3.105,3.105,0,0,1,4.837,3.109V4.837"
            transform="translate(10.365 1.382)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M4.146,0V1.036A3.105,3.105,0,0,1,1.036,4.146H0"
            transform="translate(11.056 11.056)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M4.837,4.837H3.109A3.105,3.105,0,0,1,0,1.727V0"
            transform="translate(1.382 10.365)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H16.584V16.584H0Z"
            fill="none"
            opacity="0"
          />
        </svg>
      </div>
      <div className="cardIcon">{props.cardIcon}</div>
      <h1>{props.title}</h1>
      <p>{props.description}</p>
      <ul>
        {props.perks.map((p) => (
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <g id="tick-circle" transform="translate(-748 -188)">
                <path
                  id="Vector"
                  d="M8.333,16.667A8.333,8.333,0,1,0,0,8.333,8.358,8.358,0,0,0,8.333,16.667Z"
                  transform="translate(749.667 189.667)"
                  fill="#292d32"
                  stroke="#292d32"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M0,2.358,2.358,4.717,7.083,0"
                  transform="translate(754.458 195.642)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M0,0H20V20H0Z"
                  transform="translate(748 188)"
                  fill="none"
                  opacity="0"
                />
              </g>
            </svg>
            {p}
          </li>
        ))}
      </ul>
      <img src={props.img} />
    </div>
  );
};

export default ExpandableCard;
