import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "@fintold/packages";
import { PasswordInput } from "@fintold/packages";
import { MainButton } from "@fintold/packages";
import { userLogin } from "../../store/user";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./ForgotPassword";
import { MainModal } from "@fintold/packages";
import ActivateSolutionCenter from "../AddCompany/ActivateSolutionCenter";
import AddCompanyDetails from "../AddCompany/AddCompanyDetails";
import { Link } from "react-router-dom";
import "./Login.css";
import ReCAPTCHA from "react-google-recaptcha";
import { customFetch } from "../../CustomFetch";

const Login = (props) => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState();
  const [user, setUser] = useState();
  const [userName, setUserName] = useState();
  const [addCompanyDetailsModal, setAddCompanyDetailsModal] = useState(null);
  const [ActivateSolutionModal, setActivateSolutionModal] = useState(null);
  const [modal, setModal] = useState(null);
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const showForgotPassModal = () => {
    modal.show();
  };
  const showActivateSolModal = () => {
    ActivateSolutionModal.show();
  };
  const showaddCompModal = () => {
    addCompanyDetailsModal.show();
  };
  const schema = Yup.object().shape({
    email: Yup.string().required("Username is a required field"),
    password: Yup.string().required("Password is a required field"),
  });

  const handleSubmit = async (values) => {
    await recaptchaRef.current.execute();
  };
  const login = async (token, values) => {
    try {
      const res = await fetch("/account/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values["email"],
          password: values["password"],
          token,
        }),
      });
      const data = await res.json();
      if (data.login == false) {
        setIsLoading(false);
        if (data.blocked == true)
          toast(
            <ToastBody
              icon="error"
              text="Account blocked for too many failed login attempts"
            />,
            {
              className: "errorToast",
            }
          );
        else
          toast(
            <ToastBody icon="error" text="Username or password is incorrect" />,
            {
              className: "errorToast",
            }
          );
        return;
      }
      await fetch("/account/renew-csrf-token");
      setIsLoading(false);
      setUser(data);
      setUserId(data.Id);
      setUserName(data.Name);
      if (data.passReset === true) props.history.push("/setpassword");
      else if (data._url) window.location.href = data._url;
      else checkHasBusiness(data.Id, data.Name, data.active, values.email);
    } catch (e) {
      setIsLoading(false);
      toast(
        <ToastBody
          icon="error"
          text="An error has occurred, please try again later"
        />,
        {
          className: "errorToast",
        }
      );
    }
  };

  const checkHasBusiness = async (Userid, Username, active, email) => {
    const res = await customFetch(`/account/gettingstarted`);

    const data = await res.json();

    props.dispatch(
      userLogin({
        isAuthenticated: true,
        email: email,
        id: Userid,
        name: Username,
        isActivated: active,
        url: data.url,
      })
    );
    if (data.stage == 0) {
      window.location.href = data.url;
    } else {
      props.history.push({
        pathname: "/gettingstarted",
      });
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <div className="SignUpContNbfi">
              <div className="signupCompanyLogoNbfi">
                <div className="stylingFinToldLogin">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <img src="/images/logo-black.webp" />
                    </div>
                    <div className="companyHelpNumNbfi">
                      {t("NeedHelpCall")}{" "}
                      <a className="latinText" href="tel:01203057774">
                        {" " + t("CustomerServiceNumber")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="signupFormContNbfi">
                <h1 className="headNbfi">
                  {t("Signintofintold", { name: "fintold" })}
                </h1>
                <p className="subheadNbfi">
                  Enter your email and password to login to your account.
                </p>
              </div>
              <div className="signupFormContNbfi">
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <TextInput
                            placeholder={t("enterEmail")}
                            label={"Email address"}
                            name={"email"}
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-12">
                          <PasswordInput
                            test={false}
                            name={"password"}
                            label={t("password")}
                            placeholder={t("enterPassword")}
                            actionLink={
                              <a
                                onClick={showForgotPassModal}
                                className="action styleWebsaasForgotPassword"
                              >
                                Forgot your password?
                              </a>
                            }
                          />
                        </div>
                        <div className="col-12">
                          <MainButton
                            btnType={
                              formik.isSubmitting || isLoading
                                ? "loading"
                                : "main"
                            }
                            text={
                              formik.isSubmitting || isLoading
                                ? t("loggingIn")
                                : t("enterEmailPassLogin")
                            }
                            type="submit"
                            onClick={null}
                            disabled={!(formik.isValid && formik.dirty)}
                          />
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Lf768gUAAAAANNZyskUoAL9twYZ5k8REyMKNoh4"
                            onChange={async (token) => {
                              if (token) {
                                setIsLoading(true);
                                await login(token, formik.values);
                              }
                            }}
                            size={"invisible"}
                            badge={"bottomleft"}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="helperNbfi">
                  <span>New to findtold?</span>{" "}
                  <a href="/signup">Sign up now</a>
                </div>
                <div className="FooterLoginNewStyle">
                  <footer className="privacyPolicylogg">
                    {t("ByClickingSignup")}&nbsp;
                    <a href="/TermsOfService">{t("TermsOfUse")}</a>&nbsp;
                    {t("and")}
                    &nbsp;
                    <a href="/privacypolicy">{t("PrivacyPolicy")}</a>
                  </footer>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-none d-md-block">
            <div>
              <img
                alt=""
                className="signupSideImageNbfi"
                src={process.env.PUBLIC_URL + "/images/signupimg.png"}
              />
              <div className="col-3 writingOnLoginImage">
                <h3>Reshaping future.</h3>
                <p className="writingOnSignupImageParag">
                  Registration process is simple, no documents required and you
                  can start offering solutions in minutes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActivateSolutionCenter
        modal={ActivateSolutionModal}
        setModal={setActivateSolutionModal}
      />
      <ForgotPassword modal={modal} setModal={setModal} />
      <AddCompanyDetails
        modal={addCompanyDetailsModal}
        setModal={setAddCompanyDetailsModal}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Login);
