import React, { useState, useEffect } from "react";
import "./Maximize.css";
import { useTranslation } from "react-i18next";

const Maximize = () => {
  const { t } = useTranslation();
  return (
    <div className="maximizeContainer">
      {/* <div
        className="minimizeIcon"
        style={{
          backgroundImage: `url(/images/Aboutus/maximize-2.webp)`,
        }}
      ></div> */}
      <img className="minimizeIcon" src="/images/Aboutus/maximize-2.webp" />
    </div>
  );
};
export default Maximize;
