import React from "react";
import "./MaximumSecurity.css";

const MaximumSecurity = (props) => {
  return (
    <div className="homeMaxSec">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
      >
        <g
          id="vuesax_linear_security-safe"
          data-name="vuesax/linear/security-safe"
          transform="translate(-364 -316)"
        >
          <g id="security-safe" transform="translate(364 316)">
            <path
              id="Vector"
              d="M59.4,30.375c0,16.3-11.833,31.567-28,36.033a6.535,6.535,0,0,1-3.4,0C11.833,61.942,0,46.675,0,30.375V15.742A8.007,8.007,0,0,1,4.633,8.875L23.2,1.275a17.291,17.291,0,0,1,13.033,0L54.8,8.875a8.069,8.069,0,0,1,4.633,6.867Z"
              transform="translate(10.3 6.692)"
              fill="none"
              stroke="#00d81d"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="6"
            />
            <g id="Group" transform="translate(33.333 28.333)">
              <path
                id="Vector-2"
                data-name="Vector"
                d="M13.333,6.667A6.667,6.667,0,1,1,6.667,0,6.667,6.667,0,0,1,13.333,6.667Z"
                fill="none"
                stroke="#00d81d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="6"
              />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M0,0V10"
                transform="translate(6.667 13.333)"
                fill="none"
                stroke="#00d81d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="6"
              />
            </g>
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H80V80H0Z"
              transform="translate(80 80) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
      <h1>Maximum Security</h1>
      <p>
        Our system is built on an industry leading cloud <br /> system managed
        by world's top engineers <br /> and cutting edge technology.
      </p>
    </div>
  );
};

export default MaximumSecurity;
