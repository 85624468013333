import React, { useState, useEffect } from "react";
import "./JobCard.css";
import { useTranslation } from "react-i18next";
import Maximize from "../../Aboutus/Maximize/Maximize";
import { Modal } from "react-bootstrap";
import JobPosition from "../JobPositions";

const JobCard = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div
      style={{ cursor: "pointer" }}
      className="JobCardContainer"
      onClick={handleShow}
    >
      <div
        className="jobsCard"
        style={{
          backgroundImage: `url(${props.JobCardPic})`,
        }}
      >
        <div className="jobPostion">
          <h5 className="positionTitle">{props.PositionTitle}</h5>
          <span
            className="positionDescription"
            style={{ color: props.SloganColor }}
          >
            {props.PositionDescription}
          </span>
        </div>
        <div className="more">
          <div className="more-maximize">
            <Maximize />
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobCard;
