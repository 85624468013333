import React, { useState, useEffect } from "react";
import "./TermComponent.css";
import { useTranslation } from "react-i18next";

const TermComponent = (props) => {
  const { t } = useTranslation();
  return (
    <div className="termtextContainer">
      {/* <TermTitle TermTitleText="LA LA LA" TermNumber="22" /> */}
      <div className="TermtitleContainer">
        <div className="TermNumber">{props.TermNumber}</div>
        <div className="TermNumber">{props.TermTitle}</div>
      </div>
      <div className="TermContent">{props.TermContent}</div>
    </div>
  );
};
export default TermComponent;
