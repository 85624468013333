import React, { useRef, useEffect, useState } from "react";
import { Collapse } from "bootstrap";
import "./FAQQuestion.css";

const FAQQuestion = (props) => {
  const collapseRef = useRef();
  const [collapse, setCollapse] = useState(null);

  useEffect(() => {
    setCollapse(new Collapse(collapseRef.current));
  }, []);

  return (
    <div className="faqQuestion">
      <div className="index">{props.index}</div>
      <div className="item">
        <div className="itemHead" onClick={() => collapse.toggle()}>
          {props.item.q}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <g id="arrow-down" opacity="0.4">
              <path
                id="Vector"
                d="M11.88,0,6.99,4.89a1.489,1.489,0,0,1-2.1,0L0,0"
                transform="translate(3.06 6.713)"
                fill="none"
                stroke="#292d32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Vector-2"
                data-name="Vector"
                d="M0,0H18V18H0Z"
                transform="translate(18 18) rotate(180)"
                fill="none"
                opacity="0"
              />
            </g>
          </svg>
        </div>
        <div className="itemBody collapse" ref={collapseRef}>
          {props.item.a}
        </div>
      </div>
    </div>
  );
};

export default FAQQuestion;
