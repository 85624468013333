import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UD } from "@fintold/packages";
import i18n from "i18next";
import { MainModal } from "@fintold/packages";
import { Modal } from "bootstrap";
import "./UploadCompanyLogo.css";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { useLocation } from "react-router-dom";
import { MainButton } from "@fintold/packages";
import BannerScroller from "../BannerScroller/BannerScroller";
import { VerificationSteps } from "@fintold/packages";
import UploadCompanyBanner from "../UploadCompanyBanner/UploadCompanyBanner";
import ChooseBanner from "../ChooseBanner/ChooseBanner";
const UploadCompanyLogo = (props) => {
  const { t } = useTranslation();

  const [uplMainButton, setUplMainButton] = useState(true);
  const [startUpload, setStartUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileToUpload, setFileToUpload] = useState({});
  const [view, setView] = useState(1);
  const { state } = useLocation();
  const [pressed, setPressed] = useState(false);
  const [sliderLeft, setSliderLeft] = useState("0px");
  const [startx, setStartx] = useState(0);
  const [cardsx, setCardsx] = useState();

  const handleEvent = (event) => {
    if (event.type === "mousedown") {
      setPressed(true);
      setCardsx(event.target.getBoundingClientRect().left);
      setStartx(
        event.nativeEvent.offsetX - event.target.getBoundingClientRect().left
      );

      // console.log(event.nativeEvent.offsetX);
    }
  };
  const handleMouseUp = (event) => {
    setPressed(false);
    console.log("mouse up");
  };
  const handleslider = (event) => {
    if (pressed) {
      event.preventDefault();

      var START = startx;

      var slidee = `${event.nativeEvent.offsetX - startx}px`;

      setSliderLeft(slidee);
      if (cardsx > 0) {
        setSliderLeft("0px");
      }
    }
  };

  const handleUploadBanner = () => {
    setView(3);
  };
  const handleChooseBanner = () => {
    setView(4);
  };

  useEffect(() => {
    if (props.businessState && props.businessState.avatar != null) setView(2);
  }, [props.businessState]);

  return (
    <>
      <div>
        <MainModal
          modal={props.modal}
          setModal={props.setModal}
          id={props.modalId + "modal"}
          closeText={t("CloseAndContinue")}
          hideHelper={false}
        >
          {view === 1 && (
            <>
              <div
                className="modal-header"
                style={{ width: "43.17vw", marginLeft: "20%" }}
              >
                <div className="Content">
                  {" "}
                  <div style={{ font: "normal normal bold 35px/41px Raleway" }}>
                    {t("CustomizeYourClient")}
                  </div>
                  <div className="VerificationStepsUploadBanner">
                    <VerificationSteps totalSteps={3} currentStep={2} />
                  </div>
                  <div className="VerificationStepsUploadLogo"></div>
                  <div className="UploadCompanyLogo">
                    {t("UploadCompanyLogo")}
                  </div>
                  <div className="BrandYourDashboard">
                    {t("BrandYourDashboard")}
                  </div>
                  <div>
                    <UD
                      label={t("UploadYourLogo")}
                      data={{}}
                      url="/General/uploadcompanylogo"
                      maxFileSize={3145728}
                      downloadable={false}
                      downloadDoc={null}
                      uploaded={false}
                      uploadedFile={""}
                      removeDocument={() => {}}
                      acceptedTypes={["image/png", "image/jpeg"]}
                      onSuccess={() => {
                        setView(2);
                        props.setBusinessState({
                          ...props.businessState,
                          businessData: {
                            ...props.businessState.businessData,
                            avatar: "",
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="Container">
                  <div></div>
                  <div className="Content"></div>
                  <div style={{}}></div>
                </div>
              </div>
            </>
          )}
          {view === 2 && (
            <>
              <div
                className="modal-header"
                style={{ width: "43.17vw", marginLeft: "20%" }}
              >
                <div className="Content">
                  {" "}
                  <div style={{ font: "normal normal bold 35px/41px Raleway" }}>
                    {t("CustomizeYourClient")}
                  </div>
                  <div className="VerificationStepsUploadLogo">
                    <VerificationSteps totalSteps={3} currentStep={2} />
                  </div>
                  <div className="VerificationStepsUploadLogo"></div>
                  <div className="CustomerLogin">
                    {t("CustomerLoginBanner")}
                  </div>
                  <div className="BrandYourDashboard">
                    {t("ThisBannerWillBeUsed")}
                  </div>
                  <div>
                    <button
                      className="BannerButton"
                      onClick={handleChooseBanner}
                    >
                      <span> {t("ChooseBanner")}</span>
                    </button>{" "}
                    <button
                      className="BannerButton"
                      onClick={handleUploadBanner}
                    >
                      <span> {t("UploadBanner")}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="Container">
                  <div></div>
                  <div className="Content"></div>
                  <div style={{}}></div>
                </div>
              </div>
            </>
          )}
          {view === 3 && <UploadCompanyBanner />}
          {view === 4 && (
            <>
              <ChooseBanner view={view} />
            </>
          )}
        </MainModal>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(UploadCompanyLogo);
