import React from "react";
import "./FAQContainer.css";
import FAQQuestion from "./FAQQuestion";

const FAQContainer = (props) => {
  return (
    <div className="faqcontainer">
      <h6 className="contHeader">FAQ</h6>
      <div className="questionsCont">
        {props.data.map((item, index) => (
          <FAQQuestion item={item} index={index + 1} />
        ))}
      </div>
    </div>
  );
};

export default FAQContainer;
