import React, { useState, useEffect } from "react";
import "./Solutions.css";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const Solutions = () => {
  const { t } = useTranslation();
  return (
    <div className="solutionContainer">
      <p className="solutions">{t("SolutionsCatered")}</p>
      <div>
        <p className="empower">{t("WeEmpowerYourBusiness")}</p>
        <HashLink
          to="/supplychainfinance/#spfSec"
          className="moreDetailsContainer"
        >
          <p className="moreDetails">{t("MoreDetails")}</p>
          <img src="/images/Aboutus/arrow-right.webp" />
        </HashLink>
      </div>
    </div>
  );
};
export default Solutions;
