import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import WhiteSecondaryButton from "../common/buttons/WhiteSecondaryButton";
import { useLocation, useHistory } from "react-router-dom";
import "./BlackNavBar.css";

const BlackNavBar = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const [scroll, setScroll] = useState(0);
  const unBorderedPages = ["/"];
  return (
    <div className="fintoldBlackNav">
      <nav
        className={
          scroll > 0.01 || props.initialFont === "white"
            ? "navbar navbar-light navbar-expand-lg  fixed-top fixedNav"
            : "navbar navbar-dark navbar-expand-lg fixed-top"
        }
      >
        <div
          className={
            unBorderedPages.includes(pathname)
              ? "container-fluid navbarContent"
              : "container-fluid navbarContent withBorder"
          }
        >
          <HashLink className="navbar-brand pcbrand" to="/#homepageSec">
            {props.initialFont === "white" && (
              <img src="/images/home/whitelogo.webp" />
            )}
          </HashLink>
          <HashLink className="navbar-brand mobileBrand" to="/#homepageSec">
            <img src="/images/home/blacklogo.webp" />
          </HashLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#fintoldblacknavcontent"
            aria-controls="fintoldblacknavcontent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="fintoldblacknavcontent">
            <ul className="navbar-nav  mx-auto mb-2 mb-lg-0">
              <li
                className={
                  pathname.startsWith("/supplychainfinance")
                    ? "nav-item withBorder"
                    : "nav-item"
                }
              >
                <NavHashLink
                  activeClassName="withBorder"
                  className="nav-link"
                  to="/supplychainfinance/#spfSec"
                >
                  {t("Solutions")}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <g
                      id="arrow-down"
                      transform="translate(-236 -252)"
                      opacity="0.6"
                    >
                      <path
                        id="Vector"
                        d="M11.88,0,6.99,4.89a1.489,1.489,0,0,1-2.1,0L0,0"
                        transform="translate(239.06 258.713)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M0,0H18V18H0Z"
                        transform="translate(254 270) rotate(180)"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </svg>
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink className="nav-link" to="/#fintoldCardSection">
                  {t("FintoldCard")}
                </NavHashLink>
              </li>
              <li
                className={
                  pathname.startsWith("/aboutus")
                    ? "nav-item withBorder"
                    : "nav-item"
                }
              >
                <NavHashLink
                  activeClassName="withBorder"
                  className="nav-link"
                  to="/aboutus/#aboutusSec"
                >
                  {t("AboutNavBar")}
                </NavHashLink>
              </li>
              <li
                className={
                  pathname.startsWith("/contactus")
                    ? "nav-item withBorder"
                    : "nav-item"
                }
              >
                <NavHashLink
                  activeClassName="withBorder"
                  className="nav-link"
                  to="/contactus/#contactSec"
                >
                  {t("Contact")}
                </NavHashLink>
              </li>
            </ul>
            <div className="controlCont">
              <WhiteSecondaryButton inverted={false}>
                <HashLink to="/contactus/#contactSec">{t("RequestDemo")}</HashLink>
              </WhiteSecondaryButton>

              <NavLink className="nav-link" to="/login">
                {t("LoginNowHp")}
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default BlackNavBar;
