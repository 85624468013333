import React, { useState, useEffect } from "react";
import "./Panner.css";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const Panner = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pannerContainer">
        <img src="/images/Aboutus/cdd20-0fT07sVlFCc-unsplash.webp" />
        <div className="overlay"></div>
        <div className="panner">
          <p class="aboutus">{t("Aboutus")}</p>
          <p class="reshaping">{t("reshapingFutureOfFinancial")}</p>
          <HashLink className="arrowdown" to="/aboutus/#establishedSec">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.453"
              height="49.453"
              viewBox="0 0 49.453 49.453"
            >
              <g id="arrow-down" transform="translate(-236 -252)" opacity="0.6">
                <path
                  id="Vector"
                  d="M32.639,0,19.2,13.435a4.092,4.092,0,0,1-5.77,0L0,0"
                  transform="translate(244.407 270.442)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M0,0H49.453V49.453H0Z"
                  transform="translate(285.453 301.453) rotate(180)"
                  fill="none"
                  opacity="0"
                />
              </g>
            </svg>
          </HashLink>
        </div>
      </div>
    </>
  );
};
export default Panner;
