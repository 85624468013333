import React, { useState, useEffect } from "react";
import "./Hosted.css";
import { useTranslation } from "react-i18next";

const Hosted = () => {
  const { t } = useTranslation();
  return (
    <div className="HostedContainer">
      <div className="azure">
        <div className="AzureLogo">
          <img src="/images/Aboutus/Azure.webp" />
        </div>
        <p className="HostedOnMicrosoftAzure">{t("HostedOnMicrosoftAzure")}</p>
      </div>
      <p className="ThePlatformIsHosting">{t("ThePlatformIsHosting")}</p>
    </div>
  );
};
export default Hosted;
