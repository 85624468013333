import React, { useState, useEffect } from "react";
import "./AmongHigh.css";
import { useTranslation } from "react-i18next";

const AmongHigh = () => {
  const { t } = useTranslation();
  return (
    <div className="amongHighContainer">
      <img src="/images/Aboutus/david-lezcano-Tv4OBvBOkvc-unsplash.webp" />
      <div className="amonghightext">
        <div className="startLocally">
          {t("StartingLocallyInEgyptButWeAre")}
        </div>
        <div className="amongHigh">{t("AIMINGHIGH")}</div>
      </div>
    </div>
  );
};
export default AmongHigh;
