import React, { useState, useEffect } from "react";
import "./ShareWithFriends.css";
import { useTranslation } from "react-i18next";
import Maximize from "../../Aboutus/Maximize/Maximize";
import { TextInput } from "@fintold/packages";
import { Formik, Form } from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareWithFriends = (props) => {
  const { t } = useTranslation();
  const url = window.location.href;

  return (
    <div className="ShareWithFriendsContainer">
      <div className="ShareWithFriendsContent">
        {/* <div className="MaximizeIcon">
          <Maximize />
        </div> */}

        <div className="ShareWithFriendsTitle"> {t("ShareWithFriends")} </div>
        <div className="ShareWithFriendsPostUrl">
          <div onClick={() => navigator.clipboard.writeText(url.toString())}>
            <CopyToClipboard text={url}>
              <Formik initialValues={{ email: url }}>
                {(formik) => (
                  <Form>
                    <TextInput
                      label={t("Post url")}
                      name={"email"}
                      autoComplete="off"
                    />
                  </Form>
                )}
              </Formik>
            </CopyToClipboard>
          </div>{" "}
        </div>
        {/* <div className="ShareWithFriendsShareOn"> {t("ShareOn")} </div>
        <div className="SocialMediaIconContainer">
          <img src="/images/ShareWithFriends/facebook.webp" />
          <img src="/images/ShareWithFriends/linkedin.webp" />
          <img src="/images/ShareWithFriends/whatsapp.webp" />
          <img src="/images/ShareWithFriends/twitter.webp" />
        </div> */}
      </div>
    </div>
  );
};
export default ShareWithFriends;
