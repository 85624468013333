import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import "./SmallUploadDocument.css";

const SmallUploadDocument = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const fileInputField = useRef(null);
  const [filePreview, setFilePreview] = useState("");
  const [file, setFile] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadSizeError, setUploadSizeError] = useState(false);
  const [uploadTypeError, setUploadTypeError] = useState(false);
  const { t } = useTranslation();
  const maxFileSizeInMB = 3;
  const acceptedTypes = ["application/pdf", "image/png", "image/jpeg", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

  useEffect(() => {
    setProgress(props.uploadProgress);
  }, [props.uploadProgress]);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    setUploadSizeError(false);
    setUploadTypeError(false);
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setFilePreview(URL.createObjectURL(e.dataTransfer.files[0]).toString());
    if (file.size > props.maxFileSize) {

      setUploadSizeError(true)
    }
    else {
      if (acceptedTypes.includes(file.type) === false) {
        setUploadTypeError(true)
      }
      else {
        setFile(e.dataTransfer.files[0]);
        props.setFileToUpload(e.dataTransfer.files[0]);
        props.setStartUpload && props.setStartUpload(true);
        props.uploadOnChoose && props.uploadOnChoose();
        props.setMainButton && props.setMainButton(false);
      }
    };
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const handleNewFileUpload = async (e) => {
    setUploadSizeError(false);
    setUploadTypeError(false);
    const file = e.target.files[0];
    setFilePreview(URL.createObjectURL(e.target.files[0]).toString());
    if (file.size > props.maxFileSize) {
      setUploadSizeError(true)
    }
    else {
      if (acceptedTypes.includes(file.type) === false) {
        setUploadTypeError(true)
      }
      else {
        setFile(e.target.files[0]);
        props.setFileToUpload(e.target.files[0]);
        props.setMainButton && props.setMainButton(false);
        props.setStartUpload && props.setStartUpload(true);
        props.uploadOnChoose && props.uploadOnChoose();
        setUploadSizeError(false);
        setUploadTypeError(false);
      }
    };
  };
  return (
    <div className="smallUploadDocument">
      <div className="dragAndDropLabel">
        <label>
          {props.label &&
            props.label
            ||
            t("uploadDocumentHere")
          }
        </label>
        {(progress === 100 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="32"
            height="32"
            viewBox="0 0 172 172"
          >
            <path d="M0,172v-172h172v172z" fill="none"></path>
            <g fill="#12aa3b">
              <path d="M86,21.5c-35.55872,0 -64.5,28.9413 -64.5,64.5c0,35.55869 28.94128,64.5 64.5,64.5c35.55872,0 64.5,-28.94131 64.5,-64.5c0,-35.5587 -28.94128,-64.5 -64.5,-64.5zM86,32.25c29.749,0 53.75,24.00103 53.75,53.75c0,29.74897 -24.001,53.75 -53.75,53.75c-29.749,0 -53.75,-24.00103 -53.75,-53.75c0,-29.74897 24.001,-53.75 53.75,-53.75zM112.60205,64.5l-33.59375,33.59375l-17.46875,-17.46875l-7.5166,7.5271l24.98535,24.99585l41.12085,-41.12085z"></path>
            </g>
          </svg>
        )) || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.49"
              height="17.49"
              viewBox="0 0 17.49 17.49"
            >
              <path
                d="M8.745,1.457A7.287,7.287,0,1,1,1.457,8.745,7.3,7.3,0,0,1,8.745,1.457ZM8.745,0A8.745,8.745,0,1,0,17.49,8.745,8.745,8.745,0,0,0,8.745,0Zm.729,13.117H8.016V7.287H9.474ZM8.745,4.19a.911.911,0,1,1-.911.911A.911.911,0,0,1,8.745,4.19Z"
                fill={(uploadTypeError || uploadSizeError) ? "#E63636" : "#787573"}
              />
            </svg>
          )}
      </div>
      <div className={(uploadTypeError || uploadSizeError) ? "error" : "upload"}>
        {(props.startUpload && (
          <div className="uploadingProcess">
            {((file.type === "application/pdf" ||
              (props.uploadedFile && props.uploadedFile.split(".").pop() === "pdf")) && (
                <Document
                  className={progress === 100 ? "document completed" : "document"}
                  file={(props.uploadedFile && props.uploadedFile) || filePreview}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                >
                  <Page className="page" pageNumber={1} />
                </Document>
              ) || (
                <>
                  {((file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || (props.uploadedFile && (props.uploadedFile.split(".").pop() === "xlsx"))) && (
                    <div className={progress === 100 ? "img completed" : "img"}>
                      <span>Cannot preview file</span>
                    </div>
                  ) || (
                      <div className={progress === 100 ? "img completed" : "img"}>
                        <img
                          src={
                            (props.uploadedFile && props.uploadedFile) || filePreview
                          }
                        />
                      </div>
                    )}
                </>
              ))}

            {(progress === 100 && (
              <svg
                className="documentIcon"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 172 172"
              >
                <path d="M0,172v-172h172v172z" fill="#fff" />
                <g fill="#12aa3b">
                  <path d="M86,17.2c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8zM124.45347,72.85347l-43.344,43.344c-1.07787,1.07787 -2.53413,1.67987 -4.05347,1.67987c-1.51933,0 -2.98133,-0.602 -4.05347,-1.67987l-19.7972,-19.7972c-2.24173,-2.24173 -2.24173,-5.8652 0,-8.10693c2.24173,-2.24173 5.8652,-2.24173 8.10693,0l15.74373,15.74373l39.29053,-39.29053c2.24173,-2.24173 5.8652,-2.24173 8.10693,0c2.24173,2.24173 2.24173,5.8652 0,8.10693z"></path>
                </g>
              </svg>
            )) || (
                <>
                  {progress < 1 && (
                    <svg
                      className="documentIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 172 172"
                      onClick={props.removePreview}
                    >
                      <path d="M0,172v-172h172v172z" fill="#fff"></path>
                      <g fill="#e63636">
                        <path d="M86,17.2c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8zM120.4,91.73333h-68.8c-3.1648,0 -5.73333,-2.5628 -5.73333,-5.73333c0,-3.17053 2.56853,-5.73333 5.73333,-5.73333h68.8c3.1648,0 5.73333,2.5628 5.73333,5.73333c0,3.17053 -2.56853,5.73333 -5.73333,5.73333z"></path>
                      </g>
                    </svg>
                  )}
                </>
              )}

            <div className="progressContainer">
              {progress > 0 && (
                <svg
                  className="uploadIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10.064 10.437"
                >
                  <g transform="translate(-23 -22)">
                    <defs>
                      <linearGradient
                        id="bottom-to-top-arrow"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                      >
                        <stop offset="0" stop-color="#007094">
                          <animate
                            dur="3s"
                            attributeName="offset"
                            from="0"
                            to="1"
                            repeatCount="indefinite"
                          />
                        </stop>

                        <stop offset="0" stop-color="#E4E4E5">
                          <animate
                            dur="3s"
                            attributeName="offset"
                            from="0"
                            to="1"
                            repeatCount="indefinite"
                          />
                        </stop>
                      </linearGradient>
                    </defs>
                    <path
                      fill={progress === 100 ? "#12AA3B" : "#E4E4E5"}
                      d="M33.064,23.3a1.3,1.3,0,0,0-1.3-1.3H24.3A1.3,1.3,0,0,0,23,23.3v1.677h1.118V23.3a.186.186,0,0,1,.186-.186H31.76a.186.186,0,0,1,.186.186v1.677h1.118Z"
                    />
                    <path
                      fill={
                        progress === 100 ? "#12AA3B" : "url(#bottom-to-top-arrow)"
                      }
                      d="M37.346,44.644V38.911l1.677,1.677.79-.79-2.632-2.632a.559.559,0,0,0-.79,0L33.76,39.8l.79.79,1.677-1.677v5.733Z"
                      transform="translate(-8.755 -12.206)"
                    />
                  </g>
                </svg>
              )}
              <div className="uploadProgress">
                {(progress === 100 && <span>{t("documentUploaded")}</span>) || (
                  progress > 0 && (
                    <span>{`${t("uploadingDocument")} (${progress}%)`}</span>
                  ))}
                <span>{file.name}</span>
                {progress === 100 && (
                  <div className="remove"
                    onClick={props.removeDocument && props.removeDocument}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.516"
                      height="11.516"
                      viewBox="0 0 11.516 11.9"
                    >
                      <g transform="translate(-20 -19)">
                        <path
                          d="M20,21.879h1.536v.084l.539,7.374A1.693,1.693,0,0,0,23.76,30.9h4a1.693,1.693,0,0,0,1.681-1.562l.543-7.374v-.084h1.535V20.727H26.334V19H25.182v1.727H20Zm8.829,0-.539,7.374a.534.534,0,0,1-.534.5h-4a.534.534,0,0,1-.534-.5l-.539-7.374Z"
                          transform="translate(0 0)"
                          fill="#E63636"
                        ></path>
                      </g>
                    </svg>
                    {t("remove")}
                  </div>
                )}
              </div>
            </div>
          </div>
        )) || (
            <div
              className="dragAndDrop"
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
            >
              <div className="firstContainer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.064"
                  height="10.437"
                  viewBox="0 0 10.064 10.437"
                >
                  <g transform="translate(-23 -22)">
                    <path d="M33.064,23.3a1.3,1.3,0,0,0-1.3-1.3H24.3A1.3,1.3,0,0,0,23,23.3v1.677h1.118V23.3a.186.186,0,0,1,.186-.186H31.76a.186.186,0,0,1,.186.186v1.677h1.118Z" />
                    <path
                      d="M37.346,44.644V38.911l1.677,1.677.79-.79-2.632-2.632a.559.559,0,0,0-.79,0L33.76,39.8l.79.79,1.677-1.677v5.733Z"
                      transform="translate(-8.755 -12.206)"
                    />
                  </g>
                </svg>
                <span>{t("dragAndDropHere")}</span>
                <span>{t("supportedFiles")}</span>
              </div>
              <div className="secondContainer">
                <button type="button" onClick={handleUploadBtnClick}>
                  {t("chooseFileBtn")}
                </button>
                <span className={(uploadTypeError || uploadSizeError) && "danger"}>
                  {t("maximumSize")}: {maxFileSizeInMB} {t("MB")}
                </span>
                <input
                  type="file"
                  ref={fileInputField}
                  title=""
                  value=""
                  accept=".png, .jpeg, .pdf, .jpg, .xlsx"
                  onChange={handleNewFileUpload}
                />
              </div>
            </div>
          )}
      </div>
      {uploadTypeError && (
        <span className="errorMessage">
          {t("supportedFileserror")}.
        </span>
      )}
      {uploadSizeError && (
        <span className="errorMessage">
          {t("fileNotUploaded")} {maxFileSizeInMB} {t("MB")}.
        </span>
      )
      }
    </div>
  );
};

export default SmallUploadDocument;
