import React from "react";
import Footer from "../homepage/Footer";
import NavBar from "../homepage/NavBar";
import CardsContainer from "./CardsContainer";
import ClientsArea from "./ClientsArea";
import Customize from "./Customize";
import Dashboard from "./Dashboard";
import ExpandableCard from "./ExpandableCard";
import FinancialDesign from "./FinancialDesign";
import Hero from "./Hero";
import FAQContainer from "../homepage/FAQContainer";
import PrivacyMatters from "./PrivacyMatters";
import Everyone from "./Everyone";
import { useTranslation } from "react-i18next";

const Index = (props) => {
  const { t } = useTranslation();
  return (
    <div id="spfSec">
      <NavBar initialFont="white" />
      <Hero />
      <FinancialDesign />
      <Dashboard />
      <div id="buyerfeaturesSection">
        <CardsContainer>
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 42 42"
              >
                <g id="money-recive" transform="translate(-364 -444)">
                  <g id="Group" transform="translate(380.625 457.125)">
                    <path
                      id="Vector"
                      d="M0,9.2a2.994,2.994,0,0,0,2.923,3.063h3.29A2.606,2.606,0,0,0,8.75,9.59,2.311,2.311,0,0,0,7.018,7.052L1.75,5.215A2.3,2.3,0,0,1,.018,2.677,2.617,2.617,0,0,1,2.555,0h3.29A2.994,2.994,0,0,1,8.768,3.063"
                      transform="translate(0 1.732)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,0V15.75"
                      transform="translate(4.375)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M35,17.5A17.5,17.5,0,1,1,17.5,0"
                    transform="translate(367.5 447.5)"
                    fill="none"
                    stroke="#292d32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M0,0V7H7"
                    transform="translate(393.75 449.25)"
                    fill="none"
                    stroke="#292d32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-5"
                    data-name="Vector"
                    d="M8.75,0,0,8.75"
                    transform="translate(393.75 447.5)"
                    fill="none"
                    stroke="#292d32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-6"
                    data-name="Vector"
                    d="M0,0H42V42H0Z"
                    transform="translate(364 444)"
                    fill="none"
                    opacity="0"
                  />
                </g>
              </svg>
            }
            title={
              <>
                Review cash out <br /> requests in details.
              </>
            }
            description={
              <>
                You can review cash our requests from
                <br /> suppliers on the system through your
                <br /> dashboard
              </>
            }
            perks={[
              "Review documents uploaded by clients.",
              "Approve/disapprove single documents.",
              "Review client's financial information.",
            ]}
            first={true}
            img={"/images/home/comlaptop.webp"}
            background={"rgba(247, 243, 242, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 42 42"
              >
                <g id="money-recive" transform="translate(-364 -444)">
                  <g id="Group" transform="translate(380.625 457.125)">
                    <path
                      id="Vector"
                      d="M0,9.2a2.994,2.994,0,0,0,2.923,3.063h3.29A2.606,2.606,0,0,0,8.75,9.59,2.311,2.311,0,0,0,7.018,7.052L1.75,5.215A2.3,2.3,0,0,1,.018,2.677,2.617,2.617,0,0,1,2.555,0h3.29A2.994,2.994,0,0,1,8.768,3.063"
                      transform="translate(0 1.732)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,0V15.75"
                      transform="translate(4.375)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M35,17.5A17.5,17.5,0,1,1,17.5,0"
                    transform="translate(367.5 447.5)"
                    fill="none"
                    stroke="#292d32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M0,0V7H7"
                    transform="translate(393.75 449.25)"
                    fill="none"
                    stroke="#292d32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-5"
                    data-name="Vector"
                    d="M8.75,0,0,8.75"
                    transform="translate(393.75 447.5)"
                    fill="none"
                    stroke="#292d32"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                  />
                  <path
                    id="Vector-6"
                    data-name="Vector"
                    d="M0,0H42V42H0Z"
                    transform="translate(364 444)"
                    fill="none"
                    opacity="0"
                  />
                </g>
              </svg>
            }
            title={
              <>
                Review solutions
                <br /> activation requests.
              </>
            }
            description={
              <>
                Based on solution activation process you
                <br /> have, you will be able to review client's
                <br /> solution activation request.
              </>
            }
            perks={[
              "Review documents uploaded by clients.",
              "Approve/disapprove single documents.",
              "Automatic values & fees calculations.",
            ]}
            offset={true}
            img={"/images/home/apprbuy.webp"}
            background={"rgba(244, 247, 242, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42.181"
                height="42.181"
                viewBox="0 0 42.181 42.181"
              >
                <g
                  id="vuesax_linear_profile-2user"
                  data-name="vuesax/linear/profile-2user"
                  transform="translate(-172 -252)"
                >
                  <g id="profile-2user" transform="translate(172 252)">
                    <path
                      id="Vector"
                      d="M8.085,15.589a3.2,3.2,0,0,0-.58,0,7.812,7.812,0,1,1,.58,0Z"
                      transform="translate(8.014 3.515)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M.228,0A6.147,6.147,0,0,1,6.38,6.151,6.16,6.16,0,0,1,.457,12.3,1.985,1.985,0,0,0,0,12.3"
                      transform="translate(28.613 7.03)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M3.19,2.412c-4.253,2.847-4.253,7.487,0,10.317,4.833,3.234,12.76,3.234,17.593,0,4.253-2.847,4.253-7.487,0-10.317C15.967-.8,8.041-.8,3.19,2.412Z"
                      transform="translate(4.121 23.177)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,10.545A8.5,8.5,0,0,0,3.445,9.016a4.452,4.452,0,0,0,0-7.5A8.741,8.741,0,0,0,.053,0"
                      transform="translate(32.233 24.605)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,0H42.181V42.181H0Z"
                      transform="translate(42.181 42.181) rotate(180)"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Manage clients and <br /> their accounts.
              </>
            }
            description={
              <>
                Through each client profile you can manage
                <br /> their transactions, details, invoices team members
                <br />, documents and more..
              </>
            }
            perks={[
              "Activate, suspend & delete clients.",
              "View/update client's personal details.",
              "View & mange client's added accounts.",
            ]}
            img={"/images/home/proflaptop.webp"}
            background={"rgba(247, 246, 242, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.928"
                height="40.928"
                viewBox="0 0 40.928 40.928"
              >
                <g
                  id="vuesax_linear_fatrows"
                  data-name="vuesax/linear/fatrows"
                  transform="translate(-172 -188)"
                >
                  <g id="fatrows" transform="translate(172 188)">
                    <path
                      id="Vector"
                      d="M30.526,0H3.581C1.023,0,0,1.091,0,3.8v6.89c0,2.711,1.023,3.8,3.581,3.8H30.526c2.558,0,3.581-1.091,3.581-3.8V3.8C34.107,1.091,33.084,0,30.526,0Z"
                      transform="translate(3.411 23.022)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M18.588,0H3.581C1.023,0,0,1.091,0,3.8v6.89c0,2.711,1.023,3.8,3.581,3.8H18.588c2.558,0,3.581-1.091,3.581-3.8V3.8C22.169,1.091,21.146,0,18.588,0Z"
                      transform="translate(3.411 3.411)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0H40.928V40.928H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Adjust the process with <br /> rules management.
              </>
            }
            description={
              <>
                You can define rules in the system to automate
                <br /> certain tasks, approvals, or actions
                <br /> based on certain triggers.
              </>
            }
            perks={[
              "Create, enable, disable and delete rules.",
              "Adjust rule terms and affected clients.",
              "View rule activity log and errors.",
            ]}
            img={"/images/home/ruleover.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
              >
                <g
                  id="vuesax_linear_document-text"
                  data-name="vuesax/linear/document-text"
                  transform="translate(-172 -188)"
                >
                  <g id="document-text" transform="translate(172 188)">
                    <path
                      id="Vector"
                      d="M33.333,13.333v8.333C33.333,30,30,33.333,21.667,33.333h-10C3.333,33.333,0,30,0,21.667v-10C0,3.333,3.333,0,11.667,0H20"
                      transform="translate(3.333 3.333)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M13.333,13.333H6.667c-5,0-6.667-1.667-6.667-6.667V0Z"
                      transform="translate(23.333 3.333)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0H10"
                      transform="translate(11.667 21.667)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,0H6.667"
                      transform="translate(11.667 28.333)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,0H40V40H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Control over clients'
                <br /> documents.
              </>
            }
            description={
              <>
                Through each client profile you can manage
                <br /> their transactions, details, invoices team
                <br /> members, documents and more..
              </>
            }
            perks={[
              "Request specific document update.",
              "Delete specific documents.",
              "Request specific document update.",
            ]}
            img={"/images/home/proflaptop.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
              >
                <g
                  id="vuesax_linear_user-cirlce-add"
                  data-name="vuesax/linear/user-cirlce-add"
                  transform="translate(-684 -188)"
                >
                  <g id="user-cirlce-add" transform="translate(684 188)">
                    <g id="Group" transform="translate(11.495 14.868)">
                      <path
                        id="Vector"
                        d="M10.3,5.152A5.152,5.152,0,1,1,5.152,0,5.152,5.152,0,0,1,10.3,5.152Z"
                        transform="translate(4.363)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M19.03,7.755C19.03,3.483,14.777,0,9.515,0S0,3.465,0,7.755"
                        transform="translate(0 14.41)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M34.833,17.417A17.362,17.362,0,1,1,24.2,1.357a7.432,7.432,0,0,0-.367,2.31A7.188,7.188,0,0,0,24.9,7.443,6.753,6.753,0,0,0,26.29,9.112,7.188,7.188,0,0,0,31.167,11a6.757,6.757,0,0,0,2.292-.385A17.536,17.536,0,0,1,34.833,17.417Z"
                      transform="translate(3.667 5.5)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M14.667,7.333a6.589,6.589,0,0,1-.22,1.7A6.846,6.846,0,0,1,13.6,11.11a7.146,7.146,0,0,1-3.978,3.172,6.757,6.757,0,0,1-2.292.385,7.188,7.188,0,0,1-4.877-1.888A6.753,6.753,0,0,1,1.063,11.11,7.188,7.188,0,0,1,0,7.333a7.432,7.432,0,0,1,.367-2.31,7.232,7.232,0,0,1,1.705-2.8A7.324,7.324,0,0,1,7.333,0a7.231,7.231,0,0,1,5.445,2.438A7.3,7.3,0,0,1,14.667,7.333Z"
                      transform="translate(27.5 1.833)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <g
                      id="Group-2"
                      data-name="Group"
                      transform="translate(32.102 6.453)"
                    >
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M5.463,0H0"
                        transform="translate(0 2.677)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,0V5.482"
                        transform="translate(2.732)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                    <path
                      id="Vector-7"
                      data-name="Vector"
                      d="M0,0H44V44H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Get your team in the
                <br /> game.
              </>
            }
            description={
              <>
                The platform allows you to add your team
                <br /> members, define and assign to them
                <br /> customized access based on their roles.
              </>
            }
            perks={[
              "Add, edit and remove team members.",
              "Assign permissions and create roles.",
              "Assign roles to team members.",
            ]}
            img={"/images/home/team.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45.135"
                height="45.135"
                viewBox="0 0 45.135 45.135"
              >
                <g
                  id="vuesax_linear_align-left"
                  data-name="vuesax/linear/align-left"
                  transform="translate(-364 -316)"
                >
                  <g id="align-left" transform="translate(364 316)">
                    <path
                      id="Vector"
                      d="M0,10.343H22.191c2.821,0,3.949-1.2,3.949-4.194V4.194C26.141,1.2,25.012,0,22.191,0H0"
                      transform="translate(9.591 25.858)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,0H12.788c2.821,0,3.949,1.2,3.949,4.194V6.15c0,2.99-1.128,4.194-3.949,4.194H0"
                      transform="translate(9.591 9.873)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0V37.612"
                      transform="translate(9.403 3.742)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,0H45.135V45.135H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Complete reporting <br /> about activity.
              </>
            }
            description={
              <>
                Through each client profile you can manage
                <br /> their transactions, details, invoices team <br />
                members, documents and more..
              </>
            }
            perks={[
              "Gather data about transactions.",
              "Activity, clients and invoices. ",
              "Team members activity reports.",
            ]}
            img={"/images/home/apprbuy.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
        </CardsContainer>
      </div>
      <ClientsArea />
      <div id="supplierFeaturesSection">
        <CardsContainer>
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.018"
                height="43.018"
                viewBox="0 0 43.018 43.018"
              >
                <g
                  id="vuesax_linear_direct-send"
                  data-name="vuesax/linear/direct-send"
                  transform="translate(-620 -186)"
                >
                  <g id="direct-send" transform="translate(620 186)">
                    <path
                      id="Vector"
                      d="M3.585,12.547V0L0,3.585"
                      transform="translate(17.924 3.585)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,0,3.585,3.585"
                      transform="translate(21.509 3.585)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0H7.9A1.771,1.771,0,0,1,9.5.986L11.6,5.18a3.585,3.585,0,0,0,3.208,1.99h6.327a3.585,3.585,0,0,0,3.208-1.99l2.1-4.194A1.791,1.791,0,0,1,28.033,0h7.815"
                      transform="translate(3.549 23.301)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M8.962,0C2.617.932,0,4.66,0,12.314v5.377c0,8.962,3.585,12.547,12.547,12.547H23.3c8.962,0,12.547-3.585,12.547-12.547V12.314C35.848,4.66,33.231.932,26.886,0"
                      transform="translate(3.585 9.195)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,0H43.018V43.018H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Activate & upload
                <br /> approval documents.
              </>
            }
            description={
              <>
                Clients can activate solutions and go through
                <br /> the approval process and upload needed
                <br /> documents, all online.
              </>
            }
            perks={[
              "Scan & upload required documents.",
              "Send required documents over by mail.",
              "Detect previously uploaded documents.",
            ]}
            first={true}
            img={"/images/home/weneeddocs.webp"}
            background={"rgba(247, 243, 242, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.998"
                height="43.998"
                viewBox="0 0 43.998 43.998"
              >
                <g
                  id="vuesax_linear_global"
                  data-name="vuesax/linear/global"
                  transform="translate(-172 -252)"
                >
                  <g id="global" transform="translate(172 252)">
                    <path
                      id="Vector"
                      d="M36.665,18.333A18.333,18.333,0,1,1,18.333,0,18.333,18.333,0,0,1,36.665,18.333Z"
                      transform="translate(3.667 3.667)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M.848,0H2.681a52.108,52.108,0,0,0,0,33H.848"
                      transform="translate(13.818 5.5)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0A52.108,52.108,0,0,1,0,33"
                      transform="translate(27.499 5.5)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,1.833V0A52.108,52.108,0,0,0,33,0V1.833"
                      transform="translate(5.5 27.499)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,2.681a52.108,52.108,0,0,1,33,0"
                      transform="translate(5.5 13.818)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-6"
                      data-name="Vector"
                      d="M0,0H44V44H0Z"
                      transform="translate(43.998 43.998) rotate(180)"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Cash outs, extensions,
                <br /> payments. All online.
              </>
            }
            description={
              <>
                Clients can cash out and extend invoices
                <br /> online without any paper work or offline
                <br /> activity.
              </>
            }
            perks={[
              "Supplier can cash out invoices.",
              "Buyers can extend and pay invoices.",
              "Bulk invoice payment and cashing out.",
            ]}
            offset={true}
            img={"/images/home/cashoutreq.webp"}
            background={"rgba(244, 247, 242, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39.85"
                height="39.85"
                viewBox="0 0 39.85 39.85"
              >
                <g
                  id="vuesax_linear_bank"
                  data-name="vuesax/linear/bank"
                  transform="translate(-428 -188)"
                >
                  <g id="bank" transform="translate(428 188)">
                    <path
                      id="Vector"
                      d="M17.218.1,32.162,6.077a1.8,1.8,0,0,1,1.046,1.544v5.513a1.665,1.665,0,0,1-1.66,1.66H1.66A1.665,1.665,0,0,1,0,13.134V7.621A1.8,1.8,0,0,1,1.046,6.077L15.99.1A1.944,1.944,0,0,1,17.218.1Z"
                      transform="translate(3.321 3.47)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M33.208,6.642H0V1.66A1.665,1.665,0,0,1,1.66,0H31.548a1.665,1.665,0,0,1,1.66,1.66Z"
                      transform="translate(3.321 29.887)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,11.623V0"
                      transform="translate(6.642 18.264)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M0,11.623V0"
                      transform="translate(13.283 18.264)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,11.623V0"
                      transform="translate(19.925 18.264)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-6"
                      data-name="Vector"
                      d="M0,11.623V0"
                      transform="translate(26.566 18.264)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-7"
                      data-name="Vector"
                      d="M0,11.623V0"
                      transform="translate(33.208 18.264)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-8"
                      data-name="Vector"
                      d="M0,0H36.529"
                      transform="translate(1.66 36.529)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-9"
                      data-name="Vector"
                      d="M4.981,2.491A2.491,2.491,0,1,1,2.491,0,2.491,2.491,0,0,1,4.981,2.491Z"
                      transform="translate(17.434 9.132)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-10"
                      data-name="Vector"
                      d="M0,0H39.85V39.85H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Add & manage bank
                <br /> accounts on the go.
              </>
            }
            description={
              <>
                Clients can add their bank accounts where
                <br /> they will receive their cash out transactions
                <br /> on upon cashing out invoices.
              </>
            }
            perks={[
              "Add multiple bank accounts to the system.",
              "Choose default bank for cashing out.",
              "Edit and delete added bank accounts.",
            ]}
            img={"/images/home/managebanks.webp"}
            background={"rgba(247, 246, 242, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41.36"
                height="41.36"
                viewBox="0 0 41.36 41.36"
              >
                <g
                  id="vuesax_linear_document-upload"
                  data-name="vuesax/linear/document-upload"
                  transform="translate(-364 -188)"
                >
                  <g id="document-upload" transform="translate(364 188)">
                    <path
                      id="Vector"
                      d="M3.447,10.34V0L0,3.447"
                      transform="translate(12.063 18.957)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,0,3.447,3.447"
                      transform="translate(15.51 18.957)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M34.467,13.787V22.4c0,8.617-3.447,12.063-12.063,12.063H12.063C3.447,34.467,0,31.02,0,22.4V12.063C0,3.447,3.447,0,12.063,0H20.68"
                      transform="translate(3.447 3.447)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M13.787,13.787H6.893C1.723,13.787,0,12.063,0,6.893V0Z"
                      transform="translate(24.127 3.447)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,0H41.36V41.36H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Upload invoices easily
                <br /> to the system.
              </>
            }
            description={
              <>
                Clients can upload invoices through an excel
                <br /> sheet, very simple and straight forward
                <br /> process to add all their invoices.
              </>
            }
            perks={[
              "Bulk add invoices through excel sheet.",
              "Add single invoices online through copy and paste.",
              "Suppliers notified of any uploaded invoices.",
            ]}
            img={"/images/home/addinv.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.438"
                height="43.438"
                viewBox="0 0 43.438 43.438"
              >
                <g
                  id="vuesax_linear_status-up"
                  data-name="vuesax/linear/status-up"
                  transform="translate(-236 -252)"
                >
                  <g id="status-up" transform="translate(236 252)">
                    <path
                      id="Vector"
                      d="M0,3.746V0"
                      transform="translate(12.452 29.103)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M0,7.493V0"
                      transform="translate(21.719 25.357)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,11.258V0"
                      transform="translate(30.985 21.592)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M18.533,0,17.7.977A34.174,34.174,0,0,1,0,11.909"
                      transform="translate(12.452 10.588)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-5"
                      data-name="Vector"
                      d="M0,0H5.3V5.285"
                      transform="translate(25.682 10.588)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-6"
                      data-name="Vector"
                      d="M12.669,36.2H23.529c9.049,0,12.669-3.62,12.669-12.669V12.669C36.2,3.62,32.578,0,23.529,0H12.669C3.62,0,0,3.62,0,12.669V23.529C0,32.578,3.62,36.2,12.669,36.2Z"
                      transform="translate(3.62 3.62)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-7"
                      data-name="Vector"
                      d="M0,0H43.438V43.438H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Reporting &<br /> notifications.
              </>
            }
            description={
              <>
                We allow clients to view reports about their
                <br /> invoices, transactions and cash outs and get
                <br /> notified of any activity on the system.
              </>
            }
            perks={[
              "Latest approvals, invoices and transactions.",
              "Notifications of actions on the account.",
              "Notifications of required documents.",
            ]}
            img={"/images/home/apprbuy.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
          <ExpandableCard
            cardIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
              >
                <g
                  id="vuesax_linear_user-cirlce-add"
                  data-name="vuesax/linear/user-cirlce-add"
                  transform="translate(-684 -188)"
                >
                  <g id="user-cirlce-add" transform="translate(684 188)">
                    <g id="Group" transform="translate(11.495 14.868)">
                      <path
                        id="Vector"
                        d="M10.3,5.152A5.152,5.152,0,1,1,5.152,0,5.152,5.152,0,0,1,10.3,5.152Z"
                        transform="translate(4.363)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M19.03,7.755C19.03,3.483,14.777,0,9.515,0S0,3.465,0,7.755"
                        transform="translate(0 14.41)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M34.833,17.417A17.362,17.362,0,1,1,24.2,1.357a7.432,7.432,0,0,0-.367,2.31A7.188,7.188,0,0,0,24.9,7.443,6.753,6.753,0,0,0,26.29,9.112,7.188,7.188,0,0,0,31.167,11a6.757,6.757,0,0,0,2.292-.385A17.536,17.536,0,0,1,34.833,17.417Z"
                      transform="translate(3.667 5.5)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Vector-4"
                      data-name="Vector"
                      d="M14.667,7.333a6.589,6.589,0,0,1-.22,1.7A6.846,6.846,0,0,1,13.6,11.11a7.146,7.146,0,0,1-3.978,3.172,6.757,6.757,0,0,1-2.292.385,7.188,7.188,0,0,1-4.877-1.888A6.753,6.753,0,0,1,1.063,11.11,7.188,7.188,0,0,1,0,7.333a7.432,7.432,0,0,1,.367-2.31,7.232,7.232,0,0,1,1.705-2.8A7.324,7.324,0,0,1,7.333,0a7.231,7.231,0,0,1,5.445,2.438A7.3,7.3,0,0,1,14.667,7.333Z"
                      transform="translate(27.5 1.833)"
                      fill="none"
                      stroke="#292d32"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <g
                      id="Group-2"
                      data-name="Group"
                      transform="translate(32.102 6.453)"
                    >
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M5.463,0H0"
                        transform="translate(0 2.677)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,0V5.482"
                        transform="translate(2.732)"
                        fill="none"
                        stroke="#292d32"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                    </g>
                    <path
                      id="Vector-7"
                      data-name="Vector"
                      d="M0,0H44V44H0Z"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
            }
            title={
              <>
                Team management for
                <br /> clients.
              </>
            }
            description={
              <>
                Clients can add team members to their added
                <br /> accounts on the system and assign roles to
                <br /> each team member.
              </>
            }
            perks={[
              "Add, edit and delete team members.",
              "Assign permissions and create roles.",
              "Assign roles to team members.",
            ]}
            img={"/images/home/team.webp"}
            background={"rgba(247, 242, 245, 1)"}
          />
        </CardsContainer>
      </div>
      <Customize />
      <Everyone />
      <PrivacyMatters />
      <FAQContainer
        data={[
          {
            q: t("faq1"),
            a: t("faqa1"),
          },
          {
            q: t("faq2"),
            a: t("faqa2"),
          },
          {
            q: t("faq3"),
            a: t("faqa3"),
          },
        ]}
      />
      <Footer />{" "}
    </div>
  );
};

export default Index;
