import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { HashLink } from "react-router-hash-link";
import { CountryInput } from "@fintold/packages";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { useTranslation } from "react-i18next";
import "./ContactDetails.css";
import fintoldPackages from "@fintold/packages";
import ReCAPTCHA from "react-google-recaptcha";
import { customFetch } from "../../CustomFetch";

const ContactDetails = (props) => {
  const [countryCode, setCountryCode] = useState("+20");
  const { TextInput } = fintoldPackages;
  const { t } = useTranslation();
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const ContactDetailsSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company: Yup.string().required("Bank name is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email address is required"),
    phoneNumber: Yup.number()
      .typeError("Invalid number")
      .positive()
      .typeError("Invalid number")
      .integer()
      .typeError("Invalid number")
      .required("Phone number is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    await recaptchaRef.current.execute();
  };
  const contact = async (token, values) => {
    try {
      const res = await customFetch("/general/contactus", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ ...values, token }),
      });
      setIsLoading(false);
      resetForm();
      toast(<ToastBody icon="confirm" text="Contact details sent!" />, {
        className: "defaultToast",
      });
    } catch (e) {
      setIsLoading(false);
      toast(<ToastBody icon="error" text="Error loading data" />, {
        className: "errorToast",
      });
    }
  };

  return (
    <div className="contactDetailsSec">
      <div>
        <h2>
          {t("ReadySit")}
          <br />
          {t("SeeDemo")}
          <br />
          {t("systemParag")}
        </h2>
        <p className="contactDetailsSecParag">
          {t("YouEnter")}
          <br /> {t("contactYou")}
          <br /> {t("ArrangeDemo")}
        </p>
      </div>
      <div className="contactSecContainer">
        <div>
          <Formik
            initialValues={{
              name: "",
              company: "",
              email: "",
              phoneNumber: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={ContactDetailsSchema}
          >
            {(formik) => (
              <Form>
                <div className="flex-container-NotifyMe">
                  <TextInput
                    label={t("YourName")}
                    name="name"
                    showicon="true"
                    placeholder="Enter your name"
                  />
                  <TextInput
                    label={t("BankName")}
                    name="company"
                    showicon="true"
                    placeholder="Bank name"
                  />
                </div>
                <div>
                  <TextInput
                    placeholder="emailaddress@domain.com"
                    label={t("EmailAddress")}
                    name="email"
                    autoComplete="off"
                    showicon="true"
                  />
                </div>
                <div className="col-md-12">
                  <CountryInput
                    onClick={"Egypt"}
                    parentCallback={setCountryCode}
                    name="phoneNumber"
                    placeholder="Enter phone number"
                    label={t("PhoneNumber")}
                    showicon={true}
                    dialcode={countryCode}
                  />
                </div>
                <button
                  type="submit"
                  className={`contactDetailsBtn ${formik.isSubmitting === true || isLoading ? "loading" : ""
                    }`}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  {t("CallMe")}
                </button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lf768gUAAAAANNZyskUoAL9twYZ5k8REyMKNoh4"
                  onChange={async (token) => {
                    if (token) {
                      setIsLoading(true);
                      await contact(token, formik.values);
                    }
                  }}
                  size={"invisible"}
                  badge={"bottomleft"}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
