import React from "react";
import "./Hero.css";

const Hero = (props) => {
  return (
    <div className="spfHero">
      <small>Help buyers & suppliers access funding</small>
      <h1>
        Automated supply chain <br /> finance solution.
      </h1>
      <img src="/images/home/spflaptop.webp" />
    </div>
  );
};

export default Hero;
