import React, { useState, useEffect } from "react";
import "./Avatar.css";
import { useTranslation } from "react-i18next";
import Maximize from "../Maximize/Maximize";

const Avatar = (props) => {
  const { t } = useTranslation();

  return (
    <div className="avatar">
      <div className="avatarContainer">
        <img className="founderImg" src={props.FounderPicPath} />
        <div className="bottom">
          <div className="avatarFounderContainer">
            <div className="founderPosition">{props.FounderPosition}</div>
            <div className="founderName">{props.FounderName}</div>
          </div>
          <div className="minimizeComponent">
            <Maximize />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Avatar;
