import React, { useState, useEffect } from "react";
import "./Careers.css";
import { useTranslation } from "react-i18next";
import JobCard from "./JobCard/JobCard";
import GetNotified from "./GetNotified/GetNotified";

import OpenPositions from "./OpenPositions/OpenPositions";
import JobPosition from "./JobPositions";
import Navbar from "../homepage/NavBar";
import Footer from "../homepage/Footer";

// {t("")}

const Careers = (props) => {
  const { t } = useTranslation();
  const [IsNotified, setIsNotified] = React.useState(false);
  const [sucsess, setSucsess] = React.useState(false);
  const onAppliedSccuess = () => setSucsess(true);
  return (
    <>
      <Navbar initialFont="black" />
      <div id="careerSec" className="CareersContainer">
        <div className=" careerLanding">
          <div className="CareersTitle landingText text-center">
            <div className="BuildTheFuture">
              {t("Let'sBuildTheFutureTogether")}
            </div>
            <div className="BePart">{t("BePartOfOurDream")}</div>
          </div>

          <div className="horizintalWrap">
            <div className="WorkBenefits col-sm-12  col-lg-4   align-content-sm-center">
              <div className="BenefitTitle">{t("EncouragingGrowth")}</div>
              <div className="BenefitDescription">
                {t("WithAccessToCourses")}
              </div>
            </div>
            <div className="WorkBenefits col-lg-4 col-sm-12 align-content-sm-center">
              <div className="BenefitTitle">{t("HealthyWorkEnvironment")}</div>
              <div className="BenefitDescription">
                {t("YouWillWorkInHealthy")}
              </div>
            </div>
            <div className="WorkBenefits col-sm-12 col-lg-4 align-content-sm-center align-content-xs-center">
              <div className="BenefitTitle">{t("RemoteOpportunity")}</div>
              <div className="BenefitDescription">
                {t("WorkAnywhereYouWant")}
              </div>
            </div>
          </div>

          <div className="openPositionsTitle">
            <div className="openPositionsTitleStyle">{t("OpenPositions")}</div>
            <div className="FindTheJobStyle">{t("FindTheJob")}</div>
          </div>
        </div>
        <div className="openPositionComponent jobs row    d-flex align-content-center justify-content-center">
          <OpenPositions />
        </div>
        {sucsess && (
          <div className="DoneComponent">
            <div className="DoneStyle">
              <img src="/images/JobPost/vuesax-linear-sms-tracking.webp" />
              <div className="DoneText">Done</div>
              <div className="WeWillNotifyYouNewPostsText">
                We will notify you of any new job posts.
              </div>
            </div>
          </div>
        )}
        {!sucsess && <GetNotified onAppliedSccuess={onAppliedSccuess} />}
      </div>
      <Footer />
    </>
  );
};
export default Careers;
