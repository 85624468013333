import React from "react";
import { HashLink } from "react-router-hash-link";
import "./ClientsArea.css";
const ClientsArea = (props) => {
  return (
    <div className="spfclientsareasec">
      <img className="img1-c1" src="/images/home/c1.webp" />
      <img className="img2-c2" src="/images/home/c2.webp" />
      <img className="img3" src="/images/home/c3.webp" />
      <img className="img4" src="/images/home/c4.webp" />
      <img className="img5" src="/images/home/c5.webp" />
      <h2>CLIENT AREA</h2>
      <h1>
        Everything is automatic.
        <br /> Everything is online.
      </h1>
      <p>
        Our system allows your clients to do everything
        <br /> online and automatically, from approval process
        <br /> to uploading invoices and cashing out
      </p>
      <HashLink to="#supplierFeaturesSection">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="49.453"
          height="49.453"
          viewBox="0 0 49.453 49.453"
        >
          <g id="arrow-down" transform="translate(-236 -252)" opacity="0.6">
            <path
              id="Vector"
              d="M32.639,0,19.2,13.435a4.092,4.092,0,0,1-5.77,0L0,0"
              transform="translate(244.407 270.442)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H49.453V49.453H0Z"
              transform="translate(285.453 301.453) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </svg>
      </HashLink>
    </div>
  );
};

export default ClientsArea;
