import "./BrandingDone.css";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { MainButton } from "@fintold/packages";
import { VerificationSteps } from "@fintold/packages";
const BrandingDone = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="modal-header"
        style={{ width: "43.17vw", marginLeft: "20%" }}
      >
        <div className="Content">
          <div style={{ font: "normal normal bold 35px/41px Raleway" }}>
            {t("ChangeClientAreaBanner")}
          </div>
          <div className="VerificationStepsUploadLogo">
            <VerificationSteps totalSteps={2} currentStep={3} />
          </div>
          <div className="VerificationStepsUploadLogo"></div>
          <div>
            <div className="ConfrimImage">
              {" "}
              <img src="/images/crayon-1779.png" />
              <div className="YourCompanyClientArea">
                {t("YourCompanyClientArea")}
              </div>
            </div>
          </div>
          <div className="BackToPersonalisationButton">
            <MainButton
              btnType={"main"}
              text={t("uploadFileBtn")}
              type="button"
              //   onClick={handleSubmit}
              text={t("BackToPersonalisation")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandingDone;
