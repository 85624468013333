import React from "react";
import "./LaptopScreen.css";

const LaptopScreen = (props) => {
  return (
    <div className="laptopScreen">
      <img src="/images/home/dashboard.webp" />
      <h1>
        Cloud-based financial <br /> management system.
      </h1>
      <p>
        Powerful financial management system to <br /> manage everything related
        to the solutions you <br /> offer and your clients.
      </p>
    </div>
  );
};

export default LaptopScreen;
