import React, { useState, useRef } from "react";
import axios from "axios";
import "./ApplyForJob.css";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextInput } from "@fintold/packages";
import SmallUploadDocument from "../../common/SmallUploadDocument";
import { MainButton } from "@fintold/packages";
import ReCAPTCHA from "react-google-recaptcha";

const ApplyForJob = (props) => {
  const [startUpload, setStartUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [mainButton, setMainButton] = useState(true);
  const [fileToUpload, setFileToUpload] = useState({});
  const maxFileSizeInBytes = 3145728;
  const { t, i18n } = useTranslation();
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    YourName: Yup.string().required("Your name is required"),
    EmailAddress: Yup.string()
      .email("Invalid email")
      .required("Email can't be empty"),
    YearsOfExperience: Yup.number("Invalid number")
      .required("Phone number can't be empty")
      .positive()
      .integer(),
  });

  const handleSubmit = async () => {
    await recaptchaRef.current.execute();
  };

  const apply = async (token, values) => {
    const formData = new FormData();
    formData.append("fname", values.YourName);
    formData.append("email", values.EmailAddress);
    formData.append("exp", values.YearsOfExperience);
    formData.append("title", values.ApplyingFor);
    formData.append("cv", fileToUpload);
    formData.append("token", token);

    setStartUpload(true);
    uploadDoc(formData);
  };

  const uploadDoc = async (formData) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (e) => {
      setUploadProgress(Math.round((e.loaded / e.total) * 100));
    };
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        props.onAppliedSccuess();
      }
    };
    xhr.open("POST", "/general/applyforjob");
    xhr.send(formData);
  };
  return (
    <div>
      {" "}
      <div>
        <div>
          <div>
            <div className="parentContainer-ApplyForJob">
              <div>
                <Formik
                  initialValues={{
                    YourName: "",
                    EmailAddress: "",
                    ApplyingFor: props.department,
                    YearsOfExperience: "",
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={SignupSchema}
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form style={{ marginTop: "80px" }}>
                      <div className="flex-container-ApplyForJob">
                        <TextInput
                          name="YourName"
                          showicon="true"
                          label="Your name"
                        />
                        <TextInput
                          placeholder="emailaddress@domain.com"
                          name="EmailAddress"
                          showicon="true"
                          label="Email address"
                        />
                      </div>
                      <div className="flex-container-ApplyForJob">
                        <TextInput
                          name="ApplyingFor"
                          showicon="true"
                          label="Applying for"
                          disabled={true}
                        />
                        <TextInput
                          name="YearsOfExperience"
                          showicon="true"
                          label="Years of experience"
                        />
                      </div>

                      <SmallUploadDocument
                        setMainButton={setMainButton}
                        startUpload={startUpload}
                        setStartUpload={setStartUpload}
                        setFileToUpload={setFileToUpload}
                        uploadProgress={uploadProgress}
                        maxFileSize={maxFileSizeInBytes}
                        removeDocument={() => {
                          setFileToUpload({});
                          setMainButton(true);
                          setStartUpload(false);
                        }}
                        removePreview={() => {
                          setFileToUpload({});
                          setMainButton(true);
                          setStartUpload(false);
                          setUploadProgress(0);
                        }}
                      />
                      <MainButton
                        btnType={
                          formik.isSubmitting || isLoading ? "loading" : "main"
                        }
                        text="Apply now"
                        type="submit"
                        disabled={
                          mainButton || !(formik.isValid && formik.dirty)
                        }
                      />
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lf768gUAAAAANNZyskUoAL9twYZ5k8REyMKNoh4"
                        onChange={async (token) => {
                          if (token) {
                            setIsLoading(true);
                            await apply(token, formik.values);
                          }
                        }}
                        size={"invisible"}
                        badge={"bottomleft"}
                      />
                      {/* <button
                        disabled={props.isSubmitting}
                        type="submit"
                        className="btnSubmitApplyForJob"
                      >
                        Apply now
                      </button> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyForJob;
