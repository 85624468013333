import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "../../store/user";
import { MainModal } from "@fintold/packages";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { TextInput } from "@fintold/packages";
// import { DropDownInput } from "@fintold/packages";
import DropDownInput from "./DropDownInput";
import { MainButton } from "@fintold/packages";
import "./AddCompanyDetails.css";
import { toast } from "react-toastify";
import { ToastBody } from "@fintold/packages";
import { customFetch } from "../../CustomFetch";

const AddCompanyDetails = (props) => {
  const { t } = useTranslation();
  let formData = new FormData();
  const [selectedCountry, setselectedCountry] = useState();
  const [dataCenterLocation, setCountryDialCode] = useState();
  const [CountryDataCenter, setCountryDataCenter] = useState(null);
  const [companyCrNumber, setCompanyCrNumber] = useState();
  const [view, setView] = useState(2);
  const [emailError, setEmailError] = useState("");
  const history = useHistory();
  const schema = Yup.object().shape({
    Companyname: Yup.string()
      .required("Company name is required")
      .max(50, `field can be 50 characters or less`),
    CRnumber: Yup.string()
      .required("Company registration (CR) number is required")
      .max(50, `field can be 50 characters or less`),
  });
  const callback = useCallback((CountryDataCenter, companyCrNumber) => {
    setCountryDataCenter(CountryDataCenter);
    setCompanyCrNumber(companyCrNumber);
  }, []);
  const callback2 = useCallback((companyCrNumber) => { }, []);
  const handleShow = () => setShow(false);
  const handleSubmit = async function (values) {
    setCompanyCrNumber(values.CRnumber);
    formData.append("location", values.location);
    formData.append(
      "companyName",
      values.Companyname.toLowerCase().replace(" ", "-")
    );
    formData.append("number", values.CRnumber);
    const res = await customFetch(`/account/AddNewCompanyDetails`, {
      body: formData,
      method: "POST",
    });
    const data = await res.json();
    if (data.exists == true) {
      setView(1);
      setEmailError("Company name or Company Cr number already exists !");
    }

    if (data.business == "") {
      setLoading(false);
      toast(
        <ToastBody
          icon="error"
          text={"An error has occurred, please try again later"}
        />,
        {
          className: "errorToast",
        }
      );
      return;
    }
    toast(
      <ToastBody icon="confirm" text={"Your company is added successfully"} />,
      {
        className: "defaultToast",
      }
    );
    if (props.success)
      props.success({
        userId: props.userId,
        crnumber: values.CRnumber,
        latinName: values.Companyname.toLowerCase(),
        arabicName: values.Companyname.toLowerCase(),
        avatar: null,
        banner: null,
      });
    props.modal.hide();
  };

  return (
    <>
      <div>
        <MainModal
          modal={props.modal}
          setModal={props.setModal}
          id={props.modalId + "modal"}
          closeText={t("close")}
          hideHelper={true}
        >
          <div>
            <Formik
              initialValues={{
                Companyname: "",
                CRnumber: "",
                location: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <div>
                    <div className="modal-header"></div>
                    <div className="adjustmarginfortheFormAddCompany">
                      <div className="adjustAddCompanyWidthDetails">
                        <div className="enteryourDetailsFont">
                          <h2>{t("EnterDetailsAdd")}</h2>
                        </div>
                        <div className="StyleToAddyourCompanyFont">
                          <p>{t("addcompanypara")}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <TextInput
                            name="Companyname"
                            id="input"
                            label={t("CompanynameAdd")}
                            placeholder=""
                            value={formik.values.Companyname}
                          />
                        </div>
                        <div>
                          <TextInput
                            name="CRnumber"
                            id="input"
                            label={t("CompanyregistrationnumberAdd")}
                            placeholder="00000"
                            value={formik.values.CRnumber}
                          />
                        </div>
                        <div>
                          <DropDownInput
                            label={""}
                            placeholder={"Choose country"}
                            listSize={1}
                            getList={async (listOrder) => {
                              if (listOrder == 0) {
                                const res = await customFetch(
                                  "/utilities/getallcountries"
                                );
                                const data = await res.json();
                                return data.listOfAllCountries.map((x) => {
                                  return {
                                    id: x.id,
                                    name: x.countryLatinName,
                                  };
                                });
                              }
                            }}
                            name="location"
                          />
                        </div>
                        <div className="errormsgcolor">{emailError}</div>
                        <MainButton
                          btnType={formik.isSubmitting ? "loading" : "main"}
                          text={
                            formik.errors.Companyname ||
                              formik.errors.CRnumber ||
                              !formik.dirty
                              ? t("requireddetailsAdd")
                              : t("requireddetailsAdd")
                          }
                          type="submit"
                          className={`btnSubmitAddCompany `}
                          disabled={
                            formik.errors.Companyname ||
                            formik.errors.CRnumber ||
                            formik.errors.location ||
                            !formik.dirty
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </MainModal>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(AddCompanyDetails);
